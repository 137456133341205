import { getHeadAssetByType, getHvacAssetByType, } from '@/config/FCAHelperFunctions';
import { FacilityMapTypes } from '@/objects/FCAHelperInterfaces';
import { useToken } from '@chakra-ui/react';
import { appColors as designSystemColors } from '@frontend/design-system/theme/constants';
import useImage from 'use-image';
import { appColors, casework, ceiling, flooring, measureColors, } from '../../../config/constants';
import Grid from '../../../images/grid.png';
import { rgbFromNumber, simpleStringHash } from './colors';
export const useFloorplanCanvasShape = () => {
    const [grid] = useImage(Grid);
    const [yellow, secOrange] = useToken('colors', [
        'slgq.yellow',
        'slgq.secOrange',
    ]);
    const getPatternImage = (space, selectedMeasure) => {
        const assetType = findMeasureAsset(space, selectedMeasure);
        const gridTypes = new Set([
            ceiling['2X2 TILES'].type,
            ceiling['2X4 TILES'].type,
            ceiling['SPLINED 12" TILES'].type,
            ceiling['ADHERED 12" TILES'].type,
        ]);
        if (assetType && gridTypes.has(assetType)) {
            return grid;
        }
        return;
    };
    const getStyleFromSpace = (isAssessed) => {
        return {
            stroke: {
                selected: {
                    width: 1.5,
                    color: appColors.KONVA_BLACK,
                },
                unselected: {
                    width: 1,
                    color: isAssessed
                        ? appColors.KONVA_BLUE
                        : appColors.KONVA_ORANGE,
                },
            },
            fill: {
                selected: isAssessed
                    ? 'rgba(4, 119, 186, .75)'
                    : 'rgba(246, 134, 42, 0.75)',
                unselected: isAssessed
                    ? appColors.KONVA_BLUE
                    : appColors.KONVA_ORANGE,
            },
        };
    };
    const getShape = (space, selectedSpaceIdentity) => {
        return {
            identity: space.identity,
            shape: space.location,
            style: getStyleFromSpace(space.isAssessed),
            isSelected: space.identity === selectedSpaceIdentity,
        };
    };
    const getViewOnlyStyleFromSpace = () => {
        return {
            stroke: {
                selected: {
                    width: 1.5,
                    color: appColors.KONVA_BLACK,
                },
                unselected: {
                    width: 1,
                    color: appColors.KONVA_BLACK,
                },
            },
            fill: {
                selected: `${secOrange}BF`,
                unselected: `${yellow}BF`,
            },
        };
    };
    const getViewOnlyShape = (space, isSpaceSelected) => {
        return {
            identity: space.identity,
            shape: space.location,
            style: getViewOnlyStyleFromSpace(),
            // todo pass in callback
            isSelected: isSpaceSelected(space),
        };
    };
    const getPin = (assetIdentity, location, spaceIdentity) => {
        return {
            identity: assetIdentity,
            location: location,
            associatedShapeIdentity: spaceIdentity,
        };
    };
    const selectedTransparency = '90';
    const unselectedTransparency = '50';
    const getMeasureStyleFromSpace = (space, measure, showAgeAdjustedCondition) => {
        const headAsset = measure === FacilityMapTypes.HVAC &&
            space.assets &&
            space.assets.length
            ? getHvacAssetByType(measure, space.assets)
            : space.assets && getHeadAssetByType(measure, space.assets);
        const condition = measure &&
            headAsset &&
            (showAgeAdjustedCondition
                ? headAsset === null || headAsset === void 0 ? void 0 : headAsset.ageAdjustedCondition
                : headAsset === null || headAsset === void 0 ? void 0 : headAsset.assetCondition);
        const conditionMeasureColors = [
            measureColors.MEASURE_GREEN,
            measureColors.MEASURE_GRELLO,
            measureColors.MEASURE_YELLOW,
            measureColors.MEASURE_ORANGE,
            measureColors.MEASURE_RED,
        ];
        const index = condition ? condition - 1 : 3;
        const isPresent = MeasureMapping[measure].isPresent(space);
        return {
            stroke: {
                selected: {
                    width: 2,
                    color: isPresent
                        ? conditionMeasureColors[index]
                        : designSystemColors.BLACK,
                },
                unselected: {
                    width: 1,
                    color: isPresent
                        ? conditionMeasureColors[index]
                        : conditionMeasureColors[3],
                },
            },
            fill: isPresent
                ? MeasureMapping[measure].getFill(space)
                : { selected: '', unselected: '' },
            pattern: getPatternImage(space, measure),
        };
    };
    const getIEQFill = (metric) => {
        if (!metric)
            return defaultFill;
        return {
            selected: metric.rating + selectedTransparency,
            unselected: metric.rating + unselectedTransparency,
        };
    };
    const getHVACColor = (facilityAssets, selected) => {
        const hvacAssets = facilityAssets.filter((asset) => asset.assetClass === 'Air Handling System');
        if (hvacAssets.length < 1) {
            return '';
        }
        const hvacIdHash = simpleStringHash(hvacAssets
            .map((asset) => asset.assetId)
            .sort((a, b) => a - b)
            .join(',')) % Math.pow(256, 3);
        return getRGBAString(hvacIdHash, selected);
    };
    const getRGBAString = (n, selected) => {
        const rgb = rgbFromNumber(n);
        return `rgba(${rgb.r},${rgb.g},${rgb.b},${selected ? '0.9' : '0.5'})`;
    };
    const findMeasureAsset = (space, measure) => {
        var _a, _b;
        const assetClass = space.assets &&
            ((_a = getHeadAssetByType(measure, space.assets)) === null || _a === void 0 ? void 0 : _a.assetType);
        const assetType = space.assets &&
            ((_b = getHeadAssetByType(measure, space.assets)) === null || _b === void 0 ? void 0 : _b.platformAssetType);
        return space.assets && !!assetType && assetClass === measure
            ? assetType
            : false;
    };
    const defaultFill = {
        selected: '' + selectedTransparency,
        unselected: '' + unselectedTransparency,
    };
    const MeasureMapping = {
        [FacilityMapTypes.BUILDING_AGE]: {
            getFill: (space) => {
                return {
                    selected: getRGBAString(space.constrYear, true),
                    unselected: getRGBAString(space.constrYear, false),
                };
            },
            isPresent: (space) => {
                return (space.constrYear !== undefined && space.constrYear !== null);
            },
        },
        [FacilityMapTypes.SOUND]: {
            getFill: (space) => getIEQFill(space.sound),
            isPresent: (space) => {
                return space.sound !== undefined && space.sound !== null;
            },
        },
        [FacilityMapTypes.TEMPERATURE]: {
            getFill: (space) => getIEQFill(space.temperature),
            isPresent: (space) => {
                return (space.temperature !== undefined &&
                    space.temperature !== null);
            },
        },
        [FacilityMapTypes.CO2]: {
            getFill: (space) => getIEQFill(space.co2),
            isPresent: (space) => {
                return space.co2 !== undefined && space.co2 !== null;
            },
        },
        [FacilityMapTypes.LIGHT]: {
            getFill: (space) => getIEQFill(space.light),
            isPresent: (space) => {
                return space.light !== undefined && space.light !== null;
            },
        },
        [FacilityMapTypes.HUMIDITY]: {
            getFill: (space) => getIEQFill(space.humidity),
            isPresent: (space) => {
                return space.humidity !== undefined && space.humidity !== null;
            },
        },
        [FacilityMapTypes.CASEWORK]: {
            getFill: (space) => {
                const assetType = findMeasureAsset(space, FacilityMapTypes.CASEWORK);
                const assetTypeInfo = Object.values(casework).find((value) => assetType === value.type);
                const selectedColor = assetTypeInfo
                    ? assetTypeInfo.color + selectedTransparency
                    : '';
                const unselectedColor = assetTypeInfo
                    ? assetTypeInfo.color + unselectedTransparency
                    : '';
                return {
                    selected: selectedColor,
                    unselected: unselectedColor,
                };
            },
            isPresent: (space) => findMeasureAsset(space, FacilityMapTypes.CASEWORK),
        },
        [FacilityMapTypes.FLOORING]: {
            getFill: (space) => {
                const assetType = findMeasureAsset(space, FacilityMapTypes.FLOORING);
                const assetTypeInfo = Object.values(flooring).find((value) => assetType === value.type);
                const selectedColor = assetTypeInfo
                    ? assetTypeInfo.color + selectedTransparency
                    : '';
                const unselectedColor = assetTypeInfo
                    ? assetTypeInfo.color + unselectedTransparency
                    : '';
                return {
                    selected: selectedColor,
                    unselected: unselectedColor,
                };
            },
            isPresent: (space) => findMeasureAsset(space, FacilityMapTypes.FLOORING),
        },
        [FacilityMapTypes.CEILING]: {
            getFill: (space) => {
                const assetType = findMeasureAsset(space, FacilityMapTypes.CEILING);
                const assetTypeInfo = Object.values(ceiling).find((value) => assetType === value.type);
                const selectedColor = assetTypeInfo
                    ? assetTypeInfo.color + selectedTransparency
                    : '';
                const unselectedColor = assetTypeInfo
                    ? assetTypeInfo.color + unselectedTransparency
                    : '';
                return {
                    selected: selectedColor,
                    unselected: unselectedColor,
                };
            },
            isPresent: (space) => findMeasureAsset(space, FacilityMapTypes.CEILING),
        },
        [FacilityMapTypes.HVAC]: {
            getFill: (space) => {
                return {
                    selected: getHVACColor(space.facilityAssets, true),
                    unselected: getHVACColor(space.facilityAssets, false),
                };
            },
            isPresent: (space) => !!space.facilityAssets.length,
        },
    };
    const getMeasureShapes = (spaces, measure, showAgeAdjustedCondition, selectedSpaceIdentity) => {
        return spaces.map((space) => {
            return {
                identity: space.spaceIdentity,
                shape: space.shape.map((point) => {
                    var _a;
                    return ({
                        x: point.x,
                        y: point.y,
                        pointIndex: (_a = point.pointIndex) !== null && _a !== void 0 ? _a : 0,
                    });
                }),
                style: getMeasureStyleFromSpace(space, measure, showAgeAdjustedCondition),
                isSelected: space.spaceIdentity === selectedSpaceIdentity,
            };
        });
    };
    const getMeasureSplitShapes = (spaces, measure, showAgeAdjustedCondition, selectedSpaceIdentity) => {
        if (!spaces || measure !== FacilityMapTypes.FLOORING)
            return [];
        return spaces.flatMap((space) => {
            const flooringAssets = space.assets.filter((asset) => asset.name.includes('Flooring'));
            if (flooringAssets.length === 0)
                return [];
            const fillColors = flooringAssets.map((asset) => {
                const flooringInfo = Object.values(flooring).find((value) => value.type === asset.platformAssetType);
                return flooringInfo;
            });
            let style = getMeasureStyleFromSpace(space, measure, showAgeAdjustedCondition);
            if (fillColors.length > 0) {
                style = Object.assign(Object.assign({}, style), { gradientFill: fillColors.map((colorInfo) => ({
                        selected: colorInfo ? colorInfo.color + '90' : '',
                        unselected: colorInfo ? colorInfo.color + '50' : '',
                    })) });
            }
            return {
                identity: space.spaceIdentity,
                shape: space.shape.map((point) => {
                    var _a;
                    return ({
                        x: point.x,
                        y: point.y,
                        pointIndex: (_a = point.pointIndex) !== null && _a !== void 0 ? _a : 0,
                    });
                }),
                style,
                isSelected: space.spaceIdentity === selectedSpaceIdentity,
            };
        });
    };
    return {
        getShape,
        getViewOnlyShape,
        getPin,
        getMeasureShapes,
        getMeasureSplitShapes,
    };
};
