var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { BlobServiceClient } from '@azure/storage-blob';
const containerName = process.env.REACT_APP_FCA_STORAGE_CONTAINER;
const sasToken = (_a = process.env.REACT_APP_BLOB_SAS_TOKEN) !== null && _a !== void 0 ? _a : '';
const storageAccountName = 'mysiteiqstorage';
const BLOB_STORE_BASE_URL = `https://${storageAccountName}.blob.core.windows.net`;
export const BLOB_STORE_CONTAINER_URL = `${BLOB_STORE_BASE_URL}/${containerName}`;
export const uploadFileToBlob = (file, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    if (!file || !containerName) {
        return;
    }
    // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
    const blobService = new BlobServiceClient(`${BLOB_STORE_BASE_URL}/?${sasToken}`);
    // get Container - full public read access
    const containerClient = blobService.getContainerClient(containerName);
    // upload file
    return createBlobInContainer(containerClient, file, fileName);
});
const createBlobInContainer = (containerClient, file, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(fileName);
    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    // upload file
    yield blobClient.uploadData(file, options);
});
