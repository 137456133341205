import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CopyPasteProvider } from '@/contexts/CopyPaste/CopyPasteProvider';
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { selectFacility } from '@/store/facilitySlice';
import { HStack, ListItem, Text, useDisclosure } from '@chakra-ui/react';
import { ActionMenu } from '@frontend/design-system/components/ActionMenu/ActionMenu';
import { useState } from 'react';
import { FaCopy, FaTrash } from 'react-icons/fa';
import { appColors } from '../../../../config/constants';
import { PageModes, } from '../../../../objects/FCAInterfaces';
import { useAppSelector } from '../../../../store/hooks';
import { facilityRoleCanEdit, selectFacilityRole, } from '../../../../store/userSlice';
import { DeleteAssetModal } from '../DetailsSidebar/Asset/DeleteAssetModal';
import { CopyAsset } from './CopyModal/CopyAsset';
import { SidebarItem } from './SidebarItem';
import useLongPress from './useLongPress';
export const AssetSidebarItem = ({ plans, asset, spaceIdentity, scrolling, setScrolling, scrollingAuto, setScrollingAuto, }) => {
    const [isOpenActions, setIsOpenActions] = useState(false);
    const currentFacility = useAppSelector(selectFacility);
    const facilityRole = useAppSelector(selectFacilityRole);
    const canEdit = facilityRoleCanEdit(facilityRole);
    const { isOpen: isOpenAssetDelete, onOpen: onOpenAssetDelete, onClose: onCloseAssetDelete, } = useDisclosure();
    const { isOpen: isOpenAssetCopy, onOpen: onOpenAssetCopy, onClose: onCloseAssetCopy, } = useDisclosure();
    const { selectAsset: selectCurrentAsset, setPageMode, selectedSpaceIdentity, selectedAssetIdentity, } = useFacilityContext();
    const actions = [
        {
            name: 'Delete',
            icon: _jsx(FaTrash, { fontSize: '18px' }),
            display: 'block',
            handleClick: () => onOpenAssetDelete(),
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    onOpenAssetDelete();
                    setIsOpenActions(false);
                }
            },
        },
        {
            name: 'Copy',
            icon: _jsx(FaCopy, { fontSize: '18px' }),
            display: 'block',
            handleClick: () => onOpenAssetCopy(),
            handleTouch: () => {
                if (scrolling) {
                    setScrolling(false);
                }
                else {
                    onOpenAssetCopy();
                    setIsOpenActions(false);
                }
            },
        },
    ];
    const resetPageModeView = () => {
        setPageMode(PageModes.VIEW);
    };
    const handleTouchMove = () => {
        if (!scrolling) {
            setScrolling(true);
        }
        if (!scrollingAuto) {
            setScrollingAuto(true);
        }
    };
    const handleMouseDown = () => {
        resetPageModeView();
        selectedSpaceIdentity &&
            selectCurrentAsset(asset.identity, spaceIdentity);
    };
    const handleTouchEnd = () => {
        if (scrolling) {
            setScrolling(false);
        }
        else {
            resetPageModeView();
            selectedSpaceIdentity &&
                selectCurrentAsset(asset.identity, spaceIdentity);
        }
    };
    const handleContextMenu = () => {
        if (scrolling) {
            setScrolling(false);
        }
        else {
            resetPageModeView();
            setIsOpenActions(true);
        }
    };
    const onLongPress = () => {
        // TODO: Do we want all the stuff in handleClick to fire when they do a longpress or should we just have longpress disabled while they are actively editing or creating a space/asset.
        setIsOpenActions(true);
    };
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, defaultOptions);
    return (_jsxs(_Fragment, { children: [_jsx(SidebarItem, Object.assign({ styleProps: asset.identity === selectedAssetIdentity && {
                    bg: appColors.KONVA_BLUE,
                    position: 'relative',
                } }, { children: _jsxs(_Fragment, { children: [_jsx(ListItem, Object.assign({ ml: 7, onContextMenu: (e) => e.preventDefault(), onTouchEnd: handleTouchEnd, onMouseDown: handleMouseDown, paddingY: 1 }, { children: _jsx(HStack, Object.assign({}, longPressEvent, { onContextMenu: handleContextMenu, w: '100%', userSelect: 'none', spacing: '15px' }, { children: _jsx(Text, Object.assign({ fontSize: '14px' }, { children: asset.name })) })) })), canEdit && (_jsx(ActionMenu, { actions: actions, isOpenActions: isOpenActions, onCloseActions: () => setIsOpenActions(false), handleTouchMove: handleTouchMove, uniqueStyles: {
                                top: 5,
                            } }))] }) })), _jsx(DeleteAssetModal, { isOpen: isOpenAssetDelete, onClose: onCloseAssetDelete }), currentFacility && (_jsx(CopyPasteProvider, Object.assign({ isModalOpen: isOpenAssetCopy, copyType: 'Asset', facilityIdentity: currentFacility.facilityIdentity, selectedSpaceIdentity: selectedSpaceIdentity }, { children: _jsx(CopyAsset, { plans: plans, assetIdentity: asset.identity, isOpen: isOpenAssetCopy, onClose: onCloseAssetCopy }) })))] }));
};
