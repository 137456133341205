import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { RiArrowLeftRightFill } from 'react-icons/ri';
import { appColors } from '../../config/constants';
import { variants } from '../../config/theme';
import { usePhotoSelector } from '../../contexts/Photo/PhotoSelectContext';
import { SelectionType } from '../../contexts/Photo/PhotoSelectProvider';
import { MovePhotoModal } from '../../pages/Main/Sidebar/DetailsSidebar/PhotoGalleryModal/MovePhotos/MovePhotoModal';
export const MovePhotoButton = () => {
    const { startSelecting, stopSelecting, isMoving, selectedPhotos, isSelecting, } = usePhotoSelector();
    const { isOpen: isOpenPhotoMove, onOpen: onOpenPhotoMove, onClose: onClosePhotoMove, } = useDisclosure();
    const onMoveButton = () => {
        startSelecting(SelectionType.MOVE);
    };
    return (_jsx(_Fragment, { children: isSelecting ? (isMoving && (_jsxs(_Fragment, { children: [_jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ variant: variants.transparentRedBtn, onClick: stopSelecting }, { children: "Cancel" })), _jsxs(Button, Object.assign({ isDisabled: selectedPhotos.length === 0, variant: variants.blueBtn, onClick: onOpenPhotoMove }, { children: [_jsx(Box, Object.assign({ mr: '8px' }, { children: _jsx(RiArrowLeftRightFill, { color: 'white' }) })), "Move To"] }))] }), _jsx(MovePhotoModal, { isOpen: isOpenPhotoMove, close: onClosePhotoMove, open: onOpenPhotoMove })] }))) : (_jsxs(Button, Object.assign({ variant: variants.transparentBlueBtn, onClick: onMoveButton }, { children: [_jsx(Box, Object.assign({ mr: '8px' }, { children: _jsx(RiArrowLeftRightFill, { color: appColors.PRIM_BLUE }) })), "Move"] }))) }));
};
