import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading, HStack, Image, Link, Stack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { appValues } from '../../config/constants';
import { Paths } from '../../config/paths';
import { variants } from '../../config/theme';
import logo from '../../images/Logo_OrangeWhite.png';
export const FacilityPageHeader = ({ orgId, orgName, siteName, facilityName, }) => {
    return (_jsxs(HStack, Object.assign({ alignItems: 'center', bg: 'slgq.primBlue', color: 'white', h: `${appValues.HEADER_HEIGHT_MAIN}px`, justifyContent: 'space-between' }, { children: [_jsx(Link, Object.assign({ h: '100%', px: '16px', as: RouterLink, replace: true, to: `${Paths.ORGS}/${orgId}${Paths.SITES}` }, { children: _jsx(Stack, Object.assign({ h: '100%', justifyContent: 'center' }, { children: _jsx(Image, { src: logo, alt: 'logo', px: '16px' }) })) })), _jsx(Link, Object.assign({ as: RouterLink, replace: true, to: `${Paths.ORGS}/${orgId}${Paths.SITES}` }, { children: _jsxs(Heading, Object.assign({ as: 'h1', fontWeight: 400, variant: variants.regSizeH }, { children: [orgName, " / ", siteName, " / ", facilityName] })) })), _jsx(HStack, { children: _jsx(Box, { w: '101px' }) })] })));
};
