import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Circle, Text } from '@chakra-ui/react';
import { PhotoThumbnail } from '@frontend/design-system/components/Photos/PhotoThumbnail';
import { PhotoWidgetsOverlay } from '@frontend/design-system/components/Photos/PhotoWidgetsOverlay';
import { SelectCheckbox } from '@frontend/design-system/components/Photos/SelectCheckbox';
import { CommentIcon } from '@frontend/design-system/theme/icons/CommentIcon';
import { FavoriteIcon } from '@frontend/design-system/theme/icons/FavoriteIcon';
import { useEffect, useState } from 'react';
import { BiPlay } from 'react-icons/bi';
import { usePhotoContext } from '../../../../../contexts/Photo/PhotoContext';
import { useAppSelector } from '../../../../../store/hooks';
import { selectOrganization } from '../../../../../store/orgSlice';
import { toBlobStoreContainerUrl } from '../../../../../utils/photoFileUtils';
export const ThumbnailItem = ({ photo, maxH, borderRadius, handleClick, isSelected = false, isSelectMode = false, isSelectDisabled = false, onSelect = () => { }, shouldHover = false, }) => {
    var _a, _b, _c, _d, _e;
    const org = useAppSelector(selectOrganization);
    const [photoUrl, setPhotoUrl] = useState(toBlobStoreContainerUrl(photo.name));
    useEffect(() => {
        org &&
            setPhotoUrl(toBlobStoreContainerUrl(photo.name, org.orgIdentity));
    }, [org]);
    const videoSelected = ['.mov', '.mp4'].includes(photo.name.slice(-4, photo.name.length).toLowerCase());
    const { updateFavorite, getSinglePhoto } = usePhotoContext();
    const leftOverlayItems = (_jsx(_Fragment, { children: isSelectMode && (_jsx(SelectCheckbox, { isSelected: isSelected, onSelect: onSelect, isDisabled: isSelectDisabled })) }));
    const rightOverlayItems = (_jsxs(_Fragment, { children: [_jsx(FavoriteIcon, { style: { cursor: 'pointer', pointerEvents: 'all' }, onClick: () => {
                    if (!isSelectMode) {
                        updateFavorite(photo);
                    }
                }, filled: photo.favorite }), ((_a = photo.comments) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx(CommentIcon, { boxSize: '30px', commentsnum: photo.comments.length }))] }));
    const hoverOverlayItem = (_jsxs(_Fragment, { children: [_jsx(Box, { h: '10%' }), _jsxs(Box, Object.assign({ display: 'flex', flexFlow: 'column' }, { children: [_jsx(Text, Object.assign({ textAlign: 'left', fontWeight: '900', color: '#fff' }, { children: (_b = photo.facility) === null || _b === void 0 ? void 0 : _b.name })), _jsxs(Text, Object.assign({ textAlign: 'left', color: '#fff' }, { children: [(_c = photo.space) === null || _c === void 0 ? void 0 : _c.name, " \u00A0"] })), _jsxs(Text, Object.assign({ textAlign: 'left', color: '#fff' }, { children: [(_d = photo.asset) === null || _d === void 0 ? void 0 : _d.name, " \u00A0"] })), _jsxs(Box, Object.assign({ display: 'flex', flexFlow: 'row wrap', alignItems: 'center' }, { children: [photo.tags &&
                                photo.tags
                                    .slice(0, photo.tags.length > 4 ? 4 : photo.tags.length)
                                    .map((tag, i) => (_jsx(Text, Object.assign({ bgColor: tag.color, h: 'fit-content', padding: '0rem .25rem', border: `2px solid ${tag.color}`, fontWeight: '600', color: '#2E3532', borderRadius: '2px', margin: '.25rem .25rem .25rem 0rem' }, { children: tag.name }), tag.name + i))), photo.tags.length > 4 && (_jsx(Text, Object.assign({ h: 'fit-content', padding: '0rem .25rem', backgroundColor: 'unset', border: '2px solid #fff', fontWeight: '600', color: '#fff', borderRadius: '2px', margin: 'calc(.25rem)', marginLeft: '0px' }, { children: `+ ${((_e = photo.tags) === null || _e === void 0 ? void 0 : _e.length) - 4}` })))] }))] }))] }));
    return (_jsx(PhotoWidgetsOverlay, Object.assign({ leftOverlayItems: leftOverlayItems, rightOverlayItems: rightOverlayItems, handleClick: () => {
            getSinglePhoto(photo.id);
            handleClick && handleClick();
        }, hoverItem: shouldHover && hoverOverlayItem, borderRadius: borderRadius }, { children: !videoSelected ? (_jsx(PhotoThumbnail, { imageUrl: photoUrl, altText: photo.name, maxH: maxH, borderRadius: borderRadius })) : (
        //TODO Video Thumbnail?
        _jsxs(_Fragment, { children: [_jsxs("video", Object.assign({ "data-testid": 'video-player', id: 'video-player', style: {
                        width: '100%',
                        height: '100%',
                        maxHeight: maxH,
                    }, preload: 'auto', "data-setup": '{responsive: true}' }, { children: [_jsx("source", { src: photoUrl, type: 'video/mp4' }), _jsx("p", { children: "To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video" })] })), _jsx(Circle, Object.assign({ size: '80px', position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', right: '50', bg: '#00000080', "aria-label": 'favorite photo' }, { children: _jsx(BiPlay, { size: '40px', color: '#FBDABD', style: { transform: 'translate(3px,0px)' } }) }))] })) })));
};
