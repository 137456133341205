import { combineReducers, configureStore, } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import dbCacheReducer from './dbCacheSlice';
import facilityReducer from './facilitySlice';
import featureFlagReducer from './featureFlagSlice';
import orgReducer from './orgSlice';
import photoReducer from './photoSlice';
import siteReducer from './siteSlice';
import userReducer from './userSlice';
export const history = createBrowserHistory();
const rootReducer = combineReducers({
    facility: facilityReducer,
    featureFlag: featureFlagReducer,
    organization: orgReducer,
    photo: photoReducer,
    site: siteReducer,
    user: userReducer,
    dbCache: dbCacheReducer,
    router: connectRouter(history),
});
export const store = setupStore();
export function setupStore(preloadedState) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
}
setupListeners(store.dispatch);
