import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@chakra-ui/react';
import { useAppSelector } from '../../../../../store/hooks';
import { facilityRoleCanEdit, selectFacilityRole, } from '../../../../../store/userSlice';
import { PhotoGridItem } from './PhotoGridItem';
export const AddPhotoGridItemDisabled = () => {
    const facilityRole = useAppSelector(selectFacilityRole);
    const canEdit = facilityRoleCanEdit(facilityRole);
    return !canEdit ? (_jsx(_Fragment, {})) : (_jsx(PhotoGridItem, { children: _jsx(Button, Object.assign({ w: '100%', h: '220px', pointerEvents: 'none', whiteSpace: 'unset', display: 'block', backgroundColor: '#818584', color: '#fff' }, { children: "To add photos, please choose an asset type in the specific asset tab." })) }));
};
