import { faker } from '@faker-js/faker';
import { AssetClassType } from '../objects/FCAInterfaces';
const MAX_ARRAY_LENGTH = 20;
export class PlatformAssetBuilder {
    constructor(assetOverrides) {
        this.build = () => {
            return Object.assign({ assetId: faker.number.int({ min: undefined, max: undefined }), assetName: faker.lorem.slug(1), assetClassType: faker.helpers.arrayElement([
                    AssetClassType.PINNED,
                    AssetClassType.STANDARD,
                    AssetClassType.FACILITY,
                ]), assetCategory: faker.lorem.slug(1), assetClass: faker.lorem.slug(1), assetType: faker.lorem.slug(1), modelNumber: faker.string.sample(), serialNumber: faker.string.sample(), installedYear: faker.number.int({ min: undefined, max: undefined }), averageLifeExpectancy: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), replacementScore: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), note: faker.lorem.slug(1), expectedEndOfLifeYear: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), conditions: [
                    ...new Array(faker.number.int({ min: 1, max: MAX_ARRAY_LENGTH })).keys(),
                ].map((_) => ({
                    condition: faker.number.int({ min: undefined, max: undefined }),
                    ageAdjustedCondition: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    assessedOn: faker.date.recent().toISOString(),
                    auditorFirstName: faker.person.firstName(),
                    auditorLastName: faker.person.lastName(),
                    auditedOn: faker.date.recent().toISOString(),
                })), pin: {
                    xPosition: faker.number.int({ min: undefined, max: undefined }),
                    yPosition: faker.number.int({ min: undefined, max: undefined }),
                }, survey: {
                    versionId: faker.number.int({ min: undefined, max: undefined }),
                    answers: [
                        ...new Array(faker.number.int({ min: 1, max: MAX_ARRAY_LENGTH })).keys(),
                    ].map((_) => ({
                        name: faker.person.fullName(),
                        value: null,
                    })),
                }, photos: [
                    ...new Array(faker.number.int({ min: 1, max: MAX_ARRAY_LENGTH })).keys(),
                ].map((_) => ({
                    photoId: faker.number.int({ min: undefined, max: undefined }),
                    fileUrl: faker.internet.url(),
                    takenOn: faker.date.recent().toISOString(),
                    isFavorite: faker.datatype.boolean(),
                    tags: [
                        ...new Array(faker.number.int({ min: 1, max: MAX_ARRAY_LENGTH })).keys(),
                    ].map((_) => faker.lorem.slug(1)),
                })), isFacilityAsset: faker.datatype.boolean(), spaceId: faker.number.int({ min: undefined, max: undefined }) }, this.assetOverrides);
        };
        this.assetOverrides = assetOverrides;
    }
}
export class PlatformAssetSummaryResponseBuilder {
    constructor(assetOverrides) {
        this.build = (spaceId) => {
            return Object.assign({ assetId: faker.number.int({ min: undefined, max: undefined }), spaceId: spaceId || faker.number.int({ min: undefined, max: undefined }), assetName: faker.lorem.slug(1), assetClassType: faker.helpers.arrayElement([
                    AssetClassType.PINNED,
                    AssetClassType.STANDARD,
                    AssetClassType.FACILITY,
                ]), assetCategory: faker.lorem.slug(1), assetClass: faker.lorem.slug(1), assetType: faker.lorem.slug(1), pin: {
                    xPosition: faker.number.int({ min: undefined, max: undefined }),
                    yPosition: faker.number.int({ min: undefined, max: undefined }),
                }, isFacilityAsset: faker.datatype.boolean(), quantity: faker.number.int({ min: 1, max: 100 }) }, this.assetOverrides);
        };
        this.assetOverrides = assetOverrides;
    }
}
