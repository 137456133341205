import { environment } from './environment';
export const Paths = {
    HOME: '/',
    FACILITY: 'facility',
    FACILITY_DYNAMIC: 'facility/:fidentity',
    LOGOUT: '/logout',
    ORGS: '/organizations',
    ORG_SITES: '/organizations/:oid/sites',
    SITES: '/sites',
    GALLERY: '/gallery',
    GALLERY_DYNAMIC: '/gallery/:oid',
    SUPPORT: '/support',
};
export const ExternalPaths = {
    getFCAUrl: () => `${environment.fcaUrl}/organizations/`,
    getFCASitesUrl: (oid) => `${environment.fcaUrl}/organizations/${oid}/sites`,
    getMyGallery: () => `${Paths.GALLERY}`,
    getMyOrgGalleryUrl: (oid) => `${Paths.GALLERY}/${oid}`,
    getPlannerUrl: () => `${environment.plannerUrl}`,
    getMyProjectsUrl: () => `${environment.plannerUrl}/projects`,
    getMyOrgProjectsUrl: (oid) => `${environment.plannerUrl}/projects/${oid}`,
    getMyScenariosUrl: () => `${environment.plannerUrl}/scenario-planner`,
    getMyOrgScenariosUrl: (oid) => `${environment.plannerUrl}/scenario-planner/${oid}`,
    getMyAssetsUrl: () => `${environment.plannerUrl}/myassets`,
    getMyOrgAssetsUrl: (oid) => `${environment.plannerUrl}/myassets/${oid}`,
    getMyOpportunitiesUrl: (oid) => `${environment.rootPathUrl}/prioritize/opportunities/${oid}/facilities`,
    getSystemAdminUrl: () => `${environment.msiqUrl}/systemAdmin`,
    getOrgSettingsUrl: (oid) => `${environment.msiqUrl}/organizationDetails/${oid}`,
    getOneReportUrl: (oid, wid, rid) => `${environment.msiqUrl}/reports/${oid}/${wid}/${rid}`,
    getReportUrl: (oid, rid) => `${environment.msiqUrl}/report/${oid}/${rid}`,
    getUserProfileUrl: () => `${environment.msiqUrl}/userProfile`,
    getReportsUrl: () => `${environment.msiqUrl}`,
    getReportsOrgUrl: (oid) => `${environment.msiqUrl}/organizationPreview/${oid}`,
};
