import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePhotoContext } from '@/contexts/Photo/PhotoContext';
import { Box, Center, Modal, ModalCloseButton, ModalContent, } from '@chakra-ui/react';
import { Pannellum } from 'pannellum-react';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectOrganization } from '../../../../../store/orgSlice';
import { toBlobStoreContainerUrl } from '../../../../../utils/photoFileUtils';
import { PhotoDate } from './PhotoDate';
export const FullScreenPhotoViewer = ({ panoramicSelected, onImageLoad, isOpen, onClose, }) => {
    const orgCurrent = useAppSelector(selectOrganization);
    const { selectedPhoto } = usePhotoContext();
    const [videoSelected, setVideoSelected] = useState(false);
    useEffect(() => {
        if ((selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.name.slice(-4, selectedPhoto.name.length).toLowerCase()) === '.mov' ||
            (selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.name.slice(-4, selectedPhoto.name.length).toLowerCase()) === '.mp4') {
            setVideoSelected(true);
        }
        else {
            setVideoSelected(false);
        }
    }, [selectedPhoto]);
    const renderViewer = (panoramicSelected) => {
        const compressedPhotoUrl = selectedPhoto &&
            orgCurrent &&
            toBlobStoreContainerUrl(selectedPhoto.name, orgCurrent.orgIdentity);
        if (panoramicSelected) {
            return (_jsx(Center, Object.assign({ position: 'relative' }, { children: _jsxs(Box, { children: [_jsx(Pannellum, { width: '100vw', height: '100vh', image: compressedPhotoUrl, pitch: 10, yaw: 180, hfov: 110, autoLoad: true, onLoad: () => { } }), _jsx(PhotoDate, { photo: selectedPhoto })] }) })));
        }
        else if (videoSelected) {
            return (_jsx(Center, Object.assign({ position: 'relative' }, { children: _jsxs(Box, { children: [_jsxs("video", Object.assign({ id: 'video-player', style: { height: '100vh' }, preload: 'auto', "data-setup": '{height: 100%, maxHeight: 50vh, responsive: true}', controls: true }, { children: [_jsx("source", { src: compressedPhotoUrl, type: 'video/mp4' }), _jsx("p", { children: "To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video" })] })), _jsx(PhotoDate, { photo: selectedPhoto })] }) })));
        }
        else {
            return (_jsx(Center, Object.assign({ position: 'relative' }, { children: _jsx("img", { onLoad: onImageLoad, style: {
                        width: '100%',
                        objectFit: 'contain',
                    }, src: compressedPhotoUrl, alt: selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.name }) })));
        }
    };
    return (_jsx(Modal, Object.assign({ size: 'full', isOpen: isOpen, onClose: onClose }, { children: _jsxs(ModalContent, { children: [_jsx(ModalCloseButton, { zIndex: 99, size: 'lg', background: 'white', _hover: { opacity: '40%' }, opacity: '20%' }), renderViewer(panoramicSelected)] }) })));
};
