import { jsx as _jsx } from "react/jsx-runtime";
import { HStack, useToken } from '@chakra-ui/react';
import { CustomLineChart } from '../Chart/CustomLineChart';
export var ConditionType;
(function (ConditionType) {
    ConditionType["Observed"] = "Observed";
    ConditionType["AgeAdjusted"] = "Age Adjusted";
})(ConditionType || (ConditionType = {}));
export const ConditionChart = ({ graphConfig }) => {
    var _a;
    const labels = graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.chartData.data.datasets[0].years;
    const dataset1 = graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.chartData.data.datasets.filter((dataset) => dataset.type === ConditionType.Observed)[0].values;
    const dataset2 = graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.chartData.data.datasets.filter((dataset) => dataset.type === ConditionType.AgeAdjusted)[0].values;
    const [orange] = useToken('colors', ['slgq.secOrange']);
    // Did not add these to theme because they are used elsewhere and want to confirm with Ana on naming
    const theme1 = {
        observed: { line: orange, bullet: '#FEDB5E' },
    };
    const theme2 = {
        observed: { line: '#013b5d', bullet: '#b5c3ce' },
    };
    const data = {
        labels,
        datasets: [
            {
                label: ConditionType.Observed,
                pointBackgroundColor: theme1.observed.bullet,
                pointBorderColor: theme1.observed.bullet,
                data: dataset1 !== null && dataset1 !== void 0 ? dataset1 : [],
                borderColor: theme1.observed.line,
                backgroundColor: orange,
            },
            {
                label: ConditionType.AgeAdjusted,
                pointBackgroundColor: theme2.observed.bullet,
                pointBorderColor: theme2.observed.bullet,
                data: dataset2 !== null && dataset2 !== void 0 ? dataset2 : [],
                borderColor: theme2.observed.line,
                backgroundColor: theme2.observed.line,
            },
        ],
    };
    const legend = [
        { text: ConditionType.Observed, color: '#f68529' },
        { text: ConditionType.AgeAdjusted, color: '#013b5d' },
    ];
    const options = {
        clip: { left: 10, top: 10, right: 10, bottom: 10 },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'year',
                },
                ticks: {
                    color: (e) => {
                        if (graphConfig.chartData.data.endOfLife &&
                            e.tick.label ===
                                graphConfig.chartData.data.endOfLife)
                            return 'red';
                        else
                            return 'black';
                    },
                },
            },
            y: {
                min: 1,
                max: 5,
                reverse: true,
                ticks: {
                    stepSize: 1,
                    callback: (e) => {
                        switch (e) {
                            case 1:
                                return '1 (Excellent)';
                            case 2:
                                return '2 (Acceptable)';
                            case 3:
                                return '3 (Caution)';
                            case 4:
                                return '4 (Alert)';
                            case 5:
                                return '5 (Alarm)';
                            default:
                                return '';
                        }
                    },
                },
            },
        },
    };
    return (_jsx(HStack, Object.assign({ w: '100%', pb: '70px', pl: '20px', pr: '20px' }, { children: _jsx(CustomLineChart, { data: data, options: options, legend: legend, title: (_a = graphConfig.chartData.title) !== null && _a !== void 0 ? _a : '' }) })));
};
