export var OrgContactType;
(function (OrgContactType) {
    OrgContactType[OrgContactType["CLIENT_CONCIERGE"] = 0] = "CLIENT_CONCIERGE";
    OrgContactType[OrgContactType["PRIMARY_CONTACT"] = 1] = "PRIMARY_CONTACT";
})(OrgContactType || (OrgContactType = {}));
export var SystemRole;
(function (SystemRole) {
    SystemRole["SUPER_ADMIN"] = "SuperAdministrator";
    SystemRole["ORG_APPROVER"] = "OrganizationApprover";
    SystemRole["USER"] = "User";
})(SystemRole || (SystemRole = {}));
export var UserTypes;
(function (UserTypes) {
    UserTypes["admin"] = "admin";
    UserTypes["concierge"] = "concierge";
    UserTypes["customer"] = "customer";
    UserTypes["none"] = "none";
})(UserTypes || (UserTypes = {}));
