import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@chakra-ui/react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useAppSelector } from '../../../../../store/hooks';
import { facilityRoleCanEdit, selectFacilityRole, } from '../../../../../store/userSlice';
import { PhotoGridItem } from './PhotoGridItem';
export const AddPhotoGridItem = ({ onClick, }) => {
    const facilityRole = useAppSelector(selectFacilityRole);
    const canEdit = facilityRoleCanEdit(facilityRole);
    return !canEdit ? (_jsx(_Fragment, {})) : (_jsx(PhotoGridItem, { children: _jsxs(Button, Object.assign({ w: '100%', h: '220px', onClick: onClick }, { children: [_jsx(AiOutlinePlusCircle, {}), "Add Photo"] })) }));
};
