import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SimpleGrid, Stack, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { GalleryGridHeading } from './GalleryGridHeading';
import { GalleryGridItem } from './GalleryGridItem';
import { GallerySliderController } from './GallerySliderController';
export const GalleryGridRow = ({ title, photos }) => {
    var _a;
    const [displayAll, setDisplayAll] = useState(true);
    const [startPoint, setStartPoint] = useState(0);
    const photosPerRow = (_a = useBreakpointValue({ sm: 2, md: 3, lg: 5 })) !== null && _a !== void 0 ? _a : 5;
    const endReached = Math.max(photos.length - photosPerRow, 0);
    useEffect(() => {
        if (photos.length <= 5) {
            setStartPoint(0);
        }
    }, [photos]);
    const toggleSeeAll = () => {
        setDisplayAll(!displayAll);
    };
    const showPrev = () => {
        setStartPoint(startPoint - photosPerRow);
    };
    const showNext = () => {
        setStartPoint(startPoint + photosPerRow);
    };
    const displayedPhotos = displayAll
        ? photos
        : photos.slice(startPoint, startPoint + photosPerRow);
    return (_jsxs(Stack, Object.assign({ spacing: 4 }, { children: [_jsx(GalleryGridHeading, { title: title, displayAll: displayAll, toggleSeeAll: toggleSeeAll }), _jsxs(SimpleGrid, Object.assign({ columns: { sm: 2, md: 3, lg: 5 }, spacing: 5, position: 'relative' }, { children: [startPoint > 0 && !displayAll && (_jsx(GallerySliderController, { direction: 'left', handleSlide: showPrev })), displayedPhotos.map((photo, index) => (_jsx(GalleryGridItem, { photo: photo, index: index }, index))), startPoint < endReached && !displayAll && (_jsx(GallerySliderController, { direction: 'right', handleSlide: showNext }))] }))] })));
};
