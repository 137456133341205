import { convertAssetPinResponseToLocation } from '../services/useAssetApi';
export const stringToInt = (value) => {
    return !value || value.length < 1 ? undefined : parseInt(value);
};
export const convertAssetToCurrentAsset = (asset) => {
    return {
        name: asset.name,
        identity: asset.identity,
        location: asset.pin ? { x: asset.pin.x, y: asset.pin.y } : undefined,
    };
};
export const convertAssetToAssetForm = (asset, name, assetTemplate) => {
    var _a, _b, _c, _d;
    // this should be used to determine default values if current asset is undefined
    const assetToReturn = {
        identity: asset === null || asset === void 0 ? void 0 : asset.identity,
        // pinned asset dont initialize with name but standard do
        name: name ? name : (asset === null || asset === void 0 ? void 0 : asset.name) || '',
        // pinned asset dont initialize with class but standard do
        assetClass: (assetTemplate === null || assetTemplate === void 0 ? void 0 : assetTemplate.assetClass)
            ? assetTemplate.assetClass
            : (asset === null || asset === void 0 ? void 0 : asset.assetClass) || '',
        assetType: (asset === null || asset === void 0 ? void 0 : asset.assetType) || '',
        approximateInstallYear: ((_a = asset === null || asset === void 0 ? void 0 : asset.approxYear) === null || _a === void 0 ? void 0 : _a.toString()) || '',
        assetCondition: ((_b = asset === null || asset === void 0 ? void 0 : asset.condition) === null || _b === void 0 ? void 0 : _b.toString()) || '0',
        ageAdjustedCondition: ((_c = asset === null || asset === void 0 ? void 0 : asset.ageAdjustedCondition) === null || _c === void 0 ? void 0 : _c.toString()) || '0',
        modelNumber: (asset === null || asset === void 0 ? void 0 : asset.modelNumber) || '',
        serialNumber: (asset === null || asset === void 0 ? void 0 : asset.serialNumber) || '',
        location: asset === null || asset === void 0 ? void 0 : asset.pin,
        notes: asset === null || asset === void 0 ? void 0 : asset.note,
        isFacilityWide: asset === null || asset === void 0 ? void 0 : asset.isFacilityWide,
        questions: createQuestionsForAssetForm((_d = asset === null || asset === void 0 ? void 0 : asset.questions) !== null && _d !== void 0 ? _d : [], assetTemplate, asset === null || asset === void 0 ? void 0 : asset.assetType),
        // assetConditions: asset?.conditions,
    };
    return assetToReturn;
};
export const createQuestionsForAssetForm = (assetQuestions, assetTemplate, assetType) => {
    var _a, _b;
    const questions = [];
    if (!assetTemplate) {
        return questions;
    }
    if (assetTemplate.survey && assetTemplate.survey.length > 0) {
        const currentQuestions = assetTemplate.survey.filter((question) => !question.triggeredBy);
        const triggeredQuestions = (_a = assetTemplate.survey.filter((question) => question.triggeredBy)) !== null && _a !== void 0 ? _a : [];
        for (const question of triggeredQuestions) {
            const q = (_b = question.triggeredBy) === null || _b === void 0 ? void 0 : _b.filter((trigger) => trigger.values.includes(assetType));
            if (q === null || q === void 0 ? void 0 : q.length)
                currentQuestions.push(question);
        }
        for (const templateQuestion of currentQuestions) {
            const matchingQuestion = assetQuestions.find((question) => question.name === templateQuestion.name);
            questions.push({
                name: templateQuestion.name,
                value: matchingQuestion === null || matchingQuestion === void 0 ? void 0 : matchingQuestion.value,
            });
        }
    }
    return questions;
};
export const convertAssetFormToAssessmentRequest = (spaceIdentity, assetForms, getAssetTemplateVersionByClass) => {
    return {
        spaceId: spaceIdentity,
        assets: convertAssetFormsToAssetAssessmentRequest(assetForms, getAssetTemplateVersionByClass),
    };
};
export const getLatestAssetConditionAssessment = (assetCondition, ageAdjusted = false) => {
    if (assetCondition.length < 1) {
        return undefined;
    }
    const condition = assetCondition[assetCondition.length - 1];
    return ((ageAdjusted ? condition.ageAdjustedCondition : condition.condition) ||
        undefined);
};
const convertAssetFormsToAssetAssessmentRequest = (assetForms, getAssetTemplateVersionByClass) => {
    const assessments = [];
    assetForms.map((assetForm) => {
        const versionId = getAssetTemplateVersionByClass(assetForm.assetClass);
        const assessmentRequest = {
            assetId: assetForm.identity,
            assetClass: assetForm.assetClass,
            specification: {
                name: assetForm.name || 'Untitled',
                assetType: assetForm.assetType,
                modelNumber: assetForm.modelNumber,
                serialNumber: assetForm.serialNumber,
                installedYear: stringToInt(assetForm.approximateInstallYear),
                note: assetForm.notes,
            },
            pin: assetForm.location
                ? convertLocationToAssetPinRequest(assetForm.location)
                : undefined,
            condition: convertConditionToAssetConditionRequest(assetForm.assetCondition),
            survey: assetForm.questions && assetForm.questions.length
                ? convertQuestionToAssetSurveyRequest(assetForm.questions, versionId)
                : undefined,
            isFacilityAsset: assetForm.isFacilityWide || false,
        };
        assessments.push(assessmentRequest);
    });
    return assessments;
};
const convertLocationToAssetPinRequest = (location) => {
    return {
        xPosition: location.x,
        yPosition: location.y,
    };
};
export const convertAssetPinRequestToLocation = (pin) => {
    return {
        x: pin.xPosition,
        y: pin.yPosition,
    };
};
const convertConditionToAssetConditionRequest = (condition) => {
    let parseCondition;
    if (typeof condition === 'string') {
        parseCondition = stringToInt(condition);
    }
    else {
        parseCondition = condition;
    }
    return parseCondition && parseCondition != 0
        ? {
            condition: parseCondition,
        }
        : undefined;
};
const convertQuestionToAssetSurveyRequest = (questions, versionId) => {
    const filledOutQuestions = questions.filter((question) => question.value !== undefined);
    return {
        versionId: versionId,
        answers: filledOutQuestions.map((question) => ({
            name: question.name,
            value: question.value,
        })),
    };
};
export const convertAssetConditionResponseToCondition = (assetConditions) => {
    if (!assetConditions) {
        return undefined;
    }
    return assetConditions.map((condition) => {
        var _a, _b, _c;
        return ({
            condition: condition.condition ? condition.condition : undefined,
            ageAdjustedCondition: (_a = condition.ageAdjustedCondition) !== null && _a !== void 0 ? _a : undefined,
            assessedOn: condition.assessedOn,
            auditorFirstName: (_b = condition.auditorFirstName) !== null && _b !== void 0 ? _b : undefined,
            auditorLastName: (_c = condition.auditorLastName) !== null && _c !== void 0 ? _c : undefined,
            auditedOn: condition.auditedOn ? condition.auditedOn : undefined,
        });
    });
};
// assumes in a context that has a global space identity
export const convertToAssets = (spaceIdentity, assetDetailResponse) => {
    return assetDetailResponse.map((assetDetail) => ({
        identity: assetDetail.assetId,
        spaceIdentity: spaceIdentity,
        assetCategory: assetDetail.assetCategory,
        assetClass: assetDetail.assetClass,
        assetClassType: assetDetail.assetClassType,
        assetType: assetDetail.assetType,
        name: assetDetail.assetName,
        approxYear: assetDetail.installedYear || undefined,
        modelNumber: assetDetail.modelNumber || undefined,
        serialNumber: assetDetail.serialNumber || undefined,
        condition: getLatestAssetConditionAssessment(assetDetail.conditions),
        ageAdjustedCondition: getLatestAssetConditionAssessment(assetDetail.conditions, true),
        questions: convertAssetSurveyResponseToDynamicQuestionForm(assetDetail.survey),
        pin: convertAssetPinResponseToLocation(assetDetail.pin),
        note: assetDetail.note || undefined,
        isFacilityWide: assetDetail.isFacilityAsset,
        conditions: convertAssetConditionResponseToCondition(assetDetail.conditions),
        expectedEndOfLifeYear: assetDetail.expectedEndOfLifeYear
            ? assetDetail.expectedEndOfLifeYear
            : undefined,
    }));
};
export const convertToSingleAssetFromDetails = (spaceIdentity, assetDetailResponse) => {
    if (assetDetailResponse.length < 1 || assetDetailResponse.length > 1) {
        console.warn('Unexpected number of assets returned in response');
    }
    const assets = convertToAssets(spaceIdentity, assetDetailResponse);
    return assets[0];
};
export const convertAssetSummaryResponseToAssignedAssets = (assets) => {
    return assets
        .filter((asset) => asset.isFacilityAsset === true)
        .map((asset) => {
        return {
            isAssigned: false,
            name: asset.assetName,
            identity: asset.assetId,
        };
    });
};
export const convertAssetSummaryResponseToCurrentUniqueAsset = (asset) => {
    return {
        name: asset.assetName,
        identity: asset.assetId,
        location: asset.pin
            ? convertAssetPinRequestToLocation(asset.pin)
            : undefined,
    };
};
export const convertAssetSummaryResponseToCurrentUniqueAssetMap = (assets) => {
    const assetMap = new Map();
    assets.forEach((asset) => {
        assetMap.set(asset.spaceId, convertAssetSummaryResponseToCurrentUniqueAsset(asset));
    });
    return assetMap;
};
export const convertAssetSurveyResponseToDynamicQuestionForm = (assetQuestions) => {
    if (!assetQuestions) {
        return [];
    }
    return assetQuestions.answers.map((a) => ({
        name: a.name,
        value: a.value,
    }));
};
