var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// db.js
import Dexie from 'dexie';
export class MySubClassedDexie extends Dexie {
    constructor() {
        super('pwa');
        // version number should be updated to next number, if changing anything with the store *******
        this.version(9).stores({
            // Primary key and indexed props
            requests: '++id, url, method, body',
            organization: 'id, name, smallLogo, smallLogoType, largeLogo, largeLogoType, sites, sitesAmount, facilitiesAmount',
            site: 'id, name, facilities',
            facility: 'id, name, squareFootage, note, plans, assets',
            plan: 'id, name, planFileName, planImage, planImageType, spaces',
            planImages: '++id, planName, planFileName, planImage, dateStr',
            space: 'id, spaceId, constrYear, spaceArea, type, definition, description, isAssessed, x, y, width, length, angle, opacity, light, sound, co2, temperature, humidity, roomFunction, occupied, numberOfOccupants, ieqDate, shape, survey, photos, assets, isHidden, note',
            spacePoints: 'id, x, y, pointIndex, fill',
            spaceSurvey: 'id, flooringDateOfInstall, flooringApproxYear, flooringName, flooring1Type, flooring1Condition, flooring2Type, flooring2Percentage, flooring2Condition, flooringNote, ceilingDateOfInstall, ceilingApproxYear, ceilingName, ceilingType, ceilingCondition, ceilingHeight, ceilingNote, windowsDateOfInstall, windowsApproxYear, windowsName, windowsQuantity, windowsType, windowsMaterial, windowsPane, windowsCondition, windowsLength, windowsHeight, windowsNote, caseworkDateOfInstall, caseworkApproxYear, caseworkName, caseworkSinkQuantity, caseworkType, caseworkCondition, caseworkNote, interiorDoorsDateOfInstall, interiorDoorsApproxYear, interiorDoorsName, interiorDoorsQuantity, interiorDoors1Type, interiorDoors1HandleType, interiorDoors1Condition, interiorDoors2Type, interiorDoors2HandleType, interiorDoors2Condition, interiorDoorsNote, exteriorDoorsDateOfInstall, exteriorDoorsApproxYear, exteriorDoorsName, exteriorDoorsQuantity, exteriorDoors1Type, exteriorDoors1HandleType, exteriorDoors1Condition,exteriorDoors2Type, exteriorDoors2HandleType, exteriorDoors2Condition, exteriorDoorsNote, lightingDateOfInstall, lightingApproxYear, lightingType, lightingCondition, lightingFixtureSize, lightingControl, lightingOccupancySensor, lightingDaylightingControl, lightingNote, photos, pin, isHidden',
            asset: 'id, assetType, name, approxYear, modelNum, serialNum, assetCondition, questions, pin, photos, isHidden, note, facilityId',
            assetPin: 'id, x, y, pointIndex, fill',
            question: 'id, survey, surveyName, entityType, question, inputType, booleanAnsw, dateAnsw, numberAnsw, stringAnsw, strArrAnsw',
            photo: 'id, name, url, favorite, comments',
            photoComment: 'content, createDate, firstName, lastName',
            user: 'id, email, firstName, lastName, organizationRoles, facilityRoles, systemRole, apps',
        });
        this.requests = this.table('requests');
        this.organization = this.table('organization');
        this.site = this.table('site');
        this.facility = this.table('facility');
        this.plan = this.table('plan');
        this.planImages = this.table('planImages');
        this.space = this.table('space');
        this.spacePoints = this.table('spacePoints');
        this.spaceSurvey = this.table('spaceSurvey');
        this.asset = this.table('asset');
        this.assetPin = this.table('assetPin');
        this.question = this.table('question');
        this.photo = this.table('photo');
        this.photoComment = this.table('photoComment');
    }
    clearAllTablesInCache() {
        return __awaiter(this, void 0, void 0, function* () {
            const recordsInTable = yield db.site.count();
            if (recordsInTable > 0) {
                db.tables.forEach((table) => {
                    table.clear();
                });
            }
        });
    }
}
export const db = new MySubClassedDexie();
