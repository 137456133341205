import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@chakra-ui/react';
import { FacilityMapTypes } from '../../../objects/FCAHelperInterfaces';
export const MeasureButton = ({ isInterior, measure, isSelected, setSelectedMeasure, }) => {
    const buttonName = measure === FacilityMapTypes.CO2 ? 'CO\u2082 Levels' : measure;
    return (_jsx(Button, Object.assign({ width: '115px', flexShrink: 0, outline: isSelected ? '3px solid lightblue' : '', bg: isSelected
            ? 'white'
            : isInterior
                ? 'rgba(246, 134, 42, 0.75)'
                : 'rgba(0, 59, 93, 0.75)', color: isSelected ? '' : 'white', onClick: () => {
            setSelectedMeasure(measure);
        }, _hover: {
            bg: isInterior
                ? 'rgba(246, 134, 42, 0.5)'
                : 'rgba(0, 59, 93, 0.5)',
        } }, { children: buttonName })));
};
