import { extendTheme } from '@chakra-ui/react';
import { appColors } from './constants';
export const variants = {
    regSizeH: 'regular-size',
    detailsSidebar: 'details-sidebar',
    blueBtn: 'blue-btn',
    blueSemiBText: 'blue-semi-bold-txt',
    // TODO: while working to get FCA to use design system themes this is duplicate from ds
    checkboxSelect: 'select-checkbox',
    detailsInputText: 'details-input-text',
    formLabel: 'form-label-bold',
    selectOptions: 'select-options',
    transparentBtn: 'transparent-btn',
    transparentBlueBtn: 'trans-blue-btn',
    transparentRedBtn: 'trans-red-btn',
    h2: 'h2',
    galleryHeader: 'gallery-header',
    transparentTab: 'transparent-tab',
    whiteBtn: 'white-btn',
    error: 'error',
    sidebarBtn: 'sidebar-btn',
    selectRounded: 'select-rounded',
};
export const slgqColors = {
    primBlue: 'slgq.primBlue',
    primLightGrey: 'slqg.primLightGrey',
    primOrange: 'slgq.primOrange',
    primWhite: 'slgq.primWhite',
    secGrey: 'slgq.secGrey',
    secLightGrey: 'slgq.secLightGrey',
    secDarkGrey: 'slgq.secDarkGray',
    secOrange: 'slgq.secOrange',
};
// TODO: while we still rely on fca theme we need to start pulling in ds theming to switch over
//       providing dsTheme first allows fca theme to override any design system theming
const theme = extendTheme({
    colors: {
        orange: {
            orange: appColors.SEC_ORANGE,
        },
        primBlue: {
            '600': '#0e527e',
            '500': '#003B5D',
        },
        slgq: {
            primBlue: '#003B5D',
            primLightGrey: appColors.PRIM_LIGHT_GREY,
            primOrange: '#FBDABD',
            primWhite: '#FAF9F7',
            secGrey: '#2E3532',
            secDarkGray: '#818584',
            secLightGrey: '#F2F3F4',
            secOrange: '#F6862A',
            tertBlue: '#6C899E',
            yellow: '#FEDB5E',
        },
        misc: {
            sidebarWhite: '#FAF9F7',
            bgGrey: '#DADCDE',
            sectionGrey: '#E6E7E9',
        },
    },
    fonts: {
        nav: 'montserrat',
        heading: 'montserrat',
        body: 'montserrat',
    },
    styles: {
        global: {
            h2: {
                color: slgqColors.primBlue,
            },
        },
    },
    layerStyles: {},
    components: {
        Switch: {
            baseStyle: {
                track: {
                    _checked: {
                        bg: '#003B5D',
                    },
                },
            },
            variants: {
                'orange-switch': {
                    track: {
                        _checked: {
                            bg: slgqColors.secOrange,
                        },
                    },
                },
            },
        },
        Button: {
            variants: {
                'blue-btn': {
                    bg: slgqColors.primBlue,
                    color: 'white',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    height: '30px',
                },
                'transparent-btn': {
                    bg: 'transparent',
                    boxSizing: 'border-box',
                    p: '5px',
                    fontSize: '14px',
                    height: '30px',
                },
                'trans-blue-btn': {
                    bg: 'transparent',
                    color: slgqColors.primBlue,
                    borderWidth: '1px',
                    borderColor: slgqColors.primBlue,
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    h: '30px',
                },
                'trans-red-btn': {
                    bg: 'transparent',
                    color: appColors.DELETE_ERROR,
                    borderWidth: '1px',
                    borderColor: appColors.DELETE_ERROR,
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    h: '30px',
                },
                'white-btn': {
                    bg: slgqColors.primWhite,
                    borderRadius: '60px',
                    border: '1px',
                    borderColor: slgqColors.primBlue,
                    color: slgqColors.primBlue,
                    boxSizing: 'border-box',
                    fontWeight: 700,
                    fontSize: '14px',
                    height: '30px',
                    w: '45%',
                },
                'sidebar-btn': {
                    background: '#EDF2F7',
                    dropShadow: 'rgba(0, 0, 0, 0.25)',
                    borderShadow: '50px 0px 0px 50px',
                    opacity: 0.8,
                    borderRadius: '50px 0px 0px 50px',
                    p: 0,
                    m: 0,
                },
            },
        },
        Checkbox: {
            baseStyle: {
                size: 'md',
                verticalAlign: 'center',
            },
            defaultProps: {
                color: slgqColors.secOrange,
                colorScheme: '#FFFFFF',
            },
            // TODO: while working to get FCA to use design system themes this is duplicate from ds
            variants: {
                'select-checkbox': {
                    icon: {
                        color: slgqColors.secOrange,
                    },
                    control: {
                        backgroundColor: slgqColors.primWhite,
                        borderColor: slgqColors.primBlue,
                        _disabled: {
                            backgroundColor: slgqColors.primLightGrey,
                            borderColor: slgqColors.secDarkGrey,
                        },
                    },
                },
            },
        },
        FormLabel: {
            baseStyle: {
                color: slgqColors.primBlue,
                boxSizing: 'border-box',
                fontWeight: 600,
                fontSize: 14,
            },
        },
        Heading: {
            variants: {
                'regular-size': {
                    fontSize: 16,
                },
                h2: {
                    fontWeight: 400,
                    fontSize: 26,
                },
                'details-sidebar': {
                    fontSize: '14px',
                    py: '10px',
                    textAlign: 'center',
                    lineHeight: '20px',
                    fontWeight: 400,
                },
                'gallery-header': {
                    fontWeight: 400,
                    fontSize: 30,
                    color: 'black',
                    mt: '40px',
                    mb: '20px',
                },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    _placeholder: {
                        opacity: 1,
                        color: slgqColors.primLightGrey,
                        wordBreak: 'break-word',
                        fontSize: '14px',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    },
                    fontFamily: 'montserrat',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15) inset',
                    boxSizing: 'border-box',
                    px: '18px',
                    h: '36px',
                },
            },
            variants: {
                'round-input': {
                    field: {
                        fontFamily: 'mulish',
                        borderRadius: '50px',
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                        h: '40px',
                        boxSizing: 'border-box',
                        px: '18px',
                    },
                },
                backgroundFix: {
                    field: {
                        bg: '#FFFFFF',
                    },
                },
            },
            defaultProps: {
                size: null,
                variant: 'backgroundFix',
            },
        },
        NumberInput: {
            baseStyle: {
                field: {
                    _placeholder: {
                        opacity: 1,
                        color: slgqColors.primLightGrey,
                        wordBreak: 'break-word',
                        fontSize: '14px',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    },
                    fontFamily: 'montserrat',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15) inset',
                    boxSizing: 'border-box',
                    px: '18px',
                    h: '36px',
                },
            },
            variants: {
                'round-input': {
                    field: {
                        fontFamily: 'mulish',
                        borderRadius: '50px',
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                        h: '40px',
                        boxSizing: 'border-box',
                        px: '18px',
                    },
                },
                backgroundFix: {
                    field: {
                        bg: '#FFFFFF',
                    },
                },
            },
            defaultProps: {
                size: null,
                variant: 'backgroundFix',
            },
        },
        Radio: {
            baseStyle: {
                _label: {
                    // color: '#FFA500',
                    fontSize: 14,
                },
            },
        },
        Select: {
            baseStyle: {
                field: {
                    _placeholder: {
                        opacity: 1,
                        color: slgqColors.primLightGrey,
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    },
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    textAlign: 'left',
                },
                backgroundFix: {
                    field: {
                        bg: '#FFFFFF',
                    },
                },
            },
            variants: {
                'select-rounded': {
                    field: {
                        borderRadius: '50px',
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                        h: '40px',
                        boxSizing: 'border-box',
                        px: '18px',
                        _disabled: {
                            color: slgqColors.secDarkGrey,
                            bg: '#F2F3F4',
                            border: '1px solid #D6D7DB',
                            boxShadow: 'unset',
                        },
                    },
                },
            },
            defaultProps: {
                variant: 'backgroundFix',
            },
        },
        Tab: {
            variants: {
                'transparent-tab': {
                    _focus: { boxShadow: 'none' },
                },
            },
        },
        Text: {
            baseStyle: {
                color: slgqColors.secGrey,
                fontFamily: 'montserrat',
            },
            variants: {
                'blue-semi-bold-text': {
                    color: slgqColors.primBlue,
                    boxSizing: 'border-box',
                    fontWeight: 600,
                    fontSize: '14px',
                },
                'details-input-text': {
                    color: slgqColors.secGrey,
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: '14px',
                    textAlign: 'left',
                    pl: '18px',
                },
                error: {
                    color: 'red.600',
                    pt: 1,
                    pl: 1,
                    fontSize: 'sm',
                },
            },
        },
        Textarea: {
            baseStyle: {
                field: {
                    _placeholder: {
                        opacity: 1,
                        color: slgqColors.primLightGrey,
                        wordBreak: 'break-word',
                        fontSize: '14px',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        pl: '18px',
                    },
                    fontFamily: 'montserrat',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15) inset',
                    boxSizing: 'border-box',
                    px: '18px',
                    h: '36px',
                },
            },
            variants: {
                backgroundFix: {
                    field: {
                        bg: '#FFFFFF',
                    },
                },
            },
            defaultProps: {
                size: null,
                variant: 'backgroundFix',
            },
        },
    },
});
export default theme;
