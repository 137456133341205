import { AssetClassType } from '@/objects/FCAInterfaces';
import { faker } from '@faker-js/faker';
export class PlatformMapBuilder {
    constructor(mapOverrides) {
        this.build = () => {
            const assetBuilder = new PlatformMapAssetBuilder({});
            return Object.assign({ spaceId: faker.number.int({ min: 1, max: 10000 }), spaceName: faker.lorem.slug(2), constructionYear: faker.number.int({ min: 1980, max: 2024 }), spaceFunction: faker.helpers.arrayElement([
                    'Restroom',
                    'Gym',
                    'Storage',
                ]), isAssessed: faker.datatype.boolean(), qualityMetrics: {
                    cO2MetricHistory: [],
                    lightMetricHistory: [],
                    occupancyMetricHistory: [],
                    soundMetricHistory: [],
                    temperatureMetricHistory: [],
                    humidityMetricHistory: [],
                }, assets: [
                    ...new Array(faker.number.int({ min: 1, max: 10 })).keys(),
                ].map((_) => assetBuilder.build()), servedBy: [], points: [
                    {
                        xPosition: faker.number.int({ min: 1, max: 700 }),
                        yPosition: faker.number.int({ min: 1, max: 700 }),
                        sequence: 0,
                    },
                    {
                        xPosition: faker.number.int({ min: 1, max: 700 }),
                        yPosition: faker.number.int({ min: 1, max: 700 }),
                        sequence: 1,
                    },
                    {
                        xPosition: faker.number.int({ min: 1, max: 700 }),
                        yPosition: faker.number.int({ min: 1, max: 700 }),
                        sequence: 2,
                    },
                    {
                        xPosition: faker.number.int({ min: 1, max: 700 }),
                        yPosition: faker.number.int({ min: 1, max: 700 }),
                        sequence: 3,
                    },
                ] }, this.mapOverrides);
        };
        this.mapOverrides = mapOverrides;
    }
}
export class PlatformMapAssetBuilder {
    constructor(assetOverrides) {
        this.build = () => {
            return Object.assign({ assetId: faker.number.int({ min: 100, max: 5000 }), assetName: faker.lorem.slug(2), assetClass: faker.lorem.slug(1), assetClassType: faker.helpers.arrayElement([
                    AssetClassType.PINNED,
                    AssetClassType.STANDARD,
                    AssetClassType.FACILITY,
                ]), assetType: faker.lorem.slug(1), isFacilityAsset: false, conditions: [
                    {
                        condition: 2,
                        ageAdjustedCondition: faker.number.int({ min: 1, max: 5 }),
                        assessedOn: faker.date.recent().toISOString(),
                        auditorFirstName: faker.person.firstName(),
                        auditorLastName: faker.person.lastName(),
                        auditedOn: faker.date.recent().toISOString(),
                    },
                ] }, this.assetOverrides);
        };
        this.assetOverrides = assetOverrides;
    }
}
