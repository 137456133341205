var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { addComment, addTagToPhoto, deletePhoto, deletePhotoTag, getPhoto, getPhotosByOrg, updatePhoto, } from '@/services/api/photoAPI';
import { usePhotoServiceHook } from '@/store/photoServiceHook';
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getTagsForOrg } from '../../services/api/tagApi';
import { PhotoContext } from './PhotoContext';
export function PhotoProvider({ children, orgIdentity, facilityIdentity, defaultFilters, }) {
    const [currentPhotos, setCurrentPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(undefined);
    const [currentFilters, setCurrentFilters] = useState();
    const [selectedFilters, setSelectedFilters] = useState();
    const [orgTags, setOrgTags] = useState([]);
    // TODO pass in on photo provider instantiation so we don't rely on redux
    const toast = useToast();
    useEffect(() => {
        if (orgIdentity) {
            getTagsForOrg(orgIdentity).then((orgTags) => setOrgTags(orgTags));
        }
        setCurrentFilters({});
        setSelectedFilters({});
    }, [orgIdentity]);
    useEffect(() => {
        getPhotos();
    }, [currentFilters, defaultFilters, orgIdentity]);
    const { photoFileActions } = usePhotoServiceHook();
    const addFilterToApply = (filters) => {
        setSelectedFilters(Object.assign(Object.assign({}, selectedFilters), filters));
    };
    const updateFavorite = (photo) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (navigator.onLine) {
            yield updatePhoto((_a = photo.id) !== null && _a !== void 0 ? _a : '', {
                favorite: !photo.favorite,
            });
            getPhotos(currentFilters);
        }
        else {
            toast({
                title: 'Check Internet',
                description: 'Favoriting photos not available while offline.',
                status: 'error',
                isClosable: true,
            });
        }
    });
    const getPhotos = (filters) => __awaiter(this, void 0, void 0, function* () {
        if (!orgIdentity) {
            console.error('Error occured, no orgIdentity provided');
            return [];
        }
        try {
            const resultingFilters = Object.assign(Object.assign(Object.assign({}, defaultFilters), currentFilters), filters);
            const resPhotos = yield getPhotosByOrg(orgIdentity, resultingFilters);
            setCurrentPhotos(resPhotos);
            return resPhotos;
        }
        catch (err) {
            console.error(`Error occured fetching photos, ${err}`);
            return [];
        }
    });
    const removeSinglePhoto = () => __awaiter(this, void 0, void 0, function* () {
        setSelectedPhoto(undefined);
        yield getPhotos();
        return;
    });
    const getSinglePhoto = (photoid) => __awaiter(this, void 0, void 0, function* () {
        try {
            const resPhoto = yield getPhoto(photoid);
            setSelectedPhoto(resPhoto);
            return;
        }
        catch (err) {
            console.error(`Error occured fetching photos, ${err}`);
            return;
        }
    });
    const addCommentToPhoto = (comment) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (selectedPhoto) {
                yield addComment(selectedPhoto.id, comment);
                yield getSinglePhoto(selectedPhoto.id);
                return;
            }
            else {
                throw new Error('no photo selected');
            }
        }
        catch (e) {
            throw new Error('error setting tag');
        }
    });
    const addTag = (tagIdentity) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (selectedPhoto) {
                yield addTagToPhoto(selectedPhoto.photoIdentity, tagIdentity);
                yield getSinglePhoto(selectedPhoto.id);
                return;
            }
            else {
                throw new Error('no photo selected');
            }
        }
        catch (e) {
            throw new Error('error setting tag');
        }
    });
    const removeTag = (tagIdentity) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (selectedPhoto) {
                yield deletePhotoTag(selectedPhoto === null || selectedPhoto === void 0 ? void 0 : selectedPhoto.photoIdentity, tagIdentity);
                yield getSinglePhoto(selectedPhoto.id);
                return;
            }
            else {
                throw new Error('no photo selected');
            }
        }
        catch (e) {
            throw new Error('error removing tag');
        }
    });
    const deleteSelectedPhoto = (id) => __awaiter(this, void 0, void 0, function* () {
        yield deletePhoto(id);
        yield removeSinglePhoto();
    });
    const submitSpacePhoto = (spaceIdentity, { fileList, name, selectedTags, selectedDate }) => __awaiter(this, void 0, void 0, function* () {
        if (facilityIdentity) {
            yield photoFileActions({
                organizationIdentity: orgIdentity,
                facilityIdentity: facilityIdentity,
                spaceIdentity: spaceIdentity,
            }, {
                fileList,
                name,
                selectedTags,
                selectedDate,
            });
            yield getPhotos();
        }
        else {
            toast({
                title: 'Can not upload photo to space. Missing facility information.',
                isClosable: true,
            });
        }
    });
    const submitAssetPhoto = (spaceIdentity, assetIdentity, { fileList, name, selectedTags, selectedDate }) => __awaiter(this, void 0, void 0, function* () {
        if (facilityIdentity) {
            yield photoFileActions({
                organizationIdentity: orgIdentity,
                facilityIdentity: facilityIdentity,
                spaceIdentity: spaceIdentity,
                assetIdentity: assetIdentity,
            }, {
                fileList,
                name,
                selectedTags,
                selectedDate,
            });
            yield getPhotos();
        }
        else {
            toast({
                title: 'Can not upload photo to asset. Missing facility information.',
                isClosable: true,
            });
        }
    });
    return (_jsx(PhotoContext.Provider, Object.assign({ value: {
            currentPhotos,
            currentFilters,
            setCurrentFilters,
            selectedPhoto,
            getSinglePhoto,
            removeSinglePhoto,
            updateFavorite,
            selectedFilters,
            setSelectedFilters,
            addFilterToApply,
            addCommentToPhoto,
            addTag,
            removeTag,
            orgTags,
            submitAssetPhoto,
            submitSpacePhoto,
            deleteSelectedPhoto,
            getPhotos,
        } }, { children: children })));
}
