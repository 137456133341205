var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useAssessmentTemplateApi } from '../../services/useAssessmentTemplateApi';
import { TemplateContext } from './TemplateContext';
export function TemplateProvider({ children, spaceCategory, organizationId, isStandard, }) {
    const [spaceTemplate, setSpaceTemplate] = useState([]);
    const [assetTemplates, setAssetTemplates] = useState([]);
    const [assetTemplatesLoading, setAssetTemplatesLoading] = useState(true);
    const { loadSpaceTemplateHandler, loadAssetTemplateHandler } = useAssessmentTemplateApi();
    useEffect(() => {
        if (spaceCategory) {
            getSpaceTemplate(spaceCategory, organizationId);
            fetchAssetTemplates(spaceCategory, isStandard);
        }
        else {
            setSpaceTemplate([]);
            setAssetTemplates([]);
        }
    }, [spaceCategory, isStandard, organizationId]);
    const getAssetTemplateByClass = (assetClass) => {
        if (!assetClass) {
            return undefined;
        }
        return assetTemplates === null || assetTemplates === void 0 ? void 0 : assetTemplates.find((singleTemplate) => singleTemplate.assetClass === assetClass);
    };
    const getAssetTemplateVersionByClass = (assetClass) => {
        const classTemplate = assetTemplates === null || assetTemplates === void 0 ? void 0 : assetTemplates.find((singleTemplate) => singleTemplate.assetClass === assetClass);
        if (!classTemplate) {
            return 1;
        }
        else {
            return classTemplate.version;
        }
    };
    const getRoomFunctionsBySpaceType = (spaceType) => {
        const template = spaceTemplate.find((template) => template.spaceType === spaceType);
        return (template === null || template === void 0 ? void 0 : template.functions) || [];
    };
    const getSpaceTemplate = (spaceCategory, orgId) => __awaiter(this, void 0, void 0, function* () {
        loadSpaceTemplateHandler
            .fetch(spaceCategory, orgId)
            .then((returnObject) => {
            if (returnObject) {
                setSpaceTemplate(returnObject);
            }
        });
    });
    // only update on change in definition on add space
    // only need updated space template for space type and room functions
    const updateSpaceDefinition = (definition) => {
        getSpaceTemplate(definition, organizationId);
    };
    const fetchAssetTemplates = (spaceCategory, isStandard) => __awaiter(this, void 0, void 0, function* () {
        if (!assetTemplatesLoading) {
            setAssetTemplatesLoading(true);
        }
        // Note: right now we are using GROUND when saving a new space, after the PlatformAPI changes are made we should change it so we use GROUNDS instead of GROUND
        loadAssetTemplateHandler
            .fetch(spaceCategory, isStandard)
            .then((templates) => setAssetTemplates(templates || []))
            .finally(() => setAssetTemplatesLoading(false));
    });
    return (_jsx(TemplateContext.Provider, Object.assign({ value: {
            spaceTemplate,
            assetTemplates,
            assetTemplatesLoading,
            updateSpaceDefinition,
            getAssetTemplateByClass,
            getAssetTemplateVersionByClass,
            getRoomFunctionsBySpaceType,
        } }, { children: children })));
}
