var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Stack } from '@chakra-ui/react';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormSelector } from '../../../../../../components/forms/FormSelector';
import { getListOfUniqueValues, resetFilterMoveForm, } from '../../../../../../config/FCAHelperFunctions';
import { useGalleryContext } from '../../../../../../contexts/Photo/GalleryContext';
import { usePhotoContext } from '../../../../../../contexts/Photo/PhotoContext';
import { conditions } from '../../utility/ConditionArray';
import { FilterSection } from './FilterSection';
export const FilterPhotosModal = ({ isOpen, close, showClassification, }) => {
    var _a, _b, _c, _d;
    const { selectedFilters, currentFilters, setSelectedFilters, setCurrentFilters, } = usePhotoContext();
    const { facilityDropdown, spaceYearDropdown, spaceDropdown, assetCategoryDropdown, assetTypeDropdown, assetYearDropdown, assetDropdown, initializeDropdowns, onFacilityDropdownSelection, onSpaceYearDropdownSelection, onSpaceDropdownSelection, onAssetCategoryDropdownSelection, onAssetTypeDropdownSelection, onAssetYearDropdownSelection, } = useGalleryContext();
    const { control, getValues, watch, resetField, setValue } = useForm();
    useEffect(() => {
        if (isOpen) {
            // TODO: see if we can set values on initialization of form
            setValue('Facility', currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.facilityIdentities);
            setValue('Space Construction Year', currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.spaceYears);
            setValue('Space', currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.spaceIdentities);
            setValue('Category', currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.assetCategories);
            setValue('Asset Type', currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.assetTypes);
            setValue('Install Year', currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.assetYears);
            setValue('Asset', currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.assetIdentities);
            setValue('Condition', currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.conditions);
            initializeDropdowns(getValues());
            setSelectedFilters(Object.assign({}, currentFilters));
        }
    }, [isOpen]);
    const createPhotoFilterObject = (classificationOptions, photoFilter) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return Object.assign(Object.assign({}, photoFilter), { facilityIdentities: ((_a = classificationOptions.Facility) === null || _a === void 0 ? void 0 : _a.toString()) !== 'none'
                ? classificationOptions.Facility
                : undefined, spaceYears: ((_b = classificationOptions['Space Construction Year']) === null || _b === void 0 ? void 0 : _b.toString()) !==
                'none'
                ? classificationOptions['Space Construction Year']
                : undefined, spaceIdentities: ((_c = classificationOptions.Space) === null || _c === void 0 ? void 0 : _c.toString()) !== 'none'
                ? classificationOptions.Space
                : undefined, assetCategories: ((_d = classificationOptions['Category']) === null || _d === void 0 ? void 0 : _d.toString()) !== 'none'
                ? classificationOptions['Category']
                : undefined, assetTypes: ((_e = classificationOptions['Asset Type']) === null || _e === void 0 ? void 0 : _e.toString()) !== 'none'
                ? classificationOptions['Asset Type']
                : undefined, assetYears: ((_f = classificationOptions['Install Year']) === null || _f === void 0 ? void 0 : _f.toString()) !== 'none'
                ? classificationOptions['Install Year']
                : undefined, assetIdentities: ((_g = classificationOptions.Asset) === null || _g === void 0 ? void 0 : _g.toString()) !== 'none'
                ? classificationOptions.Asset
                : undefined, conditions: ((_h = classificationOptions['Condition']) === null || _h === void 0 ? void 0 : _h.toString()) !== 'none'
                ? classificationOptions['Condition']
                : undefined });
    };
    const applyFilters = () => __awaiter(void 0, void 0, void 0, function* () {
        const photoClassificationOptions = getValues();
        // Do not want to apply classifications, if they are not present, because they will override default filters
        const photoFilter = showClassification
            ? createPhotoFilterObject(photoClassificationOptions, selectedFilters !== null && selectedFilters !== void 0 ? selectedFilters : {})
            : selectedFilters !== null && selectedFilters !== void 0 ? selectedFilters : {};
        setCurrentFilters(photoFilter);
    });
    const onClose = () => {
        setSelectedFilters({});
        resetField('Facility', {
            defaultValue: (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.facilityIdentities) || [],
        });
        resetField('Space Construction Year', {
            defaultValue: (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.spaceYears) || [],
        });
        resetField('Space', {
            defaultValue: (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.spaceIdentities) || [],
        });
        resetField('Category', {
            defaultValue: (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.assetCategories) || [],
        });
        resetField('Asset Type', {
            defaultValue: (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.assetTypes) || [],
        });
        resetField('Install Year', {
            defaultValue: (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.assetYears) || [],
        });
        resetField('Asset', {
            defaultValue: (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.assetIdentities) || [],
        });
        resetField('Condition', {
            defaultValue: (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.conditions) || [],
        });
        close();
    };
    const clearAllFilters = () => __awaiter(void 0, void 0, void 0, function* () {
        // todo: have cancel clear all filters
        onClose();
    });
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            switch (name) {
                case 'Facility':
                    {
                        const optionsByFacility = onFacilityDropdownSelection(value);
                        resetFilterMoveForm('Facility', resetField, value, {
                            'Space Construction Year': optionsByFacility['Space Construction Year'],
                            Space: optionsByFacility.Space,
                            Category: optionsByFacility.Category,
                            'Asset Type': optionsByFacility['Asset Type'],
                            'Install Year': optionsByFacility['Install Year'],
                            Asset: optionsByFacility.Asset,
                        });
                        const values = getValues();
                        onFacilityDropdownSelection(values);
                    }
                    break;
                case 'Space Construction Year':
                    {
                        const optionsBySpaceYear = onSpaceYearDropdownSelection(spaceYearDropdown, value);
                        if (value['Space Construction Year']) {
                            resetFilterMoveForm('Space Construction Year', resetField, value, {
                                Space: optionsBySpaceYear.Space,
                                Category: optionsBySpaceYear.Category,
                                'Asset Type': optionsBySpaceYear['Asset Type'],
                                'Install Year': optionsBySpaceYear['Install Year'],
                                Asset: optionsBySpaceYear.Asset,
                            });
                        }
                        const values = getValues();
                        onSpaceYearDropdownSelection(spaceYearDropdown, values);
                    }
                    break;
                case 'Space':
                    {
                        const optionsBySpace = onSpaceDropdownSelection(spaceDropdown, value);
                        if (value.Space) {
                            resetFilterMoveForm('Space', resetField, value, {
                                Category: optionsBySpace.Category,
                                'Asset Type': optionsBySpace['Asset Type'],
                                'Install Year': optionsBySpace['Install Year'],
                                Asset: optionsBySpace.Asset,
                            });
                        }
                        const values = getValues();
                        onSpaceDropdownSelection(spaceDropdown, values);
                    }
                    break;
                case 'Category':
                    {
                        const optionsByCategory = onAssetCategoryDropdownSelection(assetCategoryDropdown, value);
                        if (value.Category) {
                            resetFilterMoveForm('Category', resetField, value, {
                                'Asset Type': optionsByCategory['Asset Type'],
                                'Install Year': optionsByCategory['Install Year'],
                                Asset: optionsByCategory.Asset,
                            });
                        }
                        const values = getValues();
                        onAssetCategoryDropdownSelection(assetCategoryDropdown, values);
                    }
                    break;
                case 'Asset Type':
                    {
                        const optionsByAssetType = onAssetTypeDropdownSelection(assetTypeDropdown, value);
                        if (value['Asset Type']) {
                            resetFilterMoveForm('Asset Type', resetField, value, {
                                'Install Year': optionsByAssetType['Install Year'],
                                Asset: optionsByAssetType.Asset,
                            });
                        }
                        const values = getValues();
                        onAssetTypeDropdownSelection(assetTypeDropdown, values);
                    }
                    break;
                case 'Install Year':
                    {
                        const optionsByAssetYear = onAssetYearDropdownSelection(assetYearDropdown, value);
                        if (value['Install Year']) {
                            resetFilterMoveForm('Install Year', resetField, value, {
                                Asset: optionsByAssetYear.Asset,
                            });
                        }
                        const values = getValues();
                        onAssetYearDropdownSelection(assetYearDropdown, values);
                    }
                    break;
            }
        });
        return () => subscription.unsubscribe();
    });
    return (_jsx(Form.Modal, Object.assign({ disclosure: {
            isOpen: isOpen,
            onClose: onClose,
            onOpen: () => { },
        }, labels: {
            title: 'Filters',
            apply: 'Apply filters',
            cancel: 'Cancel',
        }, onClear: clearAllFilters, onApply: applyFilters }, { children: _jsx(Flex, Object.assign({ flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between', alignItems: showClassification ? 'stretch' : 'flex-start', gap: '40px' }, { children: _jsxs(Flex, { children: [showClassification && (_jsxs(Form.Column, { children: [_jsx(FormSelector, { isMulti: true, form: {
                                    name: 'Facility',
                                    control,
                                }, selectFrom: facilityDropdown !== null && facilityDropdown !== void 0 ? facilityDropdown : [] }), _jsx(FormSelector, { isMulti: true, form: {
                                    name: 'Space Construction Year',
                                    control,
                                }, selectFrom: (_a = getListOfUniqueValues(spaceYearDropdown, 'constrYear')) !== null && _a !== void 0 ? _a : [] }), _jsx(FormSelector, { isMulti: true, form: {
                                    name: 'Space',
                                    control,
                                }, selectFrom: spaceDropdown !== null && spaceDropdown !== void 0 ? spaceDropdown : [] }), _jsx(FormSelector, { isMulti: true, form: {
                                    name: 'Category',
                                    control,
                                }, selectFrom: (_b = getListOfUniqueValues(assetCategoryDropdown, 'category')) !== null && _b !== void 0 ? _b : [] }), _jsx(FormSelector, { isMulti: true, form: {
                                    name: 'Asset Type',
                                    control,
                                }, selectFrom: (_c = getListOfUniqueValues(assetTypeDropdown, 'assetType')) !== null && _c !== void 0 ? _c : [] }), _jsx(FormSelector, { isMulti: true, form: {
                                    name: 'Install Year',
                                    control,
                                }, selectFrom: (_d = getListOfUniqueValues(assetYearDropdown, 'installYear')) !== null && _d !== void 0 ? _d : [] }), _jsx(FormSelector, { isMulti: true, form: {
                                    name: 'Asset',
                                    control,
                                }, selectFrom: assetDropdown !== null && assetDropdown !== void 0 ? assetDropdown : [] }), _jsx(FormSelector, { isMulti: true, form: {
                                    name: 'Condition',
                                    control,
                                }, selectFrom: conditions !== null && conditions !== void 0 ? conditions : [] })] })), _jsx(Form.Column, { children: _jsx(Stack, { children: _jsxs(_Fragment, { children: [_jsx(FilterSection, { filterType: 'favorites' }), _jsx(FilterSection, { filterType: 'tags' })] }) }) })] }) })) })));
};
