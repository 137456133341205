import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { usePhotoContext } from '@/contexts/Photo/PhotoContext';
import { useEffect, useState } from 'react';
import { GalleryGridRow } from './GalleryGridRow';
export const GalleryGrid = ({ title }) => {
    const [sortedPhotos, setSortedPhotos] = useState();
    const { currentPhotos } = usePhotoContext();
    useEffect(() => {
        const favoritePhotos = currentPhotos.filter((photo) => photo.favorite);
        const nonFavoritePhotos = currentPhotos.filter((photo) => !photo.favorite);
        setSortedPhotos([...favoritePhotos, ...nonFavoritePhotos]);
    }, [currentPhotos]);
    return sortedPhotos ? (_jsx(GalleryGridRow, { title: title !== null && title !== void 0 ? title : 'Photos', photos: sortedPhotos })) : (_jsx(_Fragment, {}));
};
