var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { convertAssetSummaryResponseToCurrentUniqueAssetMap, convertToAssets, } from '@/conversions/AssetConversions';
import { AssetClassType, } from '@/objects/FCAInterfaces';
import { useToast } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { defaultOptions, usePlatformApiRequestHandler, } from '@frontend/domain/hooks/platformAPI/usePlatformApiRequestHandler';
import { useEffect, useState } from 'react';
import { CopyPasteContext } from './CopyPasteContext';
import { convertAssetDetailResponseToAssetCopySnapshot, convertCopiedAssetToAssetCopyRequest, convertCopiedSpaceToSpaceCopyRequest, convertSpaceDetailResponseToSpaceCopySnapshot, convertSpaceSummaryResponseToCurrentSpaces, } from './CopyPasteConversions';
import { convertToSpaceSummaryWithNonStandardAssets } from '@/conversions/SpaceConversions';
export function CopyPasteProvider({ children, isModalOpen, copyType, facilityIdentity, selectedSpaceIdentity, }) {
    const toast = useToast();
    const { platformApi } = useDependencies();
    const [facilitySpaces, setFacilitySpaces] = useState([]);
    const [selectedSpaceIdentities, setSelectedSpaceIdentities] = useState([]);
    const [copyDetails, setCopyDetails] = useState([]);
    const [currentSpaceCopy, setCurrentSpaceCopy] = useState();
    const loadFacilitySpaces = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the available spaces',
        } }));
    const loadSpace = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the space',
        } }));
    const loadAssetList = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the assets',
        } }));
    const pasteSpaceToSpaces = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when pasting the space information to the selected spaces',
        }, success: {
            label: 'Space information copied to spaces',
            enabled: true,
        } }));
    const pasteAssetToSpaces = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when pasting the asset to the selected spaces',
        }, success: {
            label: 'Asset copied to spaces',
            enabled: true,
        } }));
    const toggleSelectedSpace = (spaceIdentity) => {
        if (selectedSpaceIdentities.includes(spaceIdentity)) {
            const spacesToCopyTo = selectedSpaceIdentities.filter((identity) => identity !== spaceIdentity);
            setSelectedSpaceIdentities(spacesToCopyTo);
        }
        else {
            setSelectedSpaceIdentities([
                ...selectedSpaceIdentities,
                spaceIdentity,
            ]);
        }
    };
    const checkForExistingProperty = (propertyToCheck) => {
        return copyDetails.some((snap) => propertyToCheck.value === snap.value &&
            propertyToCheck.type === snap.type);
    };
    const togglePropertyDetail = (propertyObject) => {
        if (checkForExistingProperty(propertyObject)) {
            setCopyDetails(copyDetails.filter((snapshot) => snapshot.value !== propertyObject.value ||
                snapshot.type !== propertyObject.type));
        }
        else {
            setCopyDetails([...copyDetails, propertyObject]);
        }
    };
    const clearSelectedSpaces = () => {
        setSelectedSpaceIdentities([]);
    };
    const clearPropertiesToCopy = () => {
        setCopyDetails([]);
    };
    const fetchFacilitySpaces = () => __awaiter(this, void 0, void 0, function* () {
        yield loadFacilitySpaces
            .handleResponse(platformApi.space.getApiV1SpaceList(facilityIdentity), (responses) => responses.map((response) => convertToSpaceSummaryWithNonStandardAssets(response)))
            .then((spaces) => {
            var _a;
            const spaceCategory = (_a = (spaces !== null && spaces !== void 0 ? spaces : []).find((space) => space.identity === selectedSpaceIdentity)) === null || _a === void 0 ? void 0 : _a.category;
            if (spaces && spaceCategory) {
                const filteredSpaces = spaces.filter((space) => space.category === spaceCategory);
                setFacilitySpaces(filteredSpaces);
            }
        });
    });
    const fetchCopyInfo = (spaceIdentity, assetType) => __awaiter(this, void 0, void 0, function* () {
        const assets = yield fetchStandardAssets(spaceIdentity, assetType);
        if (assets) {
            fetchSpace(spaceIdentity, assets);
        }
    });
    const fetchSpace = (spaceIdentity, assets) => {
        loadSpace
            .handleResponse(platformApi.space.getApiV1SpaceLoad(undefined, spaceIdentity), (spaces) => convertSpaceDetailResponseToSpaceCopySnapshot(spaces, assets))
            .then((space) => {
            if (space) {
                setCurrentSpaceCopy(space);
            }
        });
    };
    const fetchStandardAssets = (spaceIdentity, assetType) => {
        return loadAssetList.handleResponse(platformApi.asset.postApiV1AssetLoad({
            spaceId: spaceIdentity,
            assetClassTypes: [assetType],
        }), (assets) => convertAssetDetailResponseToAssetCopySnapshot(convertToAssets(spaceIdentity, assets)));
    };
    const pasteAssetToSelectedSpaces = (assetIdentity) => __awaiter(this, void 0, void 0, function* () {
        return pasteAssetToSpaces
            .handleResponse(platformApi.asset.postApiV1AssetCopy(convertCopiedAssetToAssetCopyRequest(assetIdentity, selectedSpaceIdentities)), convertAssetSummaryResponseToCurrentUniqueAssetMap)
            .then((assets) => {
            if (assets) {
                return assets;
            }
        });
    });
    const pasteSpaceToSelectedSpaces = (spaceIdentity) => __awaiter(this, void 0, void 0, function* () {
        return pasteSpaceToSpaces
            .handleResponse(platformApi.space.postApiV1SpaceCopy(convertCopiedSpaceToSpaceCopyRequest(spaceIdentity, copyDetails, selectedSpaceIdentities)), convertSpaceSummaryResponseToCurrentSpaces)
            .then((spaces) => {
            if (spaces) {
                return spaces;
            }
        });
    });
    useEffect(() => {
        if (isModalOpen) {
            fetchFacilitySpaces();
            if (copyType === 'Space' && selectedSpaceIdentity) {
                fetchCopyInfo(selectedSpaceIdentity, AssetClassType.STANDARD);
            }
        }
    }, [isModalOpen]);
    return (_jsx(CopyPasteContext.Provider, Object.assign({ value: {
            facilitySpaces,
            toggleSelectedSpace,
            togglePropertyDetail,
            clearSelectedSpaces,
            clearPropertiesToCopy,
            currentSpaceCopy,
            selectedSpaceIdentities,
            copyDetails,
            pasteAssetToSelectedSpaces,
            pasteSpaceToSelectedSpaces,
        } }, { children: children })));
}
