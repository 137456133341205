var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { findSpaceName, toCsvString } from '../utils/spaceAssetsUtils';
export const downloadCsvFile = (type, facilityCsvData, assets) => __awaiter(void 0, void 0, void 0, function* () {
    let csv = '';
    const csvFileData = [];
    ///////// ASSETS /////////////
    if (type === 'assets') {
        csv =
            'Space,Asset Group,Asset Name,Asset Category,Asset Class,Asset Type,Approximate Year,Model Number,Serial Number,Asset Condition,Type Questions,Note\n';
        const getAssets = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (assets) {
                    assets.forEach((asset) => {
                        const spaceName = findSpaceName(asset, facilityCsvData);
                        csvFileData.push([
                            toCsvString(spaceName),
                            toCsvString(asset.assetGroup),
                            toCsvString(asset.name),
                            toCsvString(asset.assetCategory),
                            toCsvString(asset.assetClass),
                            toCsvString(asset.assetType),
                            asset.approxYear,
                            toCsvString(asset.modelNum),
                            toCsvString(asset.serialNum),
                            asset.assetCondition,
                            toCsvString(asset.questions),
                            toCsvString(asset.note),
                        ]);
                    });
                    return assets;
                }
            }
            catch (err) {
                console.log(err);
            }
        });
        yield getAssets();
    }
    /////////////// SPACES ///////////////////
    if (type === 'spaces') {
        csv =
            'Plan,Space Name,constrYear,Area,Type,Definition,Description,Is Assessed,Light,Sound,CO2,Temperature,Humidity,Room Function,Occupied,Number Of Occupants,IEQ Date,Note\n';
        (facilityCsvData === null || facilityCsvData === void 0 ? void 0 : facilityCsvData.plans) &&
            (facilityCsvData === null || facilityCsvData === void 0 ? void 0 : facilityCsvData.plans.forEach((plan) => {
                plan.spaces &&
                    plan.spaces.forEach((space) => {
                        csvFileData.push([
                            toCsvString(plan.name),
                            space.spaceId ? toCsvString(space.spaceId) : '',
                            space.constrYear,
                            space.spaceArea,
                            toCsvString(space.type),
                            toCsvString(space.definition),
                            space.description
                                ? toCsvString(space.description)
                                : '',
                            !!space.isAssessed,
                            space.light,
                            space.sound,
                            space.co2,
                            space.temperature,
                            space.humidity,
                            space.roomFunction
                                ? toCsvString(space.roomFunction)
                                : '',
                            !!space.occupied,
                            space.numberOfOccupants,
                            space.ieqDate,
                            space.note ? toCsvString(space.note) : '',
                        ]);
                    });
            }));
    }
    ///////// PLANS /////////////
    if (type === 'plans') {
        csv = 'Facility,Plan Name\n';
        (facilityCsvData === null || facilityCsvData === void 0 ? void 0 : facilityCsvData.plans) &&
            (facilityCsvData === null || facilityCsvData === void 0 ? void 0 : facilityCsvData.plans.forEach((plan) => {
                csvFileData.push([
                    (facilityCsvData === null || facilityCsvData === void 0 ? void 0 : facilityCsvData.name)
                        ? toCsvString(facilityCsvData.name)
                        : '',
                    plan.name ? toCsvString(plan.name) : '',
                ]);
            }));
    }
    // Merge the data with CSV
    csvFileData.forEach((row) => {
        csv += row.join(',');
        csv += '\n';
    });
    const hiddenElement = document.createElement('a');
    hiddenElement.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    hiddenElement.target = '_blank';
    const today = new Date().toLocaleDateString().slice(0, 10);
    //provide the name for the CSV file to be downloaded
    hiddenElement.download =
        type === 'plans'
            ? `${facilityCsvData === null || facilityCsvData === void 0 ? void 0 : facilityCsvData.name}_plans_${today}.csv`
            : type === 'spaces'
                ? `${facilityCsvData === null || facilityCsvData === void 0 ? void 0 : facilityCsvData.name}_spaces_${today}.csv`
                : `${facilityCsvData === null || facilityCsvData === void 0 ? void 0 : facilityCsvData.name}_assets_${today}.csv`;
    hiddenElement.click();
    return csv;
});
