import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { PageModes, } from '@/objects/FCAInterfaces';
import { HStack } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { DynamicInput } from '../../Inputs/DynamicInput';
export function AssetFormSubquestions({ path, assetQuestions, assetTemplate, control, isFirstAssetOfClass, }) {
    const { pageMode } = useFacilityContext();
    const addingOrUpdating = pageMode === PageModes.ADD_ASSET || pageMode === PageModes.EDIT
        ? true
        : false;
    return (_jsx(_Fragment, { children: assetQuestions &&
            assetTemplate &&
            assetQuestions.map((question, questionIndex) => {
                var _a;
                const foundQuestion = (_a = assetTemplate.survey) === null || _a === void 0 ? void 0 : _a.find((surveyQ) => surveyQ.name === question.name);
                if (isFirstAssetOfClass &&
                    assetTemplate.assetClass === 'Flooring' &&
                    (foundQuestion === null || foundQuestion === void 0 ? void 0 : foundQuestion.name) === 'AreaPercentage') {
                    return _jsx(_Fragment, {});
                }
                if (foundQuestion) {
                    return (_jsx(HStack, Object.assign({ justifyContent: 'space-between', w: '100%', "data-testid": `asset_form_subquestion_${foundQuestion.name}` }, { children: _jsx(Controller, { name: `${path}.${questionIndex}.value`, control: control, render: ({ field }) => {
                                return (_jsx(DynamicInput, Object.assign({}, field, { data: field.value, editMode: addingOrUpdating, inputType: foundQuestion.inputType, label: foundQuestion.label, choices: foundQuestion.choices, handleInputChange: field.onChange, required: foundQuestion.isRequired })));
                            } }) }), `asset_form_subquestion_${foundQuestion.name}`));
                }
                else {
                    return _jsx(_Fragment, {});
                }
            }) }));
}
