import { selectFeatureFlagCache } from './featureFlagSlice';
import { useAppSelector } from './hooks';
export var FeatureFlags;
(function (FeatureFlags) {
    FeatureFlags["FF_PIQ_ACCESS_CONTROL"] = "FF_PIQ_ACCESS_CONTROL";
    FeatureFlags["FF_ADD_PHOTO_TAG"] = "FF_ADD_PHOTO_TAG";
    FeatureFlags["FF_DOWNLOAD_PHOTOS"] = "FF_DOWNLOAD_PHOTOS";
    FeatureFlags["FF_MOVE_ONE_PHOTO"] = "FF_MOVE_ONE_PHOTO";
    FeatureFlags["FF_PHOTO_HOVER"] = "FF_PHOTO_HOVER";
    FeatureFlags["FF_REASSESS_MODAL"] = "FF_REASSESS_MODAL";
})(FeatureFlags || (FeatureFlags = {}));
export const useFeatureFlag = (flag) => {
    var _a;
    const featureFlagCache = useAppSelector(selectFeatureFlagCache);
    const foundFlag = featureFlagCache.find((ff) => ff.flag === flag);
    return (_a = foundFlag === null || foundFlag === void 0 ? void 0 : foundFlag.isEnabled) !== null && _a !== void 0 ? _a : false;
};
