import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import { usePhotoContext } from '../../../../../../contexts/Photo/PhotoContext';
export const FilterCard = ({ name, backgroundColor, filter, textColor, }) => {
    const { currentFilters, setCurrentFilters } = usePhotoContext();
    const removeFilter = () => {
        var _a, _b;
        if (currentFilters) {
            const updatedFilters = Object.assign({}, currentFilters);
            switch (filter.key) {
                case 'favorites':
                    updatedFilters[filter.key] = undefined;
                    break;
                case 'tags':
                    updatedFilters[filter.key] = (_a = currentFilters[filter.key]) === null || _a === void 0 ? void 0 : _a.filter((f) => {
                        return f !== filter.value;
                    });
                    break;
                default:
                    updatedFilters[filter.key] = (_b = currentFilters[filter.key]) === null || _b === void 0 ? void 0 : _b.filter((f) => {
                        return f && f.value !== filter.value;
                    });
            }
            setCurrentFilters(updatedFilters);
        }
    };
    return (_jsxs(Button, Object.assign({ size: 'sm', variant: 'outline', p: '0px 20px', onClick: () => removeFilter(), backgroundColor: backgroundColor, textColor: textColor ? textColor : '', borderRadius: '40px', _hover: { bg: '', textDecoration: 'underline' }, alignItems: 'center', m: '3px 10px 3px 0px' }, { children: [name, _jsx(Box, Object.assign({ ml: '8px' }, { children: _jsx(CloseIcon, { fontSize: '10px' }) }))] })));
};
