import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, List, Text } from '@chakra-ui/react';
import { BsStarFill } from 'react-icons/bs';
import { usePhotoContext } from '../../../../../../contexts/Photo/PhotoContext';
import { TagCard } from '../TagCard';
import { FilterCheckbox } from './FilterCheckbox';
export const FilterSection = ({ filterType, }) => {
    var _a;
    const { addFilterToApply, currentFilters, orgTags, selectedFilters } = usePhotoContext();
    const handleTagCheckboxChange = (value, tag) => {
        const selectedTags = (selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.tags) || [];
        if (value) {
            addFilterToApply({
                tags: [...selectedTags, tag.tagIdentity],
            });
        }
        else {
            const index = selectedTags.indexOf(tag.tagIdentity);
            selectedTags.splice(index, 1);
            addFilterToApply({
                tags: selectedTags,
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ fontSize: '14px' }, { children: filterType === 'favorites'
                    ? 'Other filters:'
                    : orgTags.length > 0
                        ? 'Tags:'
                        : '' })), _jsx(List, { children: filterType === 'favorites' ? (_jsx(FilterCheckbox, Object.assign({ handleChange: (value) => addFilterToApply({ favorites: value }), initialValue: (_a = currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.favorites) !== null && _a !== void 0 ? _a : false }, { children: _jsxs(Flex, { children: [_jsx(BsStarFill, { size: '16px', color: '#FEDB5E', style: {
                                    marginRight: '5px',
                                    alignSelf: 'center',
                                } }), "Favorites"] }) }))) : (_jsx(_Fragment, { children: orgTags &&
                        orgTags.map((tag) => {
                            var _a, _b;
                            return (_jsx(FilterCheckbox, Object.assign({ handleChange: (value) => handleTagCheckboxChange(value, tag), initialValue: (_b = (_a = currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.tags) === null || _a === void 0 ? void 0 : _a.includes(tag.tagIdentity)) !== null && _b !== void 0 ? _b : false }, { children: _jsx(TagCard, { name: tag.name, color: tag.color }) }), `orgTag: ${tag.tagIdentity}`));
                        }) })) })] }));
};
