import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, } from '@chakra-ui/react';
import { appColors } from '@frontend/design-system/theme/constants';
import React from 'react';
import { slgqColors } from '../../../../../../config/theme';
import { FacilityMapTypes } from '../../../../../../objects/FCAHelperInterfaces';
import { ConditionChart } from '../ConditionChart/ConditionChart';
import { MetricChart } from '../MetricChart/MetricChart';
import { ChartType } from '../TimeseriesButtons';
export const ChartModal = ({ graphConfig, isOpen, onClose, }) => {
    var _a;
    return (_jsxs(Modal, Object.assign({ size: '4xl', isOpen: isOpen, onClose: onClose, isCentered: true }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, Object.assign({ bg: slgqColors.primWhite }, { children: [(graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.type) === ChartType.single && (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { children: _jsx(Text, Object.assign({ mt: '1rem', color: '#2E3532', fontSize: '36px', fontWeight: '400' }, { children: graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.chartData.title })) }), _jsx(ModalCloseButton, {})] })), (graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.type) === ChartType.single && (_jsx(ConditionChart, { graphConfig: graphConfig })), (graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.type) === ChartType.multiple && (_jsxs(Tabs, Object.assign({ variant: 'unstyled', defaultIndex: (_a = graphConfig.chartData.data.datasets) === null || _a === void 0 ? void 0 : _a.findIndex((dataset) => dataset.type === graphConfig.chartData.title) }, { children: [_jsxs(TabList, { children: [graphConfig.chartData.data.datasets.map((dataset) => {
                                        return (_jsx(Tab, Object.assign({ _selected: { fontWeight: '700' }, fontSize: '20px', color: appColors.PRIM_BLUE }, { children: dataset.type ===
                                                FacilityMapTypes.CO2
                                                ? 'CO\u2082 (ppm)'
                                                : dataset.type ===
                                                    FacilityMapTypes.TEMPERATURE
                                                    ? `${FacilityMapTypes.TEMPERATURE} (F)`
                                                    : dataset.type })));
                                    }), _jsx(ModalCloseButton, {})] }), _jsx(TabIndicator, { mt: '-1.5px', height: '2px', bg: '#f58629', borderRadius: '1px' }), _jsx(TabPanels, { children: graphConfig.chartData.data.datasets.map((dataset) => {
                                    return (_jsx(TabPanel, Object.assign({ overflow: 'auto', maxHeight: '80vh' }, { children: _jsx(MetricChart, { graphConfig: graphConfig, type: dataset.type }) })));
                                }) })] })))] }))] })));
};
