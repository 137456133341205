export const appColors = {
    ASSESSED_GREEN: 'rgba(52, 168, 83, 0.25)',
    DELETE_ERROR: '#DA1100',
    KONVA_BLACK: '#000000',
    KONVA_BLUE: 'rgba(4, 119, 186, 0.25)',
    KONVA_ORANGE: 'rgba(246, 134, 42, 0.25)',
    KONVA_RED: '#F43131',
    PRIM_BLUE: '#003B5D',
    SEC_ORANGE: '#F6862A',
    TEXT_COLOR: '#2E3532',
    TEXT_SEC_DARK_GRAY: '#2E3532',
    PRIM_LIGHT_GREY: '#C0C2C1',
    SEC_LIGHT_GREY: '#F2F3F4',
};
export const appValues = {
    HEADER_HEIGHT_MAIN: 57,
    HEADER_HEIGHT: 100,
    FOOTER_HEIGHT: 45,
    SIDEBAR_WIDTH_FULL: 327,
    SIDEBAR_WIDTH_SMALL: 77,
    SIDEBAR_WIDTH_PLANS: 250,
    SIDEBAR_WIDTH_DETAILS: 288,
    TRANSITION_DURATION: '0.3s',
    SIDEBAR_ITEM_BUFFER_WIDTH: 25,
};
export const measureColors = {
    MEASURE_GREEN: '#16A34A',
    MEASURE_GRELLO: '#BDD51A',
    MEASURE_RED: '#DC2626',
    MEASURE_YELLOW: '#FACC15',
    MEASURE_BLUE: '#0000FF',
    MEASURE_ORANGE: '#FF8000',
    MEASURE_BLACK: '#2E3431',
};
export const tagColors = {
    LIGHT: {
        TAG_GREEN: '#92D050',
        TAG_LIGHT_GREEN: '#00B050',
        TAG_LIGHT_BLUE: '#00B0F0',
        TAG_ORANGE: '#FFC000',
        TAG_YELLOW: '#FFF000',
        TAG_LIGHT_RED: '#FF0000',
    },
    DARK: {
        TAG_RED: '#C00000',
        TAG_DARK_BLUE: '#0070C0',
        TAG_NAVI_BLUE: '#002060',
        TAG_PURPLE: '#7030A0',
    },
};
export const casework = {
    LAMINATED: {
        color: '#f9e1b9',
        type: 'Laminated',
    },
    METAL: {
        color: '#534C55',
        type: 'Metal',
    },
    WOOD: {
        color: '#b4724b',
        type: 'Wood',
    },
    OTHER: {
        color: '#FFFFFF',
        type: 'Other',
    },
    COMPOSITE: {
        color: '#959091',
        type: 'Composite',
    },
};
export const ceiling = {
    '2X2 TILES': {
        color: '#dddbdc',
        type: '2x2 Tiles',
    },
    '2X4 TILES': {
        color: '#40bf9a',
        type: '2x4 Tiles',
    },
    'EXPOSED ROOF DECK': {
        color: '#b4724b',
        type: 'Exposed Roof Deck',
    },
    'OPEN CEILING': {
        color: '#d82756',
        type: 'Open Ceiling',
    },
    'HARD LID': {
        color: '#f9e1b9',
        type: 'Hard Lid',
    },
    CONCRETE: {
        color: '#959091',
        type: 'Concrete',
    },
    'SPLINED 12" TILES': {
        color: '#a18db5',
        type: 'Splined 12" Tiles',
    },
    'ADHERED 12" TILES': {
        color: '#7b5f74',
        type: 'Adhered 12" Tiles',
    },
    WOOD: {
        color: '#b4724b',
        type: 'Wood',
    },
    ARCHITECTURAL: {
        color: '#60929F',
        type: 'Architectural',
    },
    OTHER: {
        color: '#3db2c2',
        type: 'Other',
    },
};
export const flooring = {
    'CARPET-ROLLED': {
        color: '#DE2155',
        type: 'Carpet-Rolled',
    },
    'CARPET-TILE': {
        color: '#21DEAA',
        type: 'Carpet-Tile',
    },
    'CERAMIC TILE': {
        color: '#8e7172',
        type: 'Ceramic Tile',
    },
    CONCRETE: {
        color: '#DAE2E1',
        type: 'Concrete',
    },
    EPOXY: {
        color: '#81675E',
        type: 'Epoxy',
    },
    LAMINATE: {
        color: '#60929F',
        type: 'Laminate',
    },
    LINOLEUM: {
        color: '#CEC6B6',
        type: 'Linoleum',
    },
    'LUXURY VINYL TILE': {
        color: '#6a7a9b',
        type: 'Luxury Vinyl Tile',
    },
    'OTHER HARD SURFACE': {
        color: '#B63F57',
        type: 'Other Hard Surface',
    },
    RUBBER: {
        color: '#071412',
        type: 'Rubber',
    },
    'SHEET VINYL': {
        color: '#41bea4',
        type: 'Sheet Vinyl',
    },
    TERRAZZO: {
        color: '#e8178c',
        type: 'Terrazzo',
    },
    'TERRAZZO TILE': {
        color: '#17E873',
        type: 'Terrazzo Tile',
    },
    'VINYL ASBESTOS TILE': {
        color: '#4b37c8',
        type: 'Vinyl Asbestos Tile',
    },
    'VINYL COMPOSITION TILE': {
        color: '#B4C837',
        type: 'Vinyl Composition Tile',
    },
    WOOD: {
        color: '#b4724b',
        type: 'Wood',
    },
    OTHER: {
        color: '#3db2c2',
        type: 'Other',
    },
};
export const supportedImageFormats = ['.jpg', '.jpeg', '.png', '.gif'];
export const supportedVideoFormats = ['.mov', '.mp4'];
export const allSupportedFormats = [
    ...supportedImageFormats,
    ...supportedVideoFormats,
];
