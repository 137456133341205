var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const baseUrl = '/facilities';
export default class FacilityService {
    constructor(api) {
        this.api = api;
    }
    getFacility(fidentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`${baseUrl}/${fidentity}`);
        });
    }
    getFacilitiesSpacesAssetsByOrg(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api
                .get(`${baseUrl}/organization/${orgIdentity}`)
                .then((facilities) => facilities.map((facility) => this.getFacilityGallerySnapshot(facility)));
        });
    }
    getPlans(fidentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`${baseUrl}/${fidentity}/plans`);
        });
    }
    createFacility(facility) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.post(`${baseUrl}`, facility);
        });
    }
    updateFacility(id, facility) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${id}`, facility);
        });
    }
    addNote(fid, note) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${fid}/note`, note);
        });
    }
    createPlan(fidentity, plan) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.put(`${baseUrl}/${fidentity}/plans`, plan);
        });
    }
    getCsv(fid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`${baseUrl}/${fid}/csv`);
        });
    }
    getFacilityGallerySnapshot(facility) {
        return {
            identity: facility.facilityIdentity,
            name: facility.name,
            spaces: facility.spaces
                ? facility.spaces.map((space) => this.getSpaceGallerySnapshot(space))
                : [],
        };
    }
    getSpaceGallerySnapshot(space) {
        return {
            identity: space.spaceIdentity,
            facilityIdentity: space.facilityIdentity,
            name: space.spaceId,
            constrYear: space.constrYear,
            assets: space.assets
                ? space.assets.map((asset) => this.getAssetGallerySnapshot(asset))
                : [],
        };
    }
    getAssetGallerySnapshot(asset) {
        return {
            identity: asset.assetIdentity,
            spaceIdentity: asset.spaceIdentity,
            name: asset.name,
            category: asset.category,
            assetType: asset.assetType,
            installYear: asset.approxYear,
        };
    }
}
