var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid, GridItem, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsPlusCircle } from 'react-icons/bs';
import { useAppSelector } from '../../../store/hooks';
import { selectOrganization } from '../../../store/orgSlice';
import { usePhotoServiceHook } from '../../../store/photoServiceHook';
export const UploadOfflinePhotosModal = ({ isOpen, onClose, }) => {
    const org = useAppSelector(selectOrganization);
    const [photoArray, setPhotoArray] = useState([]);
    const [preview, setPreview] = useState([]);
    const { photoUploadDownload } = usePhotoServiceHook();
    const { handleSubmit } = useForm();
    const photosRef = useRef(null);
    useEffect(() => {
        setPreview([]);
        preview.map((url) => URL.revokeObjectURL(url));
        const objectUrls = photoArray.map((photo) => {
            return URL.createObjectURL(photo);
        });
        setPreview(objectUrls);
        // free memory when ever this component is unmounted
        return () => {
            preview.map((url) => URL.revokeObjectURL(url));
        };
    }, [photoArray]);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        org && (yield photoUploadDownload(photoArray, org.orgIdentity, true));
        onClose();
    });
    const updateState = () => {
        var _a, _b;
        const photoArray = ((_a = photosRef.current) === null || _a === void 0 ? void 0 : _a.files)
            ? Array.from((_b = photosRef.current) === null || _b === void 0 ? void 0 : _b.files)
            : [];
        setPhotoArray(photoArray);
    };
    const renderFileList = () => {
        return (_jsx(Grid, Object.assign({ templateColumns: 'repeat(5, 1fr)', gap: 6 }, { children: preview.map((url, i) => (_jsx(GridItem, { children: _jsx(Image, { src: url, h: '50px', w: '50px', border: '1px solid black' }, url + i) }, `preview grid cell: ${url}`))) })));
    };
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose }, { children: [_jsx(ModalOverlay, {}), _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Upload Photos" }), _jsx(ModalCloseButton, {}), _jsxs(ModalBody, { children: [_jsx(Button, Object.assign({ onClick: () => {
                                        var _a;
                                        (_a = photosRef === null || photosRef === void 0 ? void 0 : photosRef.current) === null || _a === void 0 ? void 0 : _a.click();
                                    }, leftIcon: _jsx(BsPlusCircle, {}), variant: 'ghost', display: 'inline-block' }, { children: "Upload Photos" })), _jsx(Input, { type: 'file', multiple: true, style: { display: 'none' }, accept: 'image/*,video/*', onChange: updateState, ref: photosRef }), _jsx(Text, { children: "Files being uploaded:" }), renderFileList()] }), _jsx(ModalFooter, { children: _jsx(Button, Object.assign({ type: 'submit', disabled: !(photoArray.length > 0) }, { children: "Submit" })) })] }) }))] })));
};
