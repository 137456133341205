var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { Box, Button, Flex, Heading, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch, } from 'react-hook-form';
import { slgqColors, variants } from '../../../../../config/theme';
import { useSpaceContext } from '../../../../../contexts/Spaces/SpaceContext';
import { useTemplateContext } from '../../../../../contexts/Template/TemplateContext';
import { convertSpaceToSpaceForm } from '../../../../../conversions/SpaceConversions';
import { AssetClassType, PageModes, SpaceCategoryEnum, } from '../../../../../objects/FCAInterfaces';
import { useAppSelector } from '../../../../../store/hooks';
import { facilityRoleCanEdit, selectFacilityRole, } from '../../../../../store/userSlice';
import { preprocessMultiAssets } from '../../../../../utils/staticAssets';
import { DynamicFormInput, } from '../../Inputs/DynamicFormInput';
import { SidebarHeader } from '../../SidebarItems/SidebarHeader';
import { SpaceDetails } from './SpaceDetails';
import { SpaceIEQ } from './SpaceIEQ';
import { StandardAssets } from './StandardAssetsSidebar/StandardAssets';
export const SidebarSpace = () => {
    const { pageMode, setPageMode, selectedPlan, drawSpaceShape, updateCurrentSpaces, isDataLoading, setIsDataLoading, selectSpace, } = useFacilityContext();
    const { currentSpace, standardAssets, submitSpaceForm, saveMultipleAssets, } = useSpaceContext();
    const { assetTemplates, getAssetTemplateVersionByClass, updateSpaceDefinition, } = useTemplateContext();
    const facilityRole = useAppSelector(selectFacilityRole);
    const canEdit = facilityRoleCanEdit(facilityRole);
    const isAdding = pageMode === PageModes.ADD_SPACE;
    const addingOrUpdating = isAdding || pageMode === PageModes.EDIT;
    const standardAssetTemplates = assetTemplates.filter((template) => template.classType === AssetClassType.STANDARD);
    const spaceUseForm = useForm({
        values: convertSpaceToSpaceForm(isAdding ? undefined : currentSpace, isAdding ? [] : standardAssets, standardAssetTemplates),
    });
    const spaceDefinition = useWatch({
        name: 'definition',
        control: spaceUseForm.control,
    });
    const spaceType = useWatch({ name: 'type', control: spaceUseForm.control });
    const isAssessed = {
        name: 'isAssessed',
        inputType: 'checkbox',
        label: 'Space Fully Assessed',
    };
    const resetSpaceForm = (updatedStandardAssets, updatedSpace, standardAssetTemplates, isCancel = false) => {
        // use existing assets if assets save failed (id assets field is dirty, we know assets did not save)
        if (!spaceUseForm.getFieldState('standardAssets').isDirty || isCancel) {
            spaceUseForm.reset(convertSpaceToSpaceForm(updatedSpace, updatedStandardAssets, standardAssetTemplates));
        }
    };
    useEffect(() => {
        if (isAdding) {
            updateSpaceDefinition(SpaceCategoryEnum.FACILITY);
            resetSpaceForm([], undefined, standardAssetTemplates);
        }
        else {
            resetSpaceForm(standardAssets, currentSpace, standardAssetTemplates);
        }
    }, [currentSpace, standardAssets, isAdding]);
    const parseTouchedAssets = (form) => {
        // existing assets must always be submitted, so backend ROM calculations based on space changes are applied to existing assets
        const existingAssets = form.standardAssets.filter((asset) => asset.identity !== undefined);
        // need to first filter by dirty flag as it relies on the specific index within the template
        const newAssets = form.standardAssets
            .filter((_, index) => {
            const dirtyFlag = spaceUseForm.getFieldState(`standardAssets.${index}`).isDirty;
            return dirtyFlag;
        })
            .filter((asset) => asset.identity === undefined);
        return [...existingAssets, ...newAssets];
    };
    const onSubmit = (formData) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedPlan && addingOrUpdating) {
            setIsDataLoading(true);
            const savedSpace = yield submitSpaceForm(selectedPlan.identity, formData, drawSpaceShape).then((space) => {
                if (space) {
                    updateCurrentSpaces(space);
                    selectSpace(space.spaceIdentity);
                    return space;
                }
            });
            const modifiedAssets = parseTouchedAssets(formData);
            const preprocessedAssets = preprocessMultiAssets(modifiedAssets, standardAssets, standardAssetTemplates);
            // only attempt to save assets if space has been saved first
            // requires a space id to save standard assets
            if (savedSpace && modifiedAssets.length > 0) {
                const savedAssets = yield saveMultipleAssets(savedSpace.spaceIdentity, preprocessedAssets, getAssetTemplateVersionByClass)
                    .then((assets) => {
                    if (assets) {
                        setPageMode(PageModes.VIEW);
                        return assets;
                    }
                })
                    .finally(() => setIsDataLoading(false));
                if (!savedAssets) {
                    return;
                } //don't clear form if asset error
            }
            else {
                if (savedSpace) {
                    setPageMode(PageModes.VIEW);
                    setIsDataLoading(false);
                }
                else {
                    setIsDataLoading(false);
                    return; //don't clear form if space error
                }
            }
        }
    });
    const handleEditSpace = () => __awaiter(void 0, void 0, void 0, function* () {
        if (canEdit && !addingOrUpdating) {
            setPageMode(PageModes.EDIT);
        }
    });
    const renderSaveButton = () => {
        return (_jsx(Button, Object.assign({ type: 'submit', w: '60%', variant: variants.blueBtn, "data-testid": 'save-space' }, { children: "Save" })));
    };
    const renderCancelButton = () => {
        return (_jsx(Button, Object.assign({ "data-testid": 'cancel-space', onClick: () => {
                resetSpaceForm(standardAssets, currentSpace, standardAssetTemplates, true);
                setPageMode(PageModes.VIEW);
            }, variant: variants.whiteBtn, w: '60%' }, { children: "Cancel" })));
    };
    const renderBtns = () => {
        return (_jsxs(Stack, Object.assign({ w: '100%', mt: '10px', alignItems: 'center', minH: '68px' }, { children: [renderSaveButton(), renderCancelButton()] })));
    };
    return (_jsxs(Flex, Object.assign({ h: '100%', w: '100%', direction: 'column' }, { children: [_jsx(FormProvider, Object.assign({}, spaceUseForm, { children: _jsx("form", Object.assign({ onSubmit: spaceUseForm.handleSubmit(onSubmit), noValidate // prevent html form validation in favor of react hook form validation
                    : true, "data-testid": 'space-form' }, { children: _jsxs(Stack, Object.assign({ w: '100%', h: '100%', gap: '0px' }, { children: [_jsx(Heading, Object.assign({ as: 'h2', bg: 'misc.sectionGrey', borderBottom: '1px solid #C0C2C1', fontWeight: '600', variant: variants.detailsSidebar }, { children: isAdding ? 'Add New Space' : currentSpace === null || currentSpace === void 0 ? void 0 : currentSpace.spaceId })), _jsxs(Tabs, Object.assign({ className: 'detailsTabs', variant: 'line', colorScheme: 'orange', bg: slgqColors.primWhite, isLazy: true }, { children: [_jsxs(TabList, Object.assign({ bg: 'misc.sectionGrey' }, { children: [_jsx(Tab, Object.assign({ py: '0px' }, { children: _jsx(SidebarHeader, { title: 'Details' }) })), _jsx(Tab, Object.assign({ py: '0px', isDisabled: isAdding, "data-testid": 'standard-assets-tab' }, { children: _jsx(SidebarHeader, { title: 'Assets' }) })), spaceDefinition ===
                                                SpaceCategoryEnum.FACILITY && (_jsx(Tab, Object.assign({ py: '0px' }, { children: _jsx(SidebarHeader, { title: 'IEQ' }) })))] })), _jsx(Box, Object.assign({ maxHeight: '58vh', overflowY: 'auto', overflowX: 'hidden', sx: { scrollbarGutter: 'stable' } }, { children: _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(Stack, Object.assign({ "data-testid": 'space-details' }, { children: _jsx(SpaceDetails, { currentSpace: currentSpace, spaceType: spaceType, spaceDefinition: spaceDefinition, canEdit: canEdit, isEditing: addingOrUpdating }) })) }), _jsx(TabPanel, { children: currentSpace &&
                                                        pageMode !==
                                                            PageModes.ADD_SPACE && (_jsx(StandardAssets, { standardAssets: standardAssets, spaceIdentity: currentSpace.spaceIdentity, spaceName: currentSpace.spaceId, standardAssetTemplates: standardAssetTemplates, canEdit: canEdit, isEditing: addingOrUpdating })) }), spaceDefinition ===
                                                    SpaceCategoryEnum.FACILITY && (_jsx(TabPanel, { children: _jsx(SpaceIEQ, { space: currentSpace, canEdit: canEdit, isEditing: addingOrUpdating }) }))] }) })), _jsx(Box, Object.assign({ p: '20px 31px 0px 16px', borderTop: '1px solid #C0C2C1', onClick: () => {
                                            handleEditSpace();
                                        }, onTouchEnd: () => {
                                            handleEditSpace();
                                        } }, { children: _jsx(DynamicFormInput, { inputData: isAssessed, control: spaceUseForm.control, isEditing: addingOrUpdating }) }))] })), _jsx(Spacer, {}), addingOrUpdating && renderBtns()] })) })) })), _jsx(LoadingWithControl, { isLoading: isDataLoading })] })));
};
