import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Heading, HStack } from '@chakra-ui/react';
import { Dropdown } from '@frontend/design-system/components/Toolbar/Dropdown';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appColors } from '../../../config/constants';
import { Paths } from '../../../config/paths';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchOrg, fetchOrganizationSnapshots, selectAllOrgsSnapshots, selectOrganization, } from '../../../store/orgSlice';
import { selectUser } from '../../../store/userSlice';
export const PageHeader = ({ title }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const orgs = useAppSelector(selectAllOrgsSnapshots);
    const currentOrg = useAppSelector(selectOrganization);
    const userCurrent = useAppSelector(selectUser);
    const sessionItemOrg = window.sessionStorage.getItem('currOrg');
    const sessionOrg = sessionItemOrg
        ? JSON.parse(sessionItemOrg)
        : undefined;
    useEffect(() => {
        dispatch(fetchOrganizationSnapshots());
    }, [userCurrent]);
    useEffect(() => {
        if (!currentOrg && orgs.length > 0) {
            const defaultOrg = sessionOrg !== null && sessionOrg !== void 0 ? sessionOrg : orgs[0];
            dispatch(fetchOrg({ id: defaultOrg.id }));
            navigate(`${Paths.ORGS}/${defaultOrg.id}${Paths.SITES}`);
        }
    }, [orgs]);
    const handleOrgChange = (e) => {
        const orgId = e;
        dispatch(fetchOrg({ id: orgId }));
        if (location.pathname.includes(Paths.GALLERY)) {
            navigate(`${Paths.GALLERY}/${orgId}`);
        }
        else {
            navigate(`${Paths.ORG_SITES}`.replace(':oid', orgId));
        }
    };
    useEffect(() => {
        if (currentOrg) {
            sessionStorage.setItem('oid', currentOrg.id);
            sessionStorage.setItem('currOrg', JSON.stringify(currentOrg));
        }
    }, [currentOrg]);
    return (_jsx(_Fragment, { children: _jsx(Flex, Object.assign({ as: 'header', minH: '50px', w: '100%', alignItems: 'center' }, { children: _jsxs(HStack, Object.assign({ borderBottom: '1px solid #E6E7E9', justifyContent: 'space-between', w: '100%', h: '100%', p: '5px 40px' }, { children: [_jsx(Heading, Object.assign({ as: 'h1', fontSize: 36, fontWeight: 400, color: appColors.TEXT_SEC_DARK_GRAY, whiteSpace: 'nowrap' }, { children: title })), _jsx(Dropdown, { dropdownValues: orgs.map((org) => ({
                            label: org.name,
                            value: org.id,
                        })) || [], label: 'Organization', isLoading: orgs === undefined, handleChange: handleOrgChange, value: (currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.id) || '', bgColor: appColors.PRIM_BLUE })] })) })) }));
};
