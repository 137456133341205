var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAssetApi } from '@/services/useAssetApi';
import { useToast } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { defaultOptions, usePlatformApiRequestHandler, } from '@frontend/domain/hooks/platformAPI/usePlatformApiRequestHandler';
import { useEffect, useState } from 'react';
import { convertAssetFormToAssessmentRequest, convertAssetSummaryResponseToAssignedAssets, convertToAssets, } from '../../conversions/AssetConversions';
import { convertSpaceDetailResponseToSpace, convertSpaceFormToSpaceEvaluationRequest, } from '../../conversions/SpaceConversions';
import { AssetClassType, } from '../../objects/FCAInterfaces';
import { useFacilityContext } from '../Facilities/FacilityPageContext';
import { SpaceContext } from './SpaceContext';
export function SpaceProvider({ children, spaceIdentity, facilityIdentity, }) {
    const toast = useToast();
    const { platformApi } = useDependencies();
    const { updateCurrentSpaces } = useFacilityContext();
    const { reassessAssetCondition } = useAssetApi();
    const [currentSpace, setCurrentSpace] = useState();
    const [standardAssets, setStandardAssets] = useState([]);
    const [allFacilityAssetsList, setAssetFacilityAssetsList] = useState([]);
    const assessMultipleAssetsRequestHandler = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: { label: 'There was an error when saving the assets' } }));
    const loadSpace = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the space',
        } }));
    const loadAssetList = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the assets',
        } }));
    const listAssets = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the asset list',
        } }));
    const evaluateSpaceRequestHandler = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when saving the space',
        } }));
    const reassessSpaceIEQHandler = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when reassessing the space IEQ',
        } }));
    const fetchAssetList = (spaceIdentity, assetTypes) => {
        loadAssetList
            .handleResponse(platformApi.asset.postApiV1AssetLoad({
            spaceId: spaceIdentity,
            assetClassTypes: [...assetTypes],
        }), (assets) => convertToAssets(spaceIdentity, assets))
            .then((assets) => {
            if (assets) {
                setStandardAssets(assets);
            }
        });
    };
    const fetchAssetsListByFacility = (facilityId, assetClassTypes) => {
        listAssets
            .handleResponse(platformApi.asset.postApiV1AssetList({
            facilityId: facilityId,
            assetClassTypes: [...assetClassTypes],
        }), (assets) => convertAssetSummaryResponseToAssignedAssets(assets))
            .then((assets) => {
            if (assets) {
                setAssetFacilityAssetsList(assets);
            }
        });
    };
    const confirmSingleSpaceFromDetails = (space) => {
        if (space.length !== 1) {
            console.warn('Unexpected number of spaces returned in response');
        }
        return convertSpaceDetailResponseToSpace(space[0]);
    };
    const fetchSpace = (spaceIdentity) => {
        loadSpace
            .handleResponse(platformApi.space.getApiV1SpaceLoad(undefined, spaceIdentity), (spaces) => confirmSingleSpaceFromDetails(spaces))
            .then((space) => {
            if (space) {
                setCurrentSpace(space);
            }
        });
    };
    const submitSpaceForm = (floorPlanId, spaceForm, spaceShape) => __awaiter(this, void 0, void 0, function* () {
        const evaluateSpaceBody = convertSpaceFormToSpaceEvaluationRequest(floorPlanId, spaceForm, spaceShape);
        return evaluateSpaceRequestHandler
            .handleResponse(platformApi.space.postApiV1SpaceEvaluate(evaluateSpaceBody), convertSpaceDetailResponseToSpace)
            .then((space) => {
            if (space) {
                setCurrentSpace(space);
                return space;
            }
        });
    });
    const saveMultipleAssets = (spaceIdentity, assetForms, getAssetTemplateVersionByClass) => __awaiter(this, void 0, void 0, function* () {
        const assessAssetRequest = convertAssetFormToAssessmentRequest(spaceIdentity, assetForms, getAssetTemplateVersionByClass);
        return assessMultipleAssetsRequestHandler
            .handleResponse(platformApi.asset.postApiV1AssetAssess(assessAssetRequest), (savedAssets) => convertToAssets(spaceIdentity, savedAssets))
            .then((assets) => {
            if (assets) {
                updateAssetsInStandardAssetsList(assets);
                return assets;
            }
        });
    });
    const updateAssetsInStandardAssetsList = (assets) => {
        assets.forEach((asset) => {
            const replaceIndex = standardAssets.findIndex((standardAsset) => standardAsset.identity === asset.identity);
            if (replaceIndex >= 0) {
                standardAssets.splice(replaceIndex, 1, asset);
            }
            else {
                standardAssets.push(asset);
            }
        });
        setStandardAssets([...standardAssets]);
    };
    const getStandardAssetInClass = (assetClass) => {
        return standardAssets.find((asset) => asset.assetClass === assetClass);
    };
    const reassessSpaceIEQ = (newValue, assessedOn, type) => {
        let newValues;
        let occupancy;
        if (typeof newValue === 'object') {
            newValues = {
                value: newValue.co2Value,
                measuredOn: assessedOn,
            };
            occupancy = newValue.occupancyNumber;
        }
        else {
            newValues = {
                value: newValue,
                measuredOn: assessedOn,
            };
        }
        const metricMapping = {
            cO2: {
                metric: 'cO2Metric',
                extraValues: {
                    occupancyMetric: {
                        value: occupancy,
                        measuredOn: assessedOn,
                    },
                },
            },
            light: { metric: 'lightMetric' },
            sound: { metric: 'soundMetric' },
            temperature: { metric: 'temperatureMetric' },
            humidity: { metric: 'humidityMetric' },
        };
        if (spaceIdentity && type && metricMapping[type]) {
            const { metric, extraValues } = metricMapping[type];
            const payload = Object.assign({ spaceId: spaceIdentity, [metric]: newValues }, extraValues);
            reassessSpaceIEQHandler
                .handleResponse(platformApi.space.postApiV1SpaceReassess(payload), (newSpaceIEQ) => convertSpaceDetailResponseToSpace(newSpaceIEQ))
                .then((space) => {
                if (space) {
                    setCurrentSpace(space);
                    updateCurrentSpaces(space);
                }
            });
        }
    };
    const reassessStandardAssetCondition = (newCondition, assessedOn, assetId) => __awaiter(this, void 0, void 0, function* () {
        if (spaceIdentity && assetId) {
            reassessAssetCondition
                .reassess(newCondition, assessedOn, assetId, spaceIdentity)
                .then((asset) => {
                if (asset) {
                    setStandardAssets((previousAssets) => {
                        return previousAssets.map((standardAsset) => standardAsset.identity === asset.identity
                            ? asset
                            : standardAsset);
                    });
                }
            });
        }
    });
    useEffect(() => {
        if (facilityIdentity) {
            fetchAssetsListByFacility(facilityIdentity, [
                AssetClassType.FACILITY,
            ]);
        }
    }, [facilityIdentity]);
    useEffect(() => {
        if (spaceIdentity) {
            fetchAssetList(spaceIdentity, [AssetClassType.STANDARD]);
            fetchSpace(spaceIdentity);
        }
        else {
            setCurrentSpace(undefined);
            setStandardAssets([]);
        }
    }, [spaceIdentity]);
    return (_jsx(SpaceContext.Provider, Object.assign({ value: {
            saveMultipleAssets,
            standardAssets,
            currentSpace,
            manageFacilityWideAssets: {
                allFacilityAssetsList,
            },
            getStandardAssetInClass,
            submitSpaceForm,
            reassessSpaceIEQ,
            reassessStandardAssetCondition,
        } }, { children: children })));
}
