import { jsx as _jsx } from "react/jsx-runtime";
import { slgqColors } from '@/config/theme';
import { usePhotoContext } from '@/contexts/Photo/PhotoContext';
import { Flex } from '@chakra-ui/react';
import { generateTextColor } from '../../../../../../config/FCAHelperFunctions';
import { useGalleryContext } from '../../../../../../contexts/Photo/GalleryContext';
import { FilterCard } from './FilterCard';
export const FilterCardsSection = () => {
    const { currentFilters, orgTags } = usePhotoContext();
    const { facilityDropdown, spaceDropdown, assetDropdown } = useGalleryContext();
    const createCurrentFiltersArray = (filter) => {
        const filterList = [];
        if (filter.facilityIdentities) {
            const facilities = facilityDropdown.filter((facility) => {
                return (filter.facilityIdentities &&
                    filter.facilityIdentities.some((filter) => {
                        return (filter === null || filter === void 0 ? void 0 : filter.value) === facility.identity;
                    }));
            });
            if (facilities.length > 0) {
                facilities.forEach((facility) => {
                    filterList.push({
                        label: facility.name,
                        key: 'facilityIdentities',
                        value: facility.identity,
                    });
                });
            }
        }
        if (filter.spaceYears) {
            filter.spaceYears.forEach((spaceYear) => {
                filterList.push({
                    label: `Space Construction year: ${spaceYear === null || spaceYear === void 0 ? void 0 : spaceYear.label}`,
                    key: 'spaceYears',
                    value: spaceYear === null || spaceYear === void 0 ? void 0 : spaceYear.value,
                });
            });
        }
        if (filter.spaceIdentities) {
            const spaces = spaceDropdown.filter((space) => {
                return (filter.spaceIdentities &&
                    filter.spaceIdentities.some((filter) => {
                        return (filter === null || filter === void 0 ? void 0 : filter.value) === space.identity;
                    }));
            });
            if (spaces) {
                spaces.forEach((space) => {
                    filterList.push({
                        label: space.name,
                        key: 'spaceIdentities',
                        value: space.identity,
                    });
                });
            }
        }
        if (filter.assetCategories) {
            filter.assetCategories.forEach((assetCategory) => {
                filterList.push({
                    label: assetCategory === null || assetCategory === void 0 ? void 0 : assetCategory.label,
                    key: 'assetCategories',
                    value: assetCategory === null || assetCategory === void 0 ? void 0 : assetCategory.value,
                });
            });
        }
        if (filter.assetTypes) {
            filter.assetTypes.forEach((assetType) => {
                filterList.push({
                    label: assetType === null || assetType === void 0 ? void 0 : assetType.label,
                    key: 'assetTypes',
                    value: assetType === null || assetType === void 0 ? void 0 : assetType.value,
                });
            });
        }
        if (filter.assetYears) {
            filter.assetYears.forEach((assetYear) => {
                filterList.push({
                    label: `Install Year: ${assetYear === null || assetYear === void 0 ? void 0 : assetYear.label}`,
                    key: 'assetYears',
                    value: assetYear === null || assetYear === void 0 ? void 0 : assetYear.value,
                });
            });
        }
        if (filter.assetIdentities) {
            const assets = assetDropdown.filter((asset) => {
                return (filter.assetIdentities &&
                    filter.assetIdentities.some((filter) => {
                        return (filter === null || filter === void 0 ? void 0 : filter.value) === asset.identity;
                    }));
            });
            if (assets) {
                assets.forEach((asset) => {
                    filterList.push({
                        label: asset.name,
                        key: 'assetIdentities',
                        value: asset.identity,
                    });
                });
            }
        }
        if (filter.conditions) {
            filter.conditions.forEach((condition) => {
                filterList.push({
                    label: condition === null || condition === void 0 ? void 0 : condition.label,
                    key: 'conditions',
                    value: condition === null || condition === void 0 ? void 0 : condition.value,
                });
            });
        }
        if (filter.favorites) {
            filterList.push({
                label: 'Favorite',
                key: 'favorites',
                value: filter.favorites,
            });
        }
        if (filter.tags) {
            filter.tags.forEach((tag) => {
                const foundTag = orgTags.find((orgTag) => orgTag.tagIdentity === tag);
                if (foundTag) {
                    filterList.push({
                        label: foundTag.name,
                        key: 'tags',
                        value: foundTag.tagIdentity,
                        backgroundColor: foundTag.color,
                        textColor: generateTextColor(foundTag.color),
                    });
                }
            });
        }
        return filterList;
    };
    const currentFiltersArray = currentFilters
        ? createCurrentFiltersArray(currentFilters)
        : [];
    return (_jsx(Flex, Object.assign({ flexWrap: 'wrap', mb: '10px', gap: '5px', wrap: 'wrap' }, { children: currentFiltersArray === null || currentFiltersArray === void 0 ? void 0 : currentFiltersArray.map((filter, index) => {
            var _a;
            return (_jsx(FilterCard, { name: filter.label, backgroundColor: (_a = filter.backgroundColor) !== null && _a !== void 0 ? _a : slgqColors.primOrange, textColor: filter.textColor, filter: filter }, `filter-card-${index}`));
        }) })));
};
