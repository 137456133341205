import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Center, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { usePlatformOrgApi } from '../../services/api/platformOrgApiHook';
import { useAppSelector } from '../../store/hooks';
import { selectOrganization } from '../../store/orgSlice';
export const AccessDenied = () => {
    const [loading, setLoading] = useState(false);
    const [concierge, setConcierge] = useState(undefined);
    const currentOrg = useAppSelector(selectOrganization);
    const { getPlatformOrgClientConcierge } = usePlatformOrgApi();
    useEffect(() => {
        if (currentOrg) {
            setLoading(true);
            getPlatformOrgClientConcierge(currentOrg.orgIdentity)
                .then((concierge) => setConcierge(concierge))
                .catch((e) => console.error(e))
                .finally(() => setLoading(false));
        }
    }, [currentOrg]);
    return (_jsx(_Fragment, { children: loading ? (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsx(Spinner, { color: 'blue.500' }) }))) : (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsxs(Box, Object.assign({ textAlign: 'center' }, { children: [_jsx(Text, Object.assign({ mb: '2' }, { children: "At this moment, you do not have access." })), _jsx(Text, { children: concierge
                            ? `Please contact your client concierge, ${concierge.firstName} ${concierge.lastName} at ${concierge.email}`
                            : 'Please contact your client concierge' })] })) }))) }));
};
