import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Image, Text, useToken } from '@chakra-ui/react';
import co2legend_with_values_svg from '../../../../../../images/co2_legend_with_values.svg';
import { FacilityMapTypes } from '../../../../../../objects/FCAHelperInterfaces';
import { CustomLineChart } from '../Chart/CustomLineChart';
export const MetricChart = ({ graphConfig, type }) => {
    var _a, _b;
    const labels = (_a = graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.chartData.data.datasets) === null || _a === void 0 ? void 0 : _a.filter((dataset) => dataset.type === type)[0].years;
    const dataValues = (_b = graphConfig === null || graphConfig === void 0 ? void 0 : graphConfig.chartData.data.datasets) === null || _b === void 0 ? void 0 : _b.filter((dataset) => dataset.type === type)[0].values;
    const dataset = dataValues ? dataValues : [];
    const [blue] = useToken('colors', ['slgq.tertBlue']);
    const getColors = (n, type) => {
        if (type === FacilityMapTypes.CO2) {
            if (n < 600) {
                return '#17a34a';
            }
            else if (n >= 600 && n < 1000) {
                return '#fedb5f';
            }
            else if (n >= 1000 && n < 2500) {
                return '#da1000';
            }
            else if (n >= 2500) {
                return '#2e3532';
            }
            else {
                return 'black';
            }
        }
        else if (type === FacilityMapTypes.TEMPERATURE) {
            if (n < 68) {
                return '#013b5d';
            }
            else if (n >= 68 && n <= 72) {
                return '#17a34a';
            }
            else if (n >= 73 && n <= 74) {
                return '#fedc5e';
            }
            else if (n >= 75 && n <= 76) {
                return '#f6862a';
            }
            else if (n > 76) {
                return '#da1302';
            }
            else {
                return 'black';
            }
        }
        else {
            return '#FEDB5E';
        }
    };
    const ieqLegends = {
        co2: [
            { text: '< 600', color: '#17a34a' },
            { text: '600 - 1000', color: '#fedb5f' },
            { text: '1000 - 2500', color: '#da1000' },
            { text: '> 2500', color: '#2e3532' },
        ],
        temperature: [
            { text: '< 68', color: '#013b5d' },
            { text: '68 - 72', color: '#17a34a' },
            { text: '73 - 74', color: '#fedc5e' },
            { text: '75 - 76', color: '#f6862a' },
            { text: '> 76', color: '#da1302' },
        ],
    };
    const getLegend = (type) => {
        if (type === FacilityMapTypes.CO2) {
            return ieqLegends.co2;
        }
        if (type === FacilityMapTypes.TEMPERATURE) {
            return ieqLegends.temperature;
        }
        return [];
    };
    const getComponent = (type) => {
        if (type === FacilityMapTypes.CO2) {
            return (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ fontWeight: 600, fontSize: '11px' }, { children: 'CO\u2082 Levels Details' })), _jsx(Image, { src: co2legend_with_values_svg, width: '80%', height: 'auto' })] }));
        }
    };
    const MeasureMapping = {
        [FacilityMapTypes.CO2]: {
            getLegend: () => {
                return getLegend(type);
            },
            getColors: (value) => {
                return getColors(value, FacilityMapTypes.CO2);
            },
            getComponent: () => {
                return getComponent(type);
            },
        },
        [FacilityMapTypes.TEMPERATURE]: {
            getLegend: () => {
                return getLegend(type);
            },
            getColors: (value) => {
                return getColors(value, FacilityMapTypes.TEMPERATURE);
            },
            getComponent: () => {
                return getComponent(type);
            },
        },
    };
    const getMeasure = (type) => {
        if (type === FacilityMapTypes.CO2) {
            return FacilityMapTypes.CO2;
        }
        else if (type === FacilityMapTypes.TEMPERATURE) {
            return FacilityMapTypes.TEMPERATURE;
        }
        else {
            return FacilityMapTypes.CO2;
        }
    };
    const pointBorderColors = dataset.map((value) => MeasureMapping[getMeasure(type)].getColors(value));
    const pointBackgroundColors = dataset.map((value) => MeasureMapping[getMeasure(type)].getColors(value));
    const legend = MeasureMapping[getMeasure(type)].getLegend();
    const component = MeasureMapping[getMeasure(type)].getComponent();
    const data = {
        labels,
        datasets: [
            {
                pointBackgroundColor: pointBackgroundColors,
                pointBorderColor: pointBorderColors,
                data: dataset,
                borderColor: blue,
                backgroundColor: blue,
            },
        ],
    };
    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'year',
                },
            },
        },
    };
    return (_jsx(HStack, Object.assign({ w: '100%', pb: '70px', pl: '20px', pr: '20px' }, { children: _jsx(CustomLineChart, { data: data, options: options, legend: legend, title: type, component: component }) })));
};
