var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { defaultOptions, usePlatformApiRequestHandler, } from '@frontend/domain/hooks/platformAPI/usePlatformApiRequestHandler';
import { useEffect, useState } from 'react';
import { convertAssetFormToAssessmentRequest, convertToSingleAssetFromDetails, } from '../../conversions/AssetConversions';
import { AssetContext } from './AssetContext';
import { useAssetApi } from '@/services/useAssetApi';
export function AssetProvider({ children, spaceIdentity, assetIdentity, }) {
    const toast = useToast();
    const { platformApi } = useDependencies();
    const [asset, setAsset] = useState();
    const [isAssetLoading, setIsAssetLoading] = useState(true);
    const { reassessAssetCondition } = useAssetApi();
    const assessAssetRequestHandler = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when saving the asset',
        } }));
    const loadAssetDetails = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the asset',
        } }));
    const fetchAsset = (assetIdentity) => {
        loadAssetDetails
            .handleResponse(platformApi.asset.postApiV1AssetLoad({
            assetId: assetIdentity,
            assetClassTypes: [],
        }), (assets) => convertToSingleAssetFromDetails(spaceIdentity, assets))
            .then((asset) => {
            if (asset) {
                setAsset(asset);
            }
        });
    };
    const submitAssetForm = (assetForm, spaceIdentity, getAssetTemplateVersionByClass) => __awaiter(this, void 0, void 0, function* () {
        setIsAssetLoading(true);
        const assessAssetRequest = convertAssetFormToAssessmentRequest(spaceIdentity, [assetForm], getAssetTemplateVersionByClass);
        return assessAssetRequestHandler
            .handleResponse(platformApi.asset.postApiV1AssetAssess(assessAssetRequest), (assets) => convertToSingleAssetFromDetails(spaceIdentity, assets))
            .then((asset) => {
            if (asset) {
                setAsset(asset);
                return asset;
            }
        })
            .finally(() => setIsAssetLoading(false));
    });
    const reassessPinnedAssetCondition = (newCondition, assessedOn) => __awaiter(this, void 0, void 0, function* () {
        if (asset) {
            reassessAssetCondition
                .reassess(newCondition, assessedOn, asset.identity, asset.spaceIdentity)
                .then((asset) => {
                if (asset) {
                    setAsset(asset);
                }
            });
        }
    });
    useEffect(() => {
        if (assetIdentity && spaceIdentity) {
            fetchAsset(assetIdentity);
        }
        else {
            setAsset(undefined);
        }
    }, [assetIdentity, spaceIdentity]);
    return (_jsx(AssetContext.Provider, Object.assign({ value: {
            asset,
            isAssetLoading,
            submitAssetForm,
            reassessPinnedAssetCondition,
        } }, { children: children })));
}
