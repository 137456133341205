import { createSlice } from '@reduxjs/toolkit';
const initialCachingError = undefined;
export const dbCacheSlice = createSlice({
    name: 'dbCache',
    initialState: {
        cachingError: initialCachingError,
    },
    reducers: {
        setCachingErrorAsToast: (state, { payload: cacheErrorToast }) => {
            state.cachingError = cacheErrorToast;
        },
    },
});
export const selectCachingError = (state) => {
    return state.dbCache.cachingError;
};
export const { setCachingErrorAsToast } = dbCacheSlice.actions;
export default dbCacheSlice.reducer;
