import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { setFacility, setPlans } from '@/store/facilitySlice';
import { setSite } from '@/store/siteSlice';
import { Center, Flex, Stack } from '@chakra-ui/react';
import { Loading } from '@frontend/design-system/components/Loading/Loading';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../../components/layouts/PageLayout';
import { appValues } from '../../../config/constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchOrg, fetchSites, selectOrganization, selectSites, setShowAgeAdjustment, } from '../../../store/orgSlice';
import { selectUser } from '../../../store/userSlice';
import { SiteSection } from './SiteSection';
export const SitesPage = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const org = useAppSelector(selectOrganization);
    const retrieveSites = useAppSelector(selectSites);
    const { sites, loading: isLoading } = retrieveSites;
    const currentUser = useAppSelector(selectUser);
    const canRenderSiteSection = org && sites && sites.length > 0;
    useEffect(() => {
        dispatch(setSite(undefined));
        dispatch(setFacility(undefined));
        dispatch(setPlans(undefined));
    }, []);
    useEffect(() => {
        // We store this to session storage, so the main header info stays on refresh.
        if (org && params.oid === org.id) {
            dispatch(setShowAgeAdjustment(org.showAgeAdjustedCondition));
            dispatch(fetchSites({
                orgId: params.oid,
                user: currentUser !== null && currentUser !== void 0 ? currentUser : undefined,
            }));
        }
        else if (params.oid) {
            dispatch(fetchOrg({ id: params.oid }));
        }
    }, [org]);
    const renderSites = () => {
        return (_jsx(_Fragment, { children: canRenderSiteSection &&
                sites.map((site, i) => {
                    return _jsx(SiteSection, { site: site, org: org }, i);
                }) }));
    };
    return (_jsx(PageLayout, Object.assign({ title: org === null || org === void 0 ? void 0 : org.name }, { children: _jsx(Flex, Object.assign({ as: 'main', h: `calc(100vh - ${appValues.HEADER_HEIGHT}px)`, alignItems: 'flex-start', p: '50px 50px 0px 50px' }, { children: !isLoading ? (_jsx(Stack, Object.assign({ width: '100%', gap: '0px' }, { children: renderSites() }))) : (_jsx(Center, Object.assign({ width: '100%', height: '100%', overflowY: 'hidden', backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: '100' }, { children: _jsx(Loading, {}) }))) })) })));
};
