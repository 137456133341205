import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ViewIcon } from '@chakra-ui/icons';
import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { ChartModal } from '../Chart/ChartModal';
export const ConditionChartButton = ({ graphConfig, }) => {
    const { isOpen: isChartModal, onOpen: onOpenChartModal, onClose: onCloseChartModal, } = useDisclosure();
    return (_jsxs(HStack, Object.assign({ w: '100%', flex: 1, mr: '50px' }, { children: [_jsx(HStack, Object.assign({ position: 'relative', right: '-25px' }, { children: _jsx(Button, { onClick: (e) => {
                        e.stopPropagation();
                        onOpenChartModal();
                    }, rightIcon: _jsx(ViewIcon, {}), variant: variants.blueTransparentBtn, position: 'absolute', top: 0, right: 0, "data-testid": 'condition-chart-modal-open' }) })), _jsx(ChartModal, { isOpen: isChartModal, onClose: onCloseChartModal, graphConfig: graphConfig })] })));
};
