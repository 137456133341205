import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, VStack } from '@chakra-ui/react';
import { MeasureSquare } from '@frontend/design-system/components/MeasureSquare/MeasureSquare';
import { casework, ceiling, flooring, measureColors, } from '../../../config/constants';
import { FacilityMapTypes } from '../../../objects/FCAHelperInterfaces';
import { rgbFromNumber, simpleStringHash } from './colors';
export const Measures = ({ spaces, selectedMeasure, }) => {
    const renderMeasures = () => {
        let measureContent;
        switch (selectedMeasure) {
            case FacilityMapTypes.BUILDING_AGE:
                measureContent = renderBuildingAgeSquares();
                break;
            case FacilityMapTypes.CASEWORK:
                measureContent = renderCaseworkSquares();
                break;
            case FacilityMapTypes.FLOORING:
                measureContent = renderFlooringSquares();
                break;
            case FacilityMapTypes.CEILING:
                measureContent = renderCeilingSquares();
                break;
            case FacilityMapTypes.HUMIDITY:
                measureContent = (_jsxs(_Fragment, { children: [_jsx(MeasureSquare, { color: measureColors.MEASURE_GREEN, text: 'Within Range.' }), _jsx(MeasureSquare, { color: 'white', text: 'Not tested' })] }));
                break;
            case FacilityMapTypes.LIGHT:
                measureContent = renderLightSquares();
                break;
            case FacilityMapTypes.TEMPERATURE:
                measureContent = renderTemperatureSquares();
                break;
            case FacilityMapTypes.SOUND:
                measureContent = renderSoundSquares();
                break;
            case FacilityMapTypes.HVAC:
                measureContent = renderHVACSquares();
                break;
            default:
                measureContent = null;
        }
        return _jsx(VStack, Object.assign({ alignItems: 'start' }, { children: measureContent }));
    };
    const renderLightSquares = () => {
        return (_jsxs(_Fragment, { children: [_jsx(MeasureSquare, { color: measureColors.MEASURE_GREEN, text: 'Within IES Range.' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_YELLOW, text: 'Above IES Range.' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_RED, text: 'Below IES Range.' }), _jsx(MeasureSquare, { color: 'white', text: 'Not tested' })] }));
    };
    const renderTemperatureSquares = () => {
        return (_jsxs(_Fragment, { children: [_jsx(MeasureSquare, { color: measureColors.MEASURE_BLUE, text: 'Less than 68 F.' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_GREEN, text: 'Between 68 and 72 F.' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_YELLOW, text: 'Between 73 and 74 F.' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_ORANGE, text: 'Between 75 and 76 F.' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_RED, text: 'Above 76 F.' }), _jsx(MeasureSquare, { color: 'white', text: 'Not tested' })] }));
    };
    const renderSoundSquares = () => {
        return (_jsxs(_Fragment, { children: [_jsx(MeasureSquare, { color: measureColors.MEASURE_GREEN, text: 'Less than 35 dB(A).' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_YELLOW, text: 'Between 35 and 50 dB(A).' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_RED, text: 'Above 50 dB(A).' }), _jsx(MeasureSquare, { color: 'white', text: 'Not tested' })] }));
    };
    const renderHVACSquares = () => {
        if (!spaces) {
            return _jsx(_Fragment, {});
        }
        // get unique hvac asset combinations for all spaces
        const uniqueHVACs = new Map();
        // TODO include HVAC assets from space.assets that may not be served in this space
        spaces.forEach((space) => {
            const hvacIDs = [];
            const hvacs = [];
            space.facilityAssets
                .filter((asset) => asset.assetClass === 'Air Handling System')
                .sort((a, b) => a.assetId - b.assetId)
                .forEach((asset) => {
                // Include each asset individually as well so pin colors
                // show up in the key
                uniqueHVACs.set(asset.assetId.toString(), [asset]);
                hvacIDs.push(asset.assetId);
                hvacs.push(asset);
            });
            const combinedHVACID = hvacIDs.join(',');
            if (hvacIDs.length > 0 && !uniqueHVACs.has(combinedHVACID)) {
                uniqueHVACs.set(combinedHVACID, hvacs);
            }
        });
        // for each asset combination, render square based on asset ids
        return Array.from(uniqueHVACs.entries()).map(([identity, assets]) => {
            const hvacHash = simpleStringHash(identity) % Math.pow(256, 3);
            const rgb = rgbFromNumber(hvacHash);
            const name = assets.map((asset) => asset.name).join(', ');
            return (_jsx(MeasureSquare, { color: `rgb(${rgb.r},${rgb.g},${rgb.b})`, text: name }, name));
        });
    };
    const renderBuildingAgeSquares = () => {
        if (!spaces) {
            return _jsx(_Fragment, {});
        }
        const constructionYears = new Set(spaces
            .map((space) => space.constrYear)
            .filter((year) => year !== null));
        const uniqueYears = Array.from(new Set(constructionYears)).sort((a, b) => a - b);
        const renderedSquares = uniqueYears.map((year) => {
            const rgb = rgbFromNumber(year);
            return (_jsx(MeasureSquare, { color: `rgb(${rgb.r},${rgb.g},${rgb.b})`, text: year.toString() }, year.toString()));
        });
        return _jsx(_Fragment, { children: renderedSquares });
    };
    const renderAssetSquares = (assetType, assetData) => {
        if (!spaces) {
            return _jsx(_Fragment, {});
        }
        const assetTypes = new Set(spaces
            .map((space) => space.assets)
            .flat()
            .filter((asset) => asset.assetType === assetType)
            .map((asset) => asset.platformAssetType));
        const uniqueAssetTypes = Array.from(assetTypes);
        const renderedSquares = uniqueAssetTypes.map((type) => {
            const normalizedType = type === null || type === void 0 ? void 0 : type.toUpperCase();
            const color = assetData[normalizedType].color;
            const text = assetData[normalizedType].type;
            return _jsx(MeasureSquare, { color: color, text: text }, text);
        });
        return _jsx(_Fragment, { children: renderedSquares });
    };
    const renderCaseworkSquares = () => {
        return renderAssetSquares(FacilityMapTypes.CASEWORK, casework);
    };
    const renderFlooringSquares = () => {
        return renderAssetSquares(FacilityMapTypes.FLOORING, flooring);
    };
    const renderCeilingSquares = () => {
        return renderAssetSquares(FacilityMapTypes.CEILING, ceiling);
    };
    return _jsx(HStack, { children: renderMeasures() });
};
