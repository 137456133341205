import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack } from '@chakra-ui/react';
import { DynamicInput } from '../../../Inputs/DynamicInput';
import { RoomOccupiedInput } from '../../../Inputs/RoomOccupiedInput';
export const CO2InputsHandler = ({ co2IeqInput, renderInfoIcon, data, errorMessage, handleInputChange, editMode, }) => {
    var _a, _b;
    const co2Value = (_a = data === null || data === void 0 ? void 0 : data.co2Value) !== null && _a !== void 0 ? _a : '';
    const occupancyNumber = (_b = data === null || data === void 0 ? void 0 : data.occupancyNumber) !== null && _b !== void 0 ? _b : 0;
    const updateFormValues = ({ co2Value, occupancyNumber, }) => {
        handleInputChange({ co2Value, occupancyNumber });
    };
    return (_jsxs(_Fragment, { children: [_jsx(DynamicInput, { required: co2IeqInput.required, data: co2Value, editMode: editMode, inputType: co2IeqInput.inputType, label: co2IeqInput.label, choices: co2IeqInput.choices, isDisabled: co2IeqInput.isDisabled, errorMessage: errorMessage, handleInputChange: (e) => {
                    updateFormValues({
                        co2Value: e,
                        occupancyNumber: occupancyNumber,
                    });
                }, constraints: co2IeqInput.constraints, color: co2IeqInput.color, infoPopover: renderInfoIcon, placeholder: co2IeqInput.placeholder }), _jsx(HStack, Object.assign({ marginStart: 4 }, { children: _jsx(RoomOccupiedInput, { editMode: editMode, data: occupancyNumber.toString(), handleInputChange: (e) => {
                        const occupants = e ? Number(e) : 0;
                        updateFormValues({
                            co2Value: co2Value,
                            occupancyNumber: occupants,
                        });
                    }, errorMessage: errorMessage }) }))] }));
};
