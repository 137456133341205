var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { db } from '@/config/db/db';
import { BLOB_STORE_CONTAINER_URL } from '../services/fileUpload';
export const toCurrentYYYYMMDDHHMMSSMSMS = () => {
    const date = new Date();
    const dateString = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}-${date.getHours()}${date.getMinutes()}${date.getMilliseconds()}-`;
    return dateString;
};
export const toBlobStoreContainerUrl = (fileName, orgIdentity) => {
    const resultUrl = `${BLOB_STORE_CONTAINER_URL}/${toContainerPath(fileName, orgIdentity, 'compressed')}`;
    return resultUrl;
};
export const toContainerPath = (fileName, 
// eslint-disable-next-line unused-imports/no-unused-vars
orgIdentity, type) => {
    // orgIdentity is not needed until we migrate photos to use org folders
    // when reimplementing, add orgIdentity/ before the type
    // leaving orgIdentity in toContainerPath params because the work is already done to utilize it; leaving it allows the only change needing to be done here
    const containerPath = `${type ? type + '/' : ''}${fileName}`;
    return containerPath;
};
export const toCleanFileName = (fileName) => {
    const splittedFileName = fileName.split('.');
    const extension = splittedFileName.pop();
    const cleanedName = splittedFileName
        .join('.')
        .replace(/[!*'();:@&=+$,/?%#[\].\\]+|\s+/g, '-');
    return cleanedName + '.' + extension;
};
export const getImageUrl = (url) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (navigator.onLine) {
        // PWA DEV TODO: When pushing to Dev, use above; otherwise use below.
        // const azureUrl = `https://mysiteiqstorage.blob.core.windows.net/dev-fca/${url}`;
        return url;
    }
    else {
        try {
            const planObj = yield ((_a = db.plan) === null || _a === void 0 ? void 0 : _a.get({ planFileName: url }));
            const URL = window.URL || window.webkitURL;
            if (planObj === null || planObj === void 0 ? void 0 : planObj.planImage) {
                const blob = new Blob([planObj.planImage], {
                    type: planObj.planImageType,
                });
                return URL.createObjectURL(blob);
            }
            else {
                const planObj = yield db.planImages.get({
                    planFileName: url,
                });
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const objUrl = URL.createObjectURL(planObj.planImage);
                return objUrl;
            }
        }
        catch (error) {
            console.error('Failure to fetch image from offline storage:', error);
        }
    }
});
