import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Circle, Image, SimpleGrid } from '@chakra-ui/react';
import { CarouselLeftIcon } from '@frontend/design-system/theme/icons/CarouselLeftIcon';
import { CarouselRightIcon } from '@frontend/design-system/theme/icons/CarouselRightIcon';
import { useState } from 'react';
import { BiPlay } from 'react-icons/bi';
import { GallerySliderController } from '../../../../Selection/Gallery/GallerySliderController';
export const PhotoModalCarousel = ({ photos }) => {
    const photoObj = photos
        .map((file) => {
        return {
            photoUrl: URL.createObjectURL(file),
            photoFileName: file.name,
        };
    })
        .reverse();
    const [startPoint, setStartPoint] = useState(0);
    const photosPerRow = 1;
    const endReached = Math.max(photoObj.length - 1, 0);
    const showPrev = () => {
        setStartPoint(startPoint - photosPerRow);
    };
    const showNext = () => {
        setStartPoint(startPoint + photosPerRow);
    };
    return (_jsxs(SimpleGrid, Object.assign({ columns: 1, position: 'relative', w: '100%', h: '100%' }, { children: [startPoint > 0 && (_jsx(GallerySliderController, { direction: 'left', handleSlide: showPrev, showShadow: false, leftIcon: _jsx(CarouselLeftIcon, { boxSize: '40px' }) })), photoObj
                .slice(startPoint, startPoint + photosPerRow)
                .map((item, index) => {
                const isVideo = ['.mov', '.mp4'].includes(item.photoFileName.slice(-4).toLowerCase());
                if (isVideo) {
                    return (_jsxs(Box, Object.assign({ position: 'relative' }, { children: [_jsxs("video", Object.assign({ "data-testid": 'video-player', id: 'video-player', style: {
                                    margin: 'auto',
                                    objectFit: 'contain',
                                    maxHeight: '70vh',
                                }, preload: 'auto', "data-setup": '{responsive: true}' }, { children: [_jsx("source", { src: item.photoUrl, type: 'video/mp4' }), _jsx("p", { children: "To view this video, please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video" })] })), _jsx(Circle, Object.assign({ size: '80px', position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%', bg: '#00000080', "aria-label": 'favorite photo' }, { children: _jsx(BiPlay, { size: '40px', color: '#FBDABD', style: {
                                        transform: 'translate(3px,0px)',
                                    } }) }))] }), `video-${index}`));
                }
                else {
                    return (_jsx(Box, { children: _jsx(Image, { src: item.photoUrl, alt: item.photoFileName, objectFit: 'contain', margin: 'auto', maxH: '70vh', borderRadius: '5px' }, `photo-${index}`) }));
                }
            }), startPoint < endReached && (_jsx(GallerySliderController, { direction: 'right', handleSlide: showNext, showShadow: false, rightIcon: _jsx(CarouselRightIcon, { boxSize: '40px' }) }))] })));
};
