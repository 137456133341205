import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { usePhotoContext } from '@/contexts/Photo/PhotoContext';
import { useDisclosure } from '@chakra-ui/react';
import { PhotoType, SetUpPhotoModal } from '../AddPhotoModal/SetUpPhotoModal';
import PhotoCarousel from './PhotoCarousel';
import PhotoGalleryModal from './PhotoGalleryModal';
import { PhotoGalleryModalRow } from './PhotoGalleryModalRow';
export const PinnedAssetPhotos = ({ spaceId, asset, }) => {
    const { currentPhotos } = usePhotoContext();
    const { isOpen, onClose, onOpen } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsx(PhotoCarousel, { openGallery: onOpen, photos: currentPhotos }), _jsx(PhotoGalleryModal, Object.assign({ name: asset.name, isOpen: isOpen, onClose: onClose }, { children: _jsx(PhotoGalleryModalRow, { title: asset.name, photos: currentPhotos, canAddPhoto: true, renderAddPhotoModal: (isOpen, onClose) => {
                        return (_jsx(SetUpPhotoModal, { photoType: PhotoType.PINNED_ASSET, isOpen: isOpen, onClose: onClose, spaceIdentity: spaceId, assetIdentity: asset.identity, assetName: asset.name }));
                    } }) }))] }));
};
