import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Collapse, Stack, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { variants } from '../../../../config/theme';
import { PageModes } from '../../../../objects/FCAInterfaces';
export const AddNewButton = () => {
    const [expanded, setExpanded] = useState(false);
    const { selectedSpaceIdentity, setPageMode } = useFacilityContext();
    return (_jsxs(VStack, Object.assign({ w: '100%', justifyContent: 'center', spacing: 0 }, { children: [_jsx(Collapse, Object.assign({ in: expanded, style: { width: '45%' } }, { children: _jsxs(Stack, Object.assign({ spacing: 0 }, { children: [_jsx(Button, Object.assign({ onClick: () => {
                                setExpanded(false);
                                //From provider
                                setPageMode(PageModes.ADD_SPACE);
                            }, borderBottomRadius: 0, "data-testid": 'add_space_button' }, { children: "Space" })), _jsx(Button, Object.assign({ onClick: () => {
                                setExpanded(false);
                                // from provider
                                setPageMode(PageModes.ADD_ASSET);
                            }, isDisabled: !selectedSpaceIdentity, borderRadius: 0 }, { children: "Asset" }))] })) })), _jsx(Button, Object.assign({ onClick: () => setExpanded(!expanded), leftIcon: _jsx(AddIcon, {}), w: '60%', variant: variants.blueBtn, "data-testid": 'add_new_button' }, { children: "Add New" }))] })));
};
