import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useFacilityContext } from '../../../../../contexts/Facilities/FacilityPageContext';
import { useSpaceContext } from '../../../../../contexts/Spaces/SpaceContext';
import { FacilityMapTypes } from '../../../../../objects/FCAHelperInterfaces';
import { PageModes, } from '../../../../../objects/FCAInterfaces';
import { DynamicFormInput, } from '../../Inputs/DynamicFormInput';
import { ChartType } from '../Timeseries/TimeseriesButtons';
import { CO2FormWrapper } from './CO2Metric/CO2FormWrapper';
export const SpaceIEQ = ({ space, isEditing, canEdit, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { setPageMode } = useFacilityContext();
    const { reassessSpaceIEQ } = useSpaceContext();
    const { control } = useFormContext();
    const getMetricYears = (metric) => {
        const years = metric && metric.allValues
            ? metric.allValues.map((d) => {
                return new Date(d.measuredOn);
            })
            : [];
        return years;
    };
    const getMetricValues = (metric) => {
        const values = metric && metric.allValues
            ? metric.allValues.map((d) => {
                return d.value;
            })
            : [];
        return values;
    };
    const ieqMetrics = [
        {
            type: FacilityMapTypes.CO2,
            values: getMetricValues(space === null || space === void 0 ? void 0 : space.co2),
            years: getMetricYears(space === null || space === void 0 ? void 0 : space.co2),
        },
        {
            type: FacilityMapTypes.TEMPERATURE,
            values: getMetricValues(space === null || space === void 0 ? void 0 : space.temperature),
            years: getMetricYears(space === null || space === void 0 ? void 0 : space.temperature),
        },
    ];
    const co2IeqInput = {
        name: 'cO2',
        inputType: 'number',
        label: 'CO\u2082 (ppm)',
        constraints: { precision: 0 },
        color: (_a = space === null || space === void 0 ? void 0 : space.co2) === null || _a === void 0 ? void 0 : _a.rating,
        timeseriesConfig: {
            reassess: {
                modalLabel: 'CO\u2082 (ppm)',
                isReassessable: !!((_b = space === null || space === void 0 ? void 0 : space.co2) === null || _b === void 0 ? void 0 : _b.value),
                reassessCall: reassessSpaceIEQ,
            },
            graphConfig: {
                chartData: {
                    data: {
                        datasets: ieqMetrics,
                    },
                    title: FacilityMapTypes.CO2,
                },
                type: ChartType.multiple,
            },
        },
    };
    return (_jsxs(Stack, Object.assign({ onClick: () => {
            if (canEdit && !isEditing) {
                setPageMode(PageModes.EDIT);
            }
        }, spacing: 4, px: '10px', "data-testid": 'space-ieq-form' }, { children: [_jsx(DynamicFormInput, { inputData: {
                    name: 'light',
                    inputType: 'number',
                    label: 'Light (fc)',
                    constraints: { precision: 0 },
                    color: (_c = space === null || space === void 0 ? void 0 : space.light) === null || _c === void 0 ? void 0 : _c.rating,
                    timeseriesConfig: {
                        reassess: {
                            modalLabel: 'Light (fc)',
                            isReassessable: !!((_d = space === null || space === void 0 ? void 0 : space.light) === null || _d === void 0 ? void 0 : _d.value),
                            reassessCall: reassessSpaceIEQ,
                        },
                        graphConfig: {
                            chartData: {
                                data: {
                                    datasets: [],
                                },
                                title: '',
                            },
                            type: ChartType.multiple,
                        },
                    },
                }, isEditing: isEditing, control: control, canEdit: canEdit }, 'dynamic_input_light'), _jsx(DynamicFormInput, { inputData: {
                    name: 'sound',
                    inputType: 'number',
                    label: 'Sound (dbA)',
                    color: (_e = space === null || space === void 0 ? void 0 : space.sound) === null || _e === void 0 ? void 0 : _e.rating,
                    timeseriesConfig: {
                        reassess: {
                            modalLabel: 'Sound (dbA)',
                            isReassessable: !!((_f = space === null || space === void 0 ? void 0 : space.sound) === null || _f === void 0 ? void 0 : _f.value),
                            reassessCall: reassessSpaceIEQ,
                        },
                        graphConfig: {
                            chartData: {
                                data: {
                                    datasets: [],
                                },
                                title: '',
                            },
                            type: ChartType.multiple,
                        },
                    },
                }, isEditing: isEditing, control: control, canEdit: canEdit }, 'dynamic_input_sound'), _jsx(CO2FormWrapper, { control: control, co2IeqInput: co2IeqInput, isEditing: isEditing, canEdit: canEdit }), _jsx(DynamicFormInput, { inputData: {
                    name: 'temperature',
                    inputType: 'number',
                    label: 'Temperature (F)',
                    constraints: { precision: 1 },
                    color: (_g = space === null || space === void 0 ? void 0 : space.temperature) === null || _g === void 0 ? void 0 : _g.rating,
                    timeseriesConfig: {
                        reassess: {
                            modalLabel: 'Temperature (F)',
                            isReassessable: !!((_h = space === null || space === void 0 ? void 0 : space.temperature) === null || _h === void 0 ? void 0 : _h.value),
                            reassessCall: reassessSpaceIEQ,
                        },
                        graphConfig: {
                            chartData: {
                                data: {
                                    datasets: ieqMetrics,
                                },
                                title: FacilityMapTypes.TEMPERATURE,
                            },
                            type: ChartType.multiple,
                        },
                    },
                }, isEditing: isEditing, control: control, canEdit: canEdit }, 'dynamic_input_temperature'), _jsx(DynamicFormInput, { inputData: {
                    name: 'humidity',
                    inputType: 'number',
                    label: 'Humidity',
                    constraints: { precision: 0 },
                    color: (_j = space === null || space === void 0 ? void 0 : space.humidity) === null || _j === void 0 ? void 0 : _j.rating,
                    timeseriesConfig: {
                        reassess: {
                            modalLabel: 'Humidity',
                            isReassessable: !!((_k = space === null || space === void 0 ? void 0 : space.humidity) === null || _k === void 0 ? void 0 : _k.value),
                            reassessCall: reassessSpaceIEQ,
                        },
                        graphConfig: {
                            chartData: {
                                data: {
                                    datasets: [],
                                },
                                title: '',
                            },
                            type: ChartType.multiple,
                        },
                    },
                }, isEditing: isEditing, control: control, canEdit: canEdit }, 'dynamic_input_humidity')] })));
};
