import { Apps, OrganizationRole } from '../objects/FCAInterfaces';
import { SystemRole } from '../objects/UserData';
export const hasAccess = (user, currentOrg) => {
    var _a, _b;
    if (!user || user.id === 'null') {
        return false;
    }
    const apps = user.apps;
    if (!currentOrg) {
        return true;
    }
    const userCurrentOrgRole = (_a = user.organizationRoles.find((orgRole) => (currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.id) === orgRole.organizationId)) === null || _a === void 0 ? void 0 : _a.role;
    if (SystemRole.SUPER_ADMIN === user.systemRole ||
        SystemRole.ORG_APPROVER === user.systemRole ||
        OrganizationRole.LOCAL_ADMIN === userCurrentOrgRole ||
        OrganizationRole.CLIENT_CONCIERGE === userCurrentOrgRole) {
        return !!((_b = currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.apps) === null || _b === void 0 ? void 0 : _b.find((orgApp) => Apps.FCA === orgApp.app));
    }
    return !!(apps === null || apps === void 0 ? void 0 : apps.find((app) => Apps.FCA === app.app && currentOrg.id === app.organizationId));
};
