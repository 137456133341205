var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useCopyPasteContext } from '@/contexts/CopyPaste/CopyPasteContext';
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { useEffect, useState } from 'react';
import { CopySpaceModal } from './CopySpaceModal';
import { SelectSpacesModal } from './SelectSpacesModal';
import { toBlobStoreContainerUrl, getImageUrl } from '@/utils/photoFileUtils';
import { useFloorplanCanvasShape } from '@/pages/Main/Canvas/useFloorplanCanvasShapes';
export const CopySpace = ({ spaceToCopy, isOpen, onClose, plans, }) => {
    const { facilitySpaces, clearSelectedSpaces, clearPropertiesToCopy, pasteSpaceToSelectedSpaces, toggleSelectedSpace, selectedSpaceIdentities, } = useCopyPasteContext();
    const { selectedPlan } = useFacilityContext();
    const { getViewOnlyShape } = useFloorplanCanvasShape();
    const [modalStep, setModalStep] = useState(1);
    const [imageString, setImageString] = useState('');
    const [plan, setPlan] = useState(selectedPlan);
    useEffect(() => {
        const loadImageUrl = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const url = yield getImageUrl(toBlobStoreContainerUrl((_a = plan === null || plan === void 0 ? void 0 : plan.planFileName) !== null && _a !== void 0 ? _a : ''));
            url ? setImageString(url) : console.log('Failed');
        });
        isOpen && loadImageUrl();
        planUpdateHandler(plan);
    }, [plan, isOpen]);
    const submitSpaceCopy = () => __awaiter(void 0, void 0, void 0, function* () {
        pasteSpaceToSelectedSpaces(spaceToCopy.identity);
        setPlan(selectedPlan);
        onCloseHandler();
    });
    const handleSelectSpacesClick = () => {
        setModalStep(2);
    };
    const handleBackClick = () => {
        setModalStep(1);
    };
    const onCloseHandler = () => {
        clearPropertiesToCopy();
        clearSelectedSpaces();
        setPlan(selectedPlan);
        setModalStep(1);
        onClose();
    };
    const isSpaceSelected = (space) => {
        return !!selectedSpaceIdentities.find((id) => space.identity === id);
    };
    const getShapes = (spaces) => {
        return spaces
            .filter((space) => spaceToCopy.category === space.category)
            .map((space) => getViewOnlyShape(space, isSpaceSelected));
    };
    const renderSpacePropertiesToCopy = () => {
        return (_jsx(CopySpaceModal, { isOpen: isOpen, onClose: onCloseHandler, handleClose: onCloseHandler, handleSelectSpaces: handleSelectSpacesClick }));
    };
    const planUpdateHandler = (plan) => {
        plan && setPlan(plan);
    };
    const currentSpaces = facilitySpaces.filter((space) => space.floorplanIdentity === (plan === null || plan === void 0 ? void 0 : plan.identity));
    const renderSpacesToCopyTo = () => {
        return (_jsx(SelectSpacesModal, { floorPlanImage: imageString, handleSubmit: submitSpaceCopy, isOpen: isOpen, onClose: onCloseHandler, onClear: clearSelectedSpaces, handleBackOrCancel: { label: 'Back', handler: handleBackClick }, onSelect: (space) => {
                toggleSelectedSpace(space.identity);
            }, shapes: getShapes(currentSpaces), spaces: currentSpaces, selectedSpaces: selectedSpaceIdentities, plans: plans, selectedPlan: plan, onPlanSelect: setPlan }));
    };
    return (_jsx(_Fragment, { children: modalStep === 1
            ? renderSpacePropertiesToCopy()
            : renderSpacesToCopyTo() }));
};
