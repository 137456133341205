export var FacilityMapTypes;
(function (FacilityMapTypes) {
    FacilityMapTypes["BUILDING_AGE"] = "Building Age";
    FacilityMapTypes["CASEWORK"] = "Casework";
    FacilityMapTypes["CEILING"] = "Ceiling";
    FacilityMapTypes["CO2"] = "CO2";
    FacilityMapTypes["FLOORING"] = "Flooring";
    FacilityMapTypes["HUMIDITY"] = "Humidity";
    FacilityMapTypes["HVAC"] = "HVAC";
    FacilityMapTypes["LIGHT"] = "Light";
    FacilityMapTypes["SOUND"] = "Sound";
    FacilityMapTypes["TEMPERATURE"] = "Temperature";
})(FacilityMapTypes || (FacilityMapTypes = {}));
