import { rest } from 'msw';
import { makePlan, mockFetchClientSpacesPlanData } from './testObjects';
export const handlers = [
    rest.get('*/features/*', (req, res, ctx) => {
        const response = true;
        return res(ctx.json(response));
    }),
    rest.get('*/:fidentity/plans', (req, res, ctx) => {
        const plans = [
            makePlan(),
            makePlan({ id: 'plan2', identity: 2, name: 'testPlan 2' }),
        ];
        return res(ctx.json(plans));
    }),
    rest.get('*/plans/:planId', (req, res, ctx) => {
        return res(ctx.json(mockFetchClientSpacesPlanData));
    }),
    rest.get('*/assets/facilityWide/*', (req, res, ctx) => {
        return res(ctx.json([
            makePlan(),
            makePlan({ id: 'plan2', identity: 2, name: 'testPlan 2' }),
        ]));
    }),
    rest.get('*/phototags/*', (req, res, ctx) => {
        return res(ctx.json({
            tagIdentity: 1,
            name: 'Photo tag 1',
            color: '#F6862A',
        }));
    }),
    rest.get('*/photos/*/organizationPhotos', (req, res, ctx) => {
        return res(ctx.json([]));
    }),
];
