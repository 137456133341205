export const conditionArray = [
    '1 (Excellent)',
    '2 (Acceptable)',
    '3 (Caution)',
    '4 (Alert)',
    '5 (Alarm)',
];
export const conditions = [
    {
        identity: 1,
        name: '1 (Excellent)',
    },
    {
        identity: 2,
        name: '2 (Acceptable)',
    },
    {
        identity: 3,
        name: '3 (Caution)',
    },
    {
        identity: 4,
        name: '4 (Alert)',
    },
    {
        identity: 5,
        name: '5 (Alarm)',
    },
];
