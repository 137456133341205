import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Center, FormLabel, HStack, SimpleGrid, Stack, Switch, } from '@chakra-ui/react';
import { measureColors } from '../../../config/constants';
import { MeasureSquare } from '@frontend/design-system/components/MeasureSquare/MeasureSquare';
export const ConditionKey = ({ width, showAgeAdjustment, toggleShowAgeAdjustment, }) => {
    return (_jsx(_Fragment, { children: _jsx(HStack, Object.assign({ bg: 'white', pt: '10px', pb: '10px', minH: '85px', w: width, borderBottom: '1px solid black', position: 'absolute' }, { children: _jsx(Center, Object.assign({ w: '100%' }, { children: _jsxs(SimpleGrid, Object.assign({ columns: {
                        sm: 2,
                        lg: 6,
                    } }, { children: [_jsxs(Stack, Object.assign({ pl: '10px', spacing: '-5px', align: 'flex-start', minW: '150px' }, { children: [_jsxs(FormLabel, Object.assign({ htmlFor: 'ageAdjustedSwitch', "data-testid": 'aac-switch-label', fontFamily: 'mulish', fontWeight: 'normal' }, { children: ["Age Adjusted Condition:", ' '] })), _jsx(Switch, { id: 'ageAdjustedSwitch', defaultChecked: showAgeAdjustment, onChange: toggleShowAgeAdjustment, colorScheme: 'blue', pl: '20px', size: 'md' })] })), _jsx(MeasureSquare, { color: measureColors.MEASURE_GREEN, text: 'Excellent' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_GRELLO, text: 'Acceptable' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_YELLOW, text: 'Caution' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_ORANGE, text: 'Alert' }), _jsx(MeasureSquare, { color: measureColors.MEASURE_RED, text: 'Alarm' })] })) })) })) }));
};
