import { StandardAssetTypes } from '../objects/FCAInterfaces';
export const toAssetString = (assetType) => {
    if (assetType === StandardAssetTypes.interiorDoors) {
        return 'Interior Doors';
    }
    else if (assetType === StandardAssetTypes.exteriorDoors) {
        return 'Exterior Doors';
    }
    else {
        return `${assetType[0]}${assetType
            .slice(1, assetType.length)
            .toLocaleLowerCase()}`;
    }
};
