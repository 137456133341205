import { convertAssetToAssetForm } from '../conversions/AssetConversions';
import { classHasMultipleAssets } from '../pages/Main/Sidebar/DetailsSidebar/Space/StandardAssetsSidebar/StandardAssets';
export const preprocessMultiAssets = (modifiedAssets, existingAssets, assetTemplates) => {
    const swapAssetNames = (secondAsset, className) => {
        if (secondAsset) {
            secondAsset.name = `${className} 1`;
        }
    };
    const hasAssetsOfClass = (className) => {
        return modifiedAssets.some((asset) => asset.name.includes(className));
    };
    for (const className of classHasMultipleAssets) {
        if (hasAssetsOfClass(className)) {
            const firstExistingItem = existingAssets.find((asset) => asset.name === `${className} 1`);
            const firstModifiedItem = modifiedAssets.find((asset) => asset.name === `${className} 1`);
            const secondModifiedItem = modifiedAssets.find((asset) => asset.name === `${className} 2`);
            if (secondModifiedItem &&
                !firstExistingItem &&
                !firstModifiedItem) {
                swapAssetNames(secondModifiedItem, className);
            }
        }
        if (className === 'Flooring') {
            const assetTemplate = assetTemplates.find((template) => template.assetClass === 'Flooring');
            preprocessFlooring(modifiedAssets, existingAssets, assetTemplate);
        }
    }
    return modifiedAssets;
};
const preprocessFlooring = (modifiedAssets, existingAssets, assetTemplate) => {
    const findAssetByName = (name) => modifiedAssets.find((asset) => asset.name === name);
    const findQuestionByName = (asset, name) => { var _a; return (_a = asset.questions) === null || _a === void 0 ? void 0 : _a.find((question) => question.name === name); };
    const firstFlooringExisting = existingAssets.find((asset) => asset.name === 'Flooring 1');
    const firstFlooringModifiedInitially = findAssetByName('Flooring 1');
    const secondFlooringModified = findAssetByName('Flooring 2');
    if (firstFlooringModifiedInitially && !secondFlooringModified) {
        const firstQuestion = findQuestionByName(firstFlooringModifiedInitially, 'AreaPercentage');
        if (firstQuestion) {
            firstQuestion.value = 100;
        }
    }
    else if ((firstFlooringExisting || firstFlooringModifiedInitially) &&
        secondFlooringModified) {
        const secondQuestion = findQuestionByName(secondFlooringModified, 'AreaPercentage');
        if (secondQuestion) {
            const calculateAreaPercentageValue = 100 - Number(secondQuestion.value);
            if (!firstFlooringModifiedInitially) {
                const convertedFirstAsset = convertAssetToAssetForm(firstFlooringExisting, firstFlooringExisting === null || firstFlooringExisting === void 0 ? void 0 : firstFlooringExisting.name, assetTemplate);
                modifiedAssets.push(convertedFirstAsset);
            }
            const firstFlooringModified = findAssetByName('Flooring 1');
            const firstQuestion = firstFlooringModified &&
                findQuestionByName(firstFlooringModified, 'AreaPercentage');
            if (firstQuestion) {
                firstQuestion.value = calculateAreaPercentageValue;
            }
        }
    }
};
