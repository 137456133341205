import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, IconButton } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
export const GallerySliderController = ({ direction, handleSlide, showShadow = true, leftIcon = _jsx(ChevronLeftIcon, { boxSize: { sm: '40px', '2xl': '50px' } }), rightIcon = _jsx(ChevronRightIcon, { boxSize: { sm: '40px', '2xl': '50px' } }), }) => {
    const isLeft = direction === 'left';
    return (_jsx(_Fragment, { children: showShadow ? (_jsx(Box, Object.assign({ style: {
                position: 'absolute',
                zIndex: '1',
                background: isLeft
                    ? 'linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))'
                    : 'linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))',
                width: '100px',
                height: '100%',
                left: isLeft ? 0 : 'auto',
                right: isLeft ? 'auto' : 0,
                borderTopLeftRadius: isLeft ? '5px' : '0px',
                borderBottomLeftRadius: isLeft ? '5px' : '0px',
                borderTopRightRadius: isLeft ? '0px' : '5px',
                borderBottomRightRadius: isLeft ? '0px' : '5px',
            } }, { children: _jsx(IconButton, { onClick: handleSlide, icon: isLeft ? leftIcon : rightIcon, "aria-label": isLeft ? 'previous' : 'next', position: 'absolute', left: isLeft ? 0 : 'auto', right: isLeft ? 'auto' : 0, top: '50%', transform: 'translateY(-50%)', color: '#FFFFFF', boxSizing: 'border-box', boxSize: '50px', variant: variants.blueTransparentBtn }) }))) : (_jsx(IconButton, { onClick: handleSlide, icon: isLeft ? leftIcon : rightIcon, "aria-label": isLeft ? 'previous' : 'next', position: 'absolute', left: isLeft ? 0 : 'auto', right: isLeft ? 'auto' : 0, top: '50%', transform: 'translateY(-50%)', color: '#FFFFFF', boxSizing: 'border-box', p: '10px', variant: variants.blueTransparentBtn, zIndex: '1' })) }));
};
