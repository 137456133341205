var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useToast } from '@chakra-ui/react';
import { createPhotos } from '../services/api/photoAPI';
import { uploadFileToBlob } from '../services/fileUpload';
import { toCleanFileName, toContainerPath, toCurrentYYYYMMDDHHMMSSMSMS, } from '../utils/photoFileUtils';
import { useImageHandler } from './imageHandler';
export const usePhotoServiceHook = () => {
    const { compressImage } = useImageHandler();
    const toast = useToast();
    const uploadPhoto = (photoFile, orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        // store raw photo first
        const fileName = photoFile.appendString + toCleanFileName(photoFile.file.name);
        const rawContainerPath = toContainerPath(fileName, orgIdentity);
        // we aren't awaiting this, because the client does not want the slow upload from the raw photos
        uploadFileToBlob(photoFile.file, rawContainerPath);
        const compressedFile = yield compressImage(photoFile.file);
        // only store thumbnail if it could be compressed
        if (compressedFile) {
            const compressedContainerPath = toContainerPath(fileName, orgIdentity, 'compressed');
            yield uploadFileToBlob(compressedFile, compressedContainerPath);
        }
        else {
            throw new Error(fileName);
        }
    });
    const photoFileActions = (createPhotoIdentities, { fileList, name, selectedDate, selectedTags }) => __awaiter(void 0, void 0, void 0, function* () {
        const photoFiles = yield photoUploadDownload(fileList, createPhotoIdentities.organizationIdentity);
        try {
            yield createPhotos(photoFiles, createPhotoIdentities, selectedTags, selectedDate);
            toast({
                title: `${name} updated`,
                status: 'success',
                isClosable: true,
            });
        }
        catch (error) {
            console.error('Failed to add photos. ', error);
            toast({
                description: `${name} update failed. Please try again.`,
                status: 'error',
                isClosable: true,
            });
        }
    });
    const photoUploadDownload = (fileList, orgIdentity, noAppend) => __awaiter(void 0, void 0, void 0, function* () {
        toast({
            title: 'Upload beginning; feel free to continue using the FCA tool.',
            status: 'success',
            isClosable: true,
        });
        const photoArray = fileList || [];
        const photoFiles = photoArray.map((photo) => {
            const dateTimeString = noAppend
                ? ''
                : toCurrentYYYYMMDDHHMMSSMSMS();
            return { appendString: dateTimeString, file: photo };
        });
        const uploadedFiles = [];
        for (const [i, photo] of photoFiles.entries()) {
            try {
                yield uploadPhoto(photo, orgIdentity);
                uploadedFiles.push(photo);
                toast({
                    title: `Photo ${i + 1} of ${photoFiles.length} uploaded.`,
                    status: 'success',
                    isClosable: true,
                });
            }
            catch ({ name, message }) {
                console.error('Error uploading photos:', message);
                toast({
                    title: `Error uploading photo ${i + 1} of ${photoFiles.length}; please try again.`,
                    description: `Photo: ${message}`,
                    status: 'error',
                    duration: null,
                    isClosable: true,
                });
            }
        }
        return uploadedFiles;
    });
    // PWA related; allows for downloading photo to user device so they have photo with correct file name to upload to azure
    /* const photoDownload = (photo: File, appendString?: string): void => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(photo);
        a.download = appendString + photo.name;
        a.click();
    }; */
    return {
        uploadPhoto,
        photoUploadDownload,
        photoFileActions,
    };
};
