import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { variants } from '../../../../../config/theme';
import { useAppSelector } from '../../../../../store/hooks';
import { selectOrganization } from '../../../../../store/orgSlice';
import { toBlobStoreContainerUrl } from '../../../../../utils/photoFileUtils';
const PhotoCarousel = ({ openGallery, photos, }) => {
    const org = useAppSelector(selectOrganization);
    const [currentPhoto, setCurrentPhoto] = useState();
    const [carouselPhotos, setCarouselPhotos] = useState([]);
    useEffect(() => {
        let carouselPhotos = photos
            .filter((photo) => photo.name.slice(-4, photo.name.length).toLowerCase() !==
            '.mov' &&
            photo.name.slice(-4, photo.name.length).toLowerCase() !==
                '.mp4')
            .slice();
        const favoritePhotos = carouselPhotos.filter((photo) => photo.favorite);
        const nonFavoritePhotos = carouselPhotos.filter((photo) => !photo.favorite);
        carouselPhotos = [...favoritePhotos, ...nonFavoritePhotos];
        if (carouselPhotos.length > 10) {
            carouselPhotos = carouselPhotos.slice(0, 10);
        }
        if (carouselPhotos.length > 0) {
            setCarouselPhotos(carouselPhotos);
            setCurrentPhoto(carouselPhotos[0]);
        }
        else {
            setCarouselPhotos([]);
            setCurrentPhoto(undefined);
        }
    }, [photos]);
    const previousPhoto = () => {
        if (currentPhoto) {
            if (carouselPhotos.indexOf(currentPhoto) === 0) {
                setCurrentPhoto(carouselPhotos[carouselPhotos.length - 1]);
            }
            else {
                setCurrentPhoto(carouselPhotos[carouselPhotos.indexOf(currentPhoto) - 1]);
            }
        }
    };
    const nextPhoto = () => {
        if (currentPhoto) {
            if (carouselPhotos.length - 1 ===
                carouselPhotos.indexOf(currentPhoto)) {
                setCurrentPhoto(carouselPhotos[0]);
            }
            else {
                setCurrentPhoto(carouselPhotos[carouselPhotos.indexOf(currentPhoto) + 1]);
            }
        }
    };
    return (_jsx(_Fragment, { children: carouselPhotos.length > 0 && currentPhoto && navigator.onLine ? (_jsx(HStack, Object.assign({ bgImage: `${toBlobStoreContainerUrl(currentPhoto.name, org === null || org === void 0 ? void 0 : org.orgIdentity)}`, bgSize: 'cover', bgPosition: 'center', w: '100%', h: '220px', mb: '5px' }, { children: carouselPhotos.length > 1 ? (_jsxs(Grid, Object.assign({ templateColumns: 'repeat(3, 1fr)', gap: 0 }, { children: [_jsx(Grid, Object.assign({ justifyContent: 'center' }, { children: _jsx(GridItem, Object.assign({ paddingTop: '85px', w: '100%', minH: '220px', onClick: previousPhoto }, { children: _jsx(IoIosArrowBack, { fontSize: '40px', color: 'white' }) })) })), _jsx(GridItem, { minW: '125px', minH: '220px', "data-testid": 'current-carousel-photo', onClick: openGallery }), _jsx(Grid, Object.assign({ justifyContent: 'center' }, { children: _jsx(GridItem, Object.assign({ paddingTop: '85px', w: '100%', minH: '220px', onClick: nextPhoto }, { children: _jsx(IoIosArrowForward, { fontSize: '40px', color: 'white' }) })) }))] }))) : (_jsx(VStack, { "data-testid": 'current-carousel-photo', minW: '209px', h: 'inherit', onClick: openGallery })) }))) : (_jsx(Button, Object.assign({ w: '100%', mb: '15px', fontSize: '14px', variant: variants.whiteBtn, onClick: openGallery }, { children: "See photo gallery" }))) }));
};
export default PhotoCarousel;
