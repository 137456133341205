import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { slgqColors } from '../../../config/theme';
import { usePhotoContext } from '../../../contexts/Photo/PhotoContext';
import { PhotoViewer } from '../../Main/Sidebar/DetailsSidebar/PhotoGalleryModal/PhotoViewer';
export const ExpandedPhotoModal = ({ isExpandedModalOpen, onCloseExpandedModal, }) => {
    const [panoramicSelected, setPanoramicSelected] = useState(false);
    const { removeSinglePhoto } = usePhotoContext();
    const handleModalClose = () => {
        setPanoramicSelected(false);
        onCloseExpandedModal();
        removeSinglePhoto();
    };
    const onImageLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;
        const ratio = offsetWidth / offsetHeight;
        // if wide ratio set panaramic photo
        setPanoramicSelected(Math.round(ratio * 100) / 100 === 2);
    };
    return (_jsxs(Modal, Object.assign({ isOpen: isExpandedModalOpen, onClose: handleModalClose, size: 'xl', scrollBehavior: 'inside', isCentered: true }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.7)' }), _jsxs(ModalContent, Object.assign({ p: '37px', pt: 0, bg: slgqColors.primWhite, maxW: '90vw' }, { children: [_jsx(ModalHeader, { children: _jsx(IconButton, { bg: '#faf9f8', onClick: () => {
                                handleModalClose();
                            }, icon: _jsx(AiOutlineArrowLeft, {}), "aria-label": 'return to gallery' }) }), _jsx(ModalCloseButton, { onClick: () => {
                            handleModalClose();
                        } }), _jsx(ModalBody, Object.assign({ pb: '40px', pt: '0px', overflow: 'auto' }, { children: _jsx(PhotoViewer, { panoramicSelected: panoramicSelected, onImageLoad: onImageLoad, onClosePhotoViewer: onCloseExpandedModal }) }))] }))] })));
};
