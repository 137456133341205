import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
const clearSessionOnLogout = () => {
    sessionStorage.clear();
};
export const Logout = () => {
    const { logout } = useAuth0();
    useEffect(() => {
        clearSessionOnLogout();
        logout({
            logoutParams: {
                federated: true,
                returnTo: window.location.origin,
            },
        });
    }, []);
    return _jsx(_Fragment, { children: "Logging out..." });
};
