import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, useDisclosure, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useFacilityContext } from '../../../../../contexts/Facilities/FacilityPageContext';
import { useTemplateContext } from '../../../../../contexts/Template/TemplateContext';
import { PageModes, SpaceCategoryEnum, } from '../../../../../objects/FCAInterfaces';
import { DynamicFormInput, } from '../../Inputs/DynamicFormInput';
import { AddPhotoBtn } from '../AddPhotoModal/AddPhotoBtn';
import { PhotoType } from '../AddPhotoModal/SetUpPhotoModal';
import SpacePhotoCarousel from '../PhotoGalleryModal/SpacePhotoCarousel';
import { constrYearConstraint, validateYearRange, } from '../utility/Validations';
import { WarningSpaceDefinition } from './WarningSpaceDefinition';
export const SpaceDetails = ({ currentSpace, spaceType, spaceDefinition, canEdit, isEditing, }) => {
    const { control, setValue, resetField } = useFormContext();
    const { assetTemplates, spaceTemplate, updateSpaceDefinition, getRoomFunctionsBySpaceType, } = useTemplateContext();
    const { pageMode, setPageMode } = useFacilityContext();
    /* Handle Confirm Space Definition Change Warning  */
    const warningDisclosure = useDisclosure();
    const cancelSpaceDefinitionChange = (definition) => {
        if (definition === SpaceCategoryEnum.FACILITY) {
            setValue('definition', SpaceCategoryEnum.GROUNDS);
        }
        else {
            setValue('definition', SpaceCategoryEnum.FACILITY);
        }
    };
    const continueSpaceDefinitionChange = (definition) => {
        setValue('definition', definition);
        // clear fields dependent on definition
        setValue('type', '');
        setValue('roomFunction', '');
        // if GROUNDS clear IEQ
        if (definition === SpaceCategoryEnum.GROUNDS) {
            resetField('light');
            resetField('sound');
            resetField('cO2');
            resetField('temperature');
            resetField('humidity');
        }
        updateSpaceDefinition(definition);
    };
    const spaceDetailInputs = [
        {
            name: 'name',
            inputType: 'text',
            label: 'Space ID',
            constraints: {
                maxLength: 30,
            },
        },
        {
            name: 'definition',
            inputType: 'radio',
            label: 'Space Definition',
            choices: Object.values(SpaceCategoryEnum),
            isDisabled: pageMode === PageModes.EDIT,
            onChange: () => {
                warningDisclosure.onOpen();
            },
        },
        {
            name: 'type',
            inputType: 'dropdown',
            label: 'Space Type',
            choices: spaceTemplate.map((template) => template.spaceType),
        },
        {
            name: 'roomFunction',
            inputType: 'dropdown',
            label: 'Room Function',
            choices: getRoomFunctionsBySpaceType(spaceType),
        },
        {
            name: 'spaceArea',
            inputType: 'number',
            label: 'Space Area (ft\u00B2)',
            constraints: {
                precision: 2,
                min: 0,
            },
        },
        {
            name: 'constructionYear',
            inputType: 'number',
            label: 'Construction Year',
            validation: (year) => {
                const min = 1700;
                const max = new Date().getFullYear() + 1;
                return validateYearRange(year, min, max);
            },
            errorMessage: 'Please enter a year between 1700 and 1 year in the future',
            constraints: constrYearConstraint,
        },
        {
            name: 'description',
            inputType: 'textarea',
            label: 'Description',
            placeholder: 'Describe this room here in this space',
        },
        {
            name: 'note',
            inputType: 'textarea',
            label: 'Note',
            placeholder: 'Type here to write your notes',
        },
    ];
    return (_jsxs(Stack, Object.assign({ "data-testid": 'space-details' }, { children: [_jsx(VStack, Object.assign({ px: '10px', h: '100%', w: '100%', boxSizing: 'border-box', onClick: () => {
                    if (canEdit && !isEditing) {
                        setPageMode(PageModes.EDIT);
                    }
                }, spacing: 4, "data-testid": 'space-details-form' }, { children: spaceDetailInputs.map((formInput, i) => (_jsx(DynamicFormInput, { inputData: formInput, isEditing: isEditing, control: control }, `dynamic_input_${formInput.name}_${i}`))) })), !isEditing && currentSpace && (_jsxs(_Fragment, { children: [canEdit && (_jsx(AddPhotoBtn, { photoType: PhotoType.SPACE, spaceIdentity: currentSpace.spaceIdentity, spaceName: currentSpace.spaceId })), _jsx(SpacePhotoCarousel, { spaceIdentity: currentSpace.spaceIdentity, spaceName: currentSpace.spaceId, assetTemplates: assetTemplates })] })), _jsx(WarningSpaceDefinition, { disclosure: warningDisclosure, onCancel: () => cancelSpaceDefinitionChange(spaceDefinition), onContinue: () => continueSpaceDefinitionChange(spaceDefinition) })] })));
};
