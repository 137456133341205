import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FilterPhotosModal } from '@/pages/Main/Sidebar/DetailsSidebar/PhotoGalleryModal/FilterPhotosModal/FilterPhotosModal';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { appColors } from '@frontend/design-system/theme/constants';
import { MdFilterListAlt } from 'react-icons/md';
import { variants } from '../../../../../../config/theme';
export const FilterButton = ({ showClassification = true, }) => {
    const { isOpen: isOpenFilterModal, onOpen: onOpenFilterModal, onClose: onCloseFilterModal, } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsxs(Button, Object.assign({ variant: variants.transparentBlueBtn, onClick: onOpenFilterModal }, { children: [_jsx(Box, Object.assign({ mr: '8px' }, { children: _jsx(MdFilterListAlt, { color: appColors.PRIM_BLUE }) })), "Filter"] })), _jsx(FilterPhotosModal, { isOpen: isOpenFilterModal, close: onCloseFilterModal, showClassification: showClassification })] }));
};
