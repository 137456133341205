import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox } from '@chakra-ui/react';
import React, { useState } from 'react';
import { variants } from '../../theme/theme';
export const SelectCheckbox = ({ isSelected, isDisabled = false, onSelect, }) => {
    const [isChecked, setIsChecked] = useState(isSelected);
    const onChecked = (isChecked) => {
        onSelect(isChecked);
        setIsChecked(isChecked);
    };
    return (_jsx(Checkbox, { variant: variants.selectCheckbox, "aria-label": isChecked ? 'selected' : 'not selected', isChecked: isChecked, onChange: (e) => onChecked(e.target.checked), isDisabled: isDisabled, zIndex: '2' }));
};
