var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import services from '..';
export const usePlatformOrgApi = () => {
    // This should get replaced by org fetch and concierge/primary contact should be retrieved off of our org
    const getPlatformOrgClientConcierge = (id) => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService
            .getPlatformOrganization(id)
            .then((platformOrg) => platformOrg.clientConcierge
            ? services.userService.getContactFromPlatformUser(platformOrg.clientConcierge)
            : undefined);
    });
    const getOrganizationSnapshots = () => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService.getOrganizationSnapshots();
    });
    const userReports = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return services.orgService.userReports(orgIdentity);
    });
    return {
        getPlatformOrgClientConcierge,
        userReports,
        getOrganizationSnapshots,
    };
};
