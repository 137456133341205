var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const baseUrl = '/organizations';
const baseUrlPlatformAPI = '/Organization';
const baseReportUrlMsiqApi = '/Report';
export default class OrgService {
    constructor(api, platformApi) {
        this.api = api;
        this.platformApi = platformApi;
    }
    getOrg(id, relations) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.getWithQueryParams(`${baseUrl}/${id}`, {}, { relations: relations });
        });
    }
    getOrganizationSnapshots() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.platformApi
                .get(`${baseUrlPlatformAPI}/list`)
                .then((response) => response.map((org) => this.getOrganizationSnapshotFromResponse(org)));
            return response;
        });
    }
    getOrganizationSnapshotFromResponse(response) {
        if (!response.organizationIdentity) {
            console.error('Organization identity is missing', response);
            throw new Error('Organization identity is missing');
        }
        if (!response.organizationId) {
            console.error('Organization id is missing', response);
            throw new Error('Organization id is missing');
        }
        if (!response.name) {
            console.error('Organization name is missing', response);
            throw new Error('Organization name is missing');
        }
        return {
            id: response.organizationId,
            orgIdentity: response.organizationIdentity,
            name: response.name,
        };
    }
    getAllOrgs(relations) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.getWithQueryParams('/organizations', {}, { relations: relations });
        });
    }
    getPlatformOrganization(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.get(`${baseUrlPlatformAPI}/view/${orgIdentity}`);
        });
    }
    userReports(orgIdentity) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi.getWithQueryParams(`${baseReportUrlMsiqApi}/list/${orgIdentity}`);
        });
    }
    getSitesForOrg(oid) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.api.get(`/organizations/${oid}/sites`);
        });
    }
}
