import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/react';
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip, } from 'chart.js';
import 'chartjs-adapter-moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { createRoot } from 'react-dom/client';
import CustomLegendItem from './CustomLegendItem';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, TimeScale, Legend);
export const CustomLineChart = ({ data, options, legend, title, component, }) => {
    let ChartLegendTypes;
    (function (ChartLegendTypes) {
        ChartLegendTypes[ChartLegendTypes["line"] = 0] = "line";
        ChartLegendTypes[ChartLegendTypes["point"] = 1] = "point";
    })(ChartLegendTypes || (ChartLegendTypes = {}));
    const chartType = ChartLegendTypes.line;
    const getOrCreateLegendList = (id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer === null || legendContainer === void 0 ? void 0 : legendContainer.querySelector('ul');
        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.style.display = 'flex';
            listContainer.style.flexDirection = 'row';
            listContainer.style.margin = '0px';
            listContainer.style.padding = '0px';
            legendContainer === null || legendContainer === void 0 ? void 0 : legendContainer.appendChild(listContainer);
        }
        return listContainer;
    };
    const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            var _a;
            const ul = getOrCreateLegendList(options.containerID);
            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            // Will use the default dataset labels if no custom legend was provided in the options
            ((_a = options.legendValues) !== null && _a !== void 0 ? _a : items).forEach((item) => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.justifyContent = 'flex-end';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';
                li.style.marginBottom = '20px';
                // If we want to override the click functionality
                // li.onclick = () => {};
                const root = createRoot(li);
                root.render(_jsx(CustomLegendItem, { text: item.text, color: item.color }));
                ul.appendChild(li);
            });
        },
    };
    const customOptions = Object.assign({ responsive: true, maintainAspectRatio: false, plugins: {
            tooltip: {
                enabled: false,
            },
            htmlLegend: {
                // ID of the container to put the legend in JSX
                containerID: `legend-container-${title}`,
                chartType,
                legendValues: legend,
            },
            legend: {
                position: 'top',
                display: false,
            },
        } }, options);
    return (_jsxs("div", Object.assign({ style: { width: '100%' } }, { children: [_jsxs("div", Object.assign({ style: {
                    height: '400px',
                    width: '100%',
                } }, { children: [_jsx(Flex, { justify: 'flex-end', id: `legend-container-${title}` }), _jsx(Line, { "data-testid": `chart-${title}`, options: customOptions, plugins: [htmlLegendPlugin], data: data })] })), component && _jsx("div", Object.assign({ style: { marginTop: '30px' } }, { children: component }))] })));
};
