import axios from 'axios';
import { environment } from '../config/environment';
import FacilityService from './facilityService';
import FeatureFlagService from './featureFlagService';
import OfflineUploadService from './offlineUploadService';
import OrgService from './orgService';
import PhotoService from './photoService';
import PlanService from './planService';
import RestService from './RestService';
import SiteService from './siteService';
import TagService from './tagService';
import UserService from './userService';
export const apiService = new RestService(axios, environment.apiBase);
export const platformApiService = new RestService(axios, environment.platformApi);
const facilityService = new FacilityService(apiService);
const orgService = new OrgService(apiService, platformApiService);
const planService = new PlanService(apiService);
const photoService = new PhotoService(apiService, platformApiService);
const siteService = new SiteService(apiService);
const userService = new UserService(platformApiService);
const featureFlagService = new FeatureFlagService(apiService);
const offlineUploadService = new OfflineUploadService(apiService);
const tagService = new TagService(apiService);
const services = {
    facilityService,
    orgService,
    planService,
    photoService,
    siteService,
    userService,
    featureFlagService,
    offlineUploadService,
    tagService,
};
export default services;
