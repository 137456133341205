import { Apps, OrganizationRole } from '@/objects/FCAInterfaces';
import { selectUser } from '@/store/userSlice';
import { SystemRole } from '../../objects/UserData';
import { FeatureFlags, useFeatureFlag } from '../../store/featureFlag';
import { useAppSelector } from '../../store/hooks';
import { selectOrganization } from '../../store/orgSlice';
export const useAppAccess = (user, org) => {
    const piqEnabled = useFeatureFlag(FeatureFlags.FF_PIQ_ACCESS_CONTROL);
    const hasAccess = (maybe) => {
        var _a, _b, _c, _d, _e;
        if (!user) {
            return false;
        }
        if (!org) {
            return false;
        }
        if ((_a = org.apps) === null || _a === void 0 ? void 0 : _a.some((app) => app.app === maybe)) {
            if (user.systemRole === SystemRole.SUPER_ADMIN) {
                return true;
            }
            if (user.systemRole === SystemRole.ORG_APPROVER) {
                return true;
            }
            if ((_b = user.organizationRoles) === null || _b === void 0 ? void 0 : _b.some((role) => role.organizationIdentity === (org === null || org === void 0 ? void 0 : org.orgIdentity) &&
                role.role === OrganizationRole.LOCAL_ADMIN)) {
                return true;
            }
            if ((_c = user.organizationRoles) === null || _c === void 0 ? void 0 : _c.some((role) => role.organizationIdentity === (org === null || org === void 0 ? void 0 : org.orgIdentity) &&
                role.role === OrganizationRole.CLIENT_CONCIERGE)) {
                return true;
            }
            return ((_e = (_d = user.apps) === null || _d === void 0 ? void 0 : _d.some((app) => app.app === maybe &&
                app.organizationIdentity === (org === null || org === void 0 ? void 0 : org.orgIdentity))) !== null && _e !== void 0 ? _e : false);
        }
        return false;
    };
    return {
        [Apps.REPORTS]: hasAccess(Apps.REPORTS),
        [Apps.FCA]: hasAccess(Apps.FCA),
        [Apps.PLANNER]: hasAccess(Apps.PLANNER),
        [Apps.PIQ]: piqEnabled && hasAccess(Apps.PIQ),
    };
};
export const useCurrentUserAppAccess = () => {
    const user = useAppSelector(selectUser);
    const org = useAppSelector(selectOrganization);
    return useAppAccess(user, org);
};
