import { jsx as _jsx } from "react/jsx-runtime";
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { useController, } from 'react-hook-form';
export function FormSelector({ isMulti, form, selectFrom }) {
    const { field } = useController(form);
    const formattedSelectFrom = selectFrom.length > 0
        ? selectFrom.map((option) => ({
            label: option.name,
            value: option.identity,
        }))
        : [
            {
                label: `No associated ${form.name === 'Category' || form.name === 'Facility'
                    ? form.name.slice(0, -1) + 'ie'
                    : form.name}s`,
                value: 'none',
            },
        ];
    return (_jsx(Form.MultiSelectDropdown, { isMulti: isMulti, label: form.name, selectFrom: formattedSelectFrom, onChange: field.onChange, value: field.value }));
}
