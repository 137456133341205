import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { PhotoProvider } from '@/contexts/Photo/PhotoProvider';
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../../components/layouts/PageLayout';
import { GalleryProvider } from '../../../contexts/Photo/GalleryProvider';
import { PhotoSelectProvider } from '../../../contexts/Photo/PhotoSelectProvider';
import { getFacilitiesSpacesAssetsByOrg } from '../../../services/api/facilityAPI';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchOrg, selectAllOrgsSnapshots, selectOrganization, } from '../../../store/orgSlice';
import { setFilteredPhotos } from '../../../store/photoSlice';
import { Gallery } from './Gallery';
export const GalleryPage = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const currentOrg = useAppSelector(selectOrganization);
    const orgs = useAppSelector(selectAllOrgsSnapshots);
    const [facilities, setFacilities] = useState([]);
    const [spaces, setSpaces] = useState([]);
    const [assets, setAssets] = useState([]);
    const toast = useToast();
    useEffect(() => {
        dispatch(setFilteredPhotos([]));
    }, []);
    useEffect(() => {
        if (params.oid && params.oid !== (currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.id)) {
            dispatch(fetchOrg({ id: params.oid }));
        }
    }, [orgs]);
    useEffect(() => {
        if (currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.orgIdentity) {
            getFacilitiesSpacesAssetsByOrg(currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.orgIdentity)
                .then((fetchedData) => {
                const facilitiesArray = fetchedData.map((facility) => {
                    return {
                        identity: facility.identity,
                        name: facility.name,
                    };
                });
                const spacesAssetsArray = fetchedData.flatMap((facility) => {
                    if (facility.spaces) {
                        return facility.spaces;
                    }
                    else
                        return [];
                });
                const spacesArray = spacesAssetsArray.map((space) => {
                    return {
                        identity: space.identity,
                        facilityIdentity: space.facilityIdentity,
                        constrYear: space.constrYear,
                        name: space.name,
                    };
                });
                const assetsArray = spacesAssetsArray.flatMap((space) => {
                    if (space.assets) {
                        return space.assets;
                    }
                    else
                        return [];
                });
                setFacilities(facilitiesArray);
                setSpaces(spacesArray);
                setAssets(assetsArray);
            })
                .catch((error) => {
                console.error(error);
                toast({
                    title: 'Something went wrong with fetching facilities; try again later.',
                    status: 'error',
                    isClosable: true,
                });
            });
        }
    }, [currentOrg === null || currentOrg === void 0 ? void 0 : currentOrg.orgIdentity]);
    return (_jsx(PageLayout, Object.assign({ title: 'myGallery' }, { children: _jsx(_Fragment, { children: currentOrg && (_jsx(GalleryProvider, Object.assign({ allFacilities: facilities, allSpaces: spaces, allAssets: assets }, { children: _jsx(PhotoProvider, Object.assign({ orgIdentity: currentOrg.orgIdentity }, { children: _jsx(PhotoSelectProvider, Object.assign({ org: currentOrg }, { children: _jsx(Gallery, { currentOrg: currentOrg }) })) })) }))) }) })));
};
