import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { PageLayout } from '../../components/layouts/PageLayout';
import { useAppSelector } from '../../store/hooks';
import { selectOrganization } from '../../store/orgSlice';
import { AccessDenied } from './AccessDenied';
export const NoAccessPage = () => {
    var _a;
    const org = useAppSelector(selectOrganization);
    return (_jsx(PageLayout, Object.assign({ title: (_a = org === null || org === void 0 ? void 0 : org.name) !== null && _a !== void 0 ? _a : 'No App Access' }, { children: _jsx(Box, Object.assign({ m: '0px 50px' }, { children: _jsx(AccessDenied, {}) })) })));
};
