var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
export const defaultOptions = {
    error: {
        label: 'Error encountered in request',
    },
    success: {
        label: 'Successful Request',
        enabled: false,
    },
};
/**
 * Handles Api request data by wrapping a request promise to handle success/errors optionally through Chakra toast
 * Must be initialized within ChakraProvider due to the import and use of 'useToast'
 * @param options
 * @returns accessors for the response data of a request
 */
export const usePlatformApiRequestHandler = (toast, options = defaultOptions) => {
    const { success, error } = options;
    const displaySuccessToast = success.enabled;
    // typeorm does not like generics specified in useState?
    const [apiError, setApiError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const getErrorDescription = (errorBody, err) => {
        if (errorBody) {
            if (errorBody.errors) {
                return JSON.stringify(errorBody.errors);
            }
            else {
                return errorBody.toString();
            }
        }
        else {
            return err.toString();
        }
    };
    const handleResponse = (response, mapper) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        return response
            .then((res) => {
            if (displaySuccessToast) {
                toast({
                    title: success.label,
                    status: 'success',
                    isClosable: true,
                });
            }
            return mapper(res);
        })
            .catch((err) => {
            const errorBody = err.body;
            const title = errorBody && errorBody.title
                ? errorBody.title
                : error.label;
            toast({
                title: title,
                status: 'error',
                description: getErrorDescription(errorBody, err),
                isClosable: true,
            });
            setApiError(err);
        })
            .finally(() => setIsLoading(false));
    });
    return { error: apiError, isLoading, handleResponse };
};
