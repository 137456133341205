import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThumbnailItem } from '@/pages/Main/Sidebar/DetailsSidebar/PhotoGalleryModal/ThumbnailItem';
import { Box, useDisclosure } from '@chakra-ui/react';
import { usePhotoSelector } from '../../../contexts/Photo/PhotoSelectContext';
import { FeatureFlags, useFeatureFlag } from '../../../store/featureFlag';
import { ExpandedPhotoModal } from './ExpandedPhotoModal';
export const GalleryGridItem = ({ photo, index }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const myPhotos = useFeatureFlag(FeatureFlags.FF_ADD_PHOTO_TAG);
    const downloadPhotoFeature = useFeatureFlag(FeatureFlags.FF_DOWNLOAD_PHOTOS);
    const FFPhotoHover = useFeatureFlag(FeatureFlags.FF_PHOTO_HOVER);
    const { isSelecting, isPhotoSelected, selectPhoto, unselectPhoto, selectLimitReached, } = usePhotoSelector();
    const photoSelected = downloadPhotoFeature && isPhotoSelected(photo);
    const onGallerySelect = () => {
        if (isPhotoSelected(photo)) {
            unselectPhoto(photo);
        }
        else {
            selectPhoto(photo);
        }
    };
    return (_jsxs(Box, Object.assign({ height: '177px' }, { children: [_jsx(ThumbnailItem, { photo: photo, maxH: 'inherit', borderRadius: '5px', handleClick: onOpen, isSelectMode: isSelecting, isSelected: photoSelected, onSelect: onGallerySelect, isSelectDisabled: !photoSelected && selectLimitReached(), shouldHover: FFPhotoHover && true }, index + photo.name), myPhotos && (_jsx(ExpandedPhotoModal, { isExpandedModalOpen: isOpen, onCloseExpandedModal: onClose }))] }), `photo_${index}`));
};
