var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useToast } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { defaultOptions, usePlatformApiRequestHandler, } from '@frontend/domain/hooks/platformAPI/usePlatformApiRequestHandler';
import { convertAssetSurveyResponseToDynamicQuestionForm, convertToSingleAssetFromDetails, getLatestAssetConditionAssessment, } from '../conversions/AssetConversions';
export const convertAssetPinResponseToLocation = (assetPin) => {
    if (!assetPin) {
        return undefined;
    }
    return {
        x: Math.round(assetPin.xPosition),
        y: Math.round(assetPin.yPosition),
    };
};
export const convertAssetDetailsToAssetForm = (assets) => {
    var _a, _b, _c;
    if (assets.length < 1) {
        throw new Error('No asset provided');
    }
    const asset = assets[0];
    const assetToReturn = {
        name: asset.assetName,
        assetClass: asset.assetClass,
        assetType: asset.assetType,
        approximateInstallYear: ((_a = asset.installedYear) === null || _a === void 0 ? void 0 : _a.toString()) || undefined,
        assetCondition: (_b = getLatestAssetConditionAssessment(asset.conditions)) === null || _b === void 0 ? void 0 : _b.toString(),
        ageAdjustedCondition: (_c = getLatestAssetConditionAssessment(asset.conditions, true)) === null || _c === void 0 ? void 0 : _c.toString(),
        modelNumber: asset.modelNumber || undefined,
        serialNumber: asset.serialNumber || undefined,
        location: convertAssetPinResponseToLocation(asset.pin),
        notes: asset.note || undefined,
        isFacilityWide: asset.isFacilityAsset,
        questions: convertAssetSurveyResponseToDynamicQuestionForm(asset.survey),
    };
    return assetToReturn;
};
const determineQuestions = (answers) => {
    let concatAnswers = '';
    answers &&
        answers.forEach((answer) => {
            if (answer.value !== null) {
                const formattedAnswer = `${answer.name}: ${answer.value}`;
                if (concatAnswers === '') {
                    concatAnswers = formattedAnswer;
                }
                else {
                    concatAnswers += `; ${formattedAnswer}`;
                }
            }
        });
    return concatAnswers;
};
export const convertAssetDetailToAssetCSV = (assets, orgAAC) => {
    const convertedAssets = assets.map((asset) => {
        var _a;
        let condition;
        if (asset.conditions.length > 0) {
            condition = orgAAC
                ? asset.conditions[asset.conditions.length - 1]
                    .ageAdjustedCondition
                : asset.conditions[asset.conditions.length - 1].condition;
        }
        return {
            assetId: asset.assetId,
            assetGroup: `assetType: ${asset.assetType || ''}; year: ${asset.installedYear || ''}; condition: ${condition || ''}`,
            name: asset.assetName || '',
            assetCategory: asset.assetCategory || '',
            assetClass: asset.assetClass || '',
            assetType: asset.assetType || '',
            approxYear: asset.installedYear || '',
            modelNum: asset.modelNumber || '',
            serialNum: asset.serialNumber || '',
            assetCondition: condition || '',
            questions: determineQuestions((_a = asset.survey) === null || _a === void 0 ? void 0 : _a.answers),
            note: asset.note || '',
            spaceId: asset.spaceId,
        };
    });
    return convertedAssets;
};
export const useAssetApi = () => {
    const { platformApi } = useDependencies();
    const toast = useToast();
    const loadAssetsByFacility = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading assets',
        } }));
    const loadAssetDetails = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when loading the asset',
        } }));
    const reassessAssetHandler = usePlatformApiRequestHandler(toast, Object.assign(Object.assign({}, defaultOptions), { error: {
            label: 'There was an error when reassessing the asset',
        } }));
    const fetchAssetByIdentity = (assetIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        const assetDetails = yield loadAssetDetails.handleResponse(platformApi.asset.postApiV1AssetLoad({
            assetId: assetIdentity,
            assetClassTypes: [],
        }), convertAssetDetailsToAssetForm);
        return assetDetails;
    });
    const fetchAssetsByFacility = (facilityId, orgAAC) => __awaiter(void 0, void 0, void 0, function* () {
        const assets = yield loadAssetsByFacility.handleResponse(platformApi.asset.postApiV1AssetLoad({
            facilityId: facilityId,
            assetClassTypes: [],
        }), (assets) => convertAssetDetailToAssetCSV(assets, orgAAC));
        return assets;
    });
    const reassessAssetCondition = (newCondition, assessedOn, assetIdentity, spaceIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return yield reassessAssetHandler.handleResponse(platformApi.asset.postApiV1AssetReassess({
            spaceId: spaceIdentity !== null && spaceIdentity !== void 0 ? spaceIdentity : 0,
            assetId: assetIdentity !== null && assetIdentity !== void 0 ? assetIdentity : 0,
            condition: parseInt(newCondition.slice(0, 1)[0]),
            assessedOn: assessedOn,
        }), (newAsset) => convertToSingleAssetFromDetails(spaceIdentity !== null && spaceIdentity !== void 0 ? spaceIdentity : 0, [newAsset]));
    });
    return {
        loadAssetsByFacility: {
            isLoading: loadAssetsByFacility.isLoading,
            error: loadAssetsByFacility.error,
            fetch: fetchAssetsByFacility,
        },
        loadAssetDetails: {
            isLoading: loadAssetDetails.isLoading,
            error: loadAssetDetails.error,
            fetch: fetchAssetByIdentity,
        },
        reassessAssetCondition: {
            isLoading: reassessAssetHandler.isLoading,
            error: reassessAssetHandler.error,
            reassess: reassessAssetCondition,
        },
    };
};
