import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import { useEffect, useRef, useState } from 'react';
import { Camera } from 'react-camera-pro';
import { useForm } from 'react-hook-form';
import { usePhotoContext } from '../../../../../contexts/Photo/PhotoContext';
import { AddPhotoModalBody } from './AddPhotoModalBody';
import { PhotoModalCarousel } from './PhotoModalCarousel';
export const AddPhotoModal = ({ addingTo, isOpen, onClose, onSubmit, setPhotos, selectedTags, setSelectedTags, setSelectedDate, }) => {
    const { orgTags } = usePhotoContext();
    const photosRef = useRef(null);
    const photosRefCamera = useRef(null);
    const camera = useRef(null);
    const [photoArray, setPhotoArray] = useState([]);
    const [photoTaken, setPhotoTaken] = useState(false);
    const [error, setError] = useState(null);
    const [invalidDate, setInvalidDate] = useState(false);
    const { register: tagRegister, reset: tagReset } = useForm();
    useEffect(() => { }, [invalidDate]);
    const handleTagSelect = (event) => {
        const tagIdentity = Number(event.target.value);
        const tagsArray = orgTags.filter((tag) => tag.tagIdentity === tagIdentity);
        setSelectedTags([...selectedTags, ...tagsArray]);
        tagReset({
            tag: '',
        });
    };
    const handleTagDelete = (tagToDelete) => {
        const tagsArray = selectedTags.filter((tag) => tag.tagIdentity !== tagToDelete);
        setSelectedTags(tagsArray);
    };
    const onCloseModal = () => {
        setPhotoArray([]);
        setPhotoTaken(false);
        setSelectedTags([]);
        setSelectedDate(undefined);
        onClose();
    };
    const takePhoto = () => {
        const newPhotoToUpload = camera.current.takePhoto();
        fetch(newPhotoToUpload)
            .then((res) => res.blob())
            .then((res) => {
            const file = createPhotoFile(res, photoArray.length + 1);
            setPhotos([...photoArray, file]);
            setPhotoArray([...photoArray, file]);
        });
        setPhotoTaken(true);
    };
    const createPhotoFile = (file, index) => {
        return new File([file], `Photo-${index}.jpeg`, {
            type: 'image/jpeg',
        });
    };
    const retakePhoto = () => {
        photoArray.pop();
        setPhotoTaken(false);
    };
    const savePhoto = () => {
        setPhotos([...photoArray]);
        setPhotoArray([...photoArray]);
        setPhotoTaken(false);
    };
    const updateState = (photo) => {
        var _a;
        if ((_a = photo.current) === null || _a === void 0 ? void 0 : _a.files) {
            const newFiles = Array.from(photo.current.files);
            const newPhotoArray = newFiles.map((file) => {
                if (file.name === 'image.jpg') {
                    return createPhotoFile(file, photoArray.length + 1);
                }
                else {
                    return file;
                }
            });
            setPhotos([...photoArray, ...newPhotoArray]);
            setPhotoArray([...photoArray, ...newPhotoArray]);
        }
    };
    const renderCamera = () => {
        return (_jsx(Box, Object.assign({ maxH: '70vh' }, { children: _jsxs(Button, Object.assign({ onClick: () => {
                    var _a;
                    (_a = photosRefCamera === null || photosRefCamera === void 0 ? void 0 : photosRefCamera.current) === null || _a === void 0 ? void 0 : _a.click();
                }, h: '100%', w: '100%', variant: 'ghost', display: 'inline-block', padding: '0' }, { children: [_jsx(Input, { type: 'file', multiple: true, style: { display: 'none' }, accept: 'images/*', capture: 'environment', onChange: () => {
                            updateState(photosRefCamera);
                        }, ref: photosRefCamera }), _jsx(Camera, { facingMode: 'environment', ref: camera, aspectRatio: 16 / 9, errorMessages: {
                            noCameraAccessible: undefined,
                            permissionDenied: undefined,
                            switchCamera: undefined,
                            canvas: undefined,
                        } })] })) })));
    };
    const renderPhotoView = () => {
        return _jsx(PhotoModalCarousel, { photos: photoArray });
    };
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onCloseModal }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, Object.assign({ maxW: '55vw' }, { children: [_jsxs(ModalHeader, { children: ["Add Photo(s) to ", addingTo] }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: _jsx(AddPhotoModalBody, { photoTaken: photoTaken, renderCamera: renderCamera(), renderPhotoView: renderPhotoView(), onTakePhoto: takePhoto, onRetakePhoto: retakePhoto, onSavePhoto: savePhoto, onUpdateState: updateState, photosRef: photosRef, photos: photoArray, handleTagSelect: handleTagSelect, handleTagDelete: handleTagDelete, register: tagRegister, tagsToDisplay: selectedTags, error: error, setError: setError, setInvalidDate: setInvalidDate, setSelectedDate: setSelectedDate }) }), _jsx(ModalFooter, { children: _jsx(Button, Object.assign({ variant: variants.blueBtn, height: '36px', onClick: () => {
                                onSubmit();
                                onCloseModal();
                            }, isDisabled: !!error || !(photoArray.length > 0) || invalidDate, _hover: { bg: '' } }, { children: "Submit" })) })] }))] })));
};
