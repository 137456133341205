import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { PhotoSelectContext } from './PhotoSelectContext';
export var SelectionType;
(function (SelectionType) {
    SelectionType["DOWNLOAD"] = "download";
    SelectionType["MOVE"] = "move";
})(SelectionType || (SelectionType = {}));
export function PhotoSelectProvider({ children, org, initialProps, }) {
    var _a, _b, _c;
    const [isSelecting, setIsSelecting] = useState((_a = initialProps === null || initialProps === void 0 ? void 0 : initialProps.isSelecting) !== null && _a !== void 0 ? _a : false);
    const [isMoving, setIsMoving] = useState((_b = initialProps === null || initialProps === void 0 ? void 0 : initialProps.isMoving) !== null && _b !== void 0 ? _b : false);
    const [selectedPhotos, setSelectedPhotos] = useState((_c = initialProps === null || initialProps === void 0 ? void 0 : initialProps.selectedPhotos) !== null && _c !== void 0 ? _c : []);
    const [selectLimit, setSelectLimit] = useState(initialProps === null || initialProps === void 0 ? void 0 : initialProps.selectLimit);
    // Prevent selecting across orgs
    useEffect(() => {
        if (isSelecting && org) {
            stopSelecting();
        }
    }, [org]);
    const startSelecting = (type, selectLimit) => {
        setSelectLimit(selectLimit);
        setIsSelecting(true);
        if (type === SelectionType.MOVE) {
            setIsMoving(true);
        }
    };
    const stopSelecting = () => {
        clearSelection();
        setIsSelecting(false);
        setIsMoving(false);
        setSelectLimit(undefined);
    };
    const clearSelection = () => {
        setSelectedPhotos([]);
    };
    const selectPhoto = (photo) => {
        validateSelect();
        setSelectedPhotos([...selectedPhotos, photo]);
    };
    const validateSelect = () => {
        if (!isSelecting) {
            throw new Error('Selecting must be started before a selection can be made');
        }
        if (selectLimitReached()) {
            throw new Error('Select Limit reached. Cannot select more items');
        }
    };
    const unselectPhoto = (photo) => {
        validateUnselect();
        setSelectedPhotos(selectedPhotos.filter((selectedPhoto) => selectedPhoto.photoIdentity !== photo.photoIdentity));
    };
    const validateUnselect = () => {
        if (!isSelecting) {
            throw new Error('Selecting must be started before anything is unselected');
        }
    };
    const isPhotoSelected = (photo) => {
        const photoFound = selectedPhotos.find((selectedPhoto) => selectedPhoto.photoIdentity === photo.photoIdentity);
        return photoFound ? true : false;
    };
    const selectLimitReached = () => {
        return (selectLimit !== undefined && selectedPhotos.length >= selectLimit);
    };
    return (_jsx(PhotoSelectContext.Provider, Object.assign({ value: {
            isSelecting,
            isMoving,
            startSelecting,
            stopSelecting,
            selectedPhotos,
            selectLimit,
            selectPhoto,
            unselectPhoto,
            isPhotoSelected,
            selectLimitReached,
        } }, { children: children })));
}
