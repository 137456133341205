import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { Flex, Heading, HStack, IconButton, List, Spacer, Stack, useDisclosure, } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { useState } from 'react';
import { VscAdd } from 'react-icons/vsc';
import { appValues } from '../../../../config/constants';
import { variants } from '../../../../config/theme';
import { PageModes } from '../../../../objects/FCAInterfaces';
import { useAppSelector } from '../../../../store/hooks';
import { facilityRoleCanEdit, selectFacilityRole, } from '../../../../store/userSlice';
import { Sidebar } from '../Sidebar';
import { PlanSidebarItem } from '../SidebarItems/PlanSidebarItem';
import { SpaceSidebarItem } from '../SidebarItems/SpaceSidebarItem';
import { AddNewButton } from './AddNewButton';
import { UploadPlanModal } from './UploadPlanModal';
export const PlansSidebar = ({ plans, updateCurrentPlans, handlePlanCreate, handlePlanDelete, }) => {
    const [scrolling, setScrolling] = useState(false);
    const [scrollingAuto, setScrollingAuto] = useState(false);
    const facilityRole = useAppSelector(selectFacilityRole);
    const canEdit = facilityRoleCanEdit(facilityRole);
    const { setPageMode, selectedPlan, currentSpaces, mapMode, pageMode, isDataLoading, } = useFacilityContext();
    const { isOpen: isOpenUpload, onOpen: onOpenUpload, onClose: onCloseUpload, } = useDisclosure();
    const handleOpenUpload = () => {
        onOpenUpload();
    };
    const renderPlans = () => {
        return (_jsxs(Stack, Object.assign({ bg: 'misc.sectionGrey', py: '19px', spacing: '19px' }, { children: [_jsxs(HStack, Object.assign({ justifyContent: 'space-between', pl: '21px' }, { children: [_jsx(Heading, Object.assign({ as: 'h2', variant: variants.regSizeH }, { children: "Facility Plans" })), canEdit && (_jsx(IconButton, { onClick: handleOpenUpload, "aria-label": 'add-new-plan', icon: _jsx(VscAdd, {}), variant: variants.transparentBtn, _hover: { cursor: 'pointer' } }))] })), _jsx(List, Object.assign({ minH: '15vh', maxH: '25vh', overflowY: 'auto', sx: { scrollbarGutter: 'stable' }, spacing: '19px', onTouchMove: () => {
                        if (!scrolling) {
                            setScrolling(true);
                        }
                        if (!scrollingAuto) {
                            setScrollingAuto(true);
                        }
                    } }, { children: plans.map((plan, index) => {
                        return (_jsx(PlanSidebarItem, { plan: plan, plans: plans, updateCurrentPlans: updateCurrentPlans, handlePlanCreate: handlePlanCreate, handlePlanDelete: handlePlanDelete, scrolling: scrolling, setScrolling: setScrolling, scrollingAuto: scrollingAuto, setScrollingAuto: setScrollingAuto }, `${plan.name} ${index}`));
                    }) })), _jsx(UploadPlanModal, { isOpen: isOpenUpload, onClose: onCloseUpload, handlePlanCreate: handlePlanCreate, updateCurrentPlans: updateCurrentPlans })] })));
    };
    const renderSpaces = () => {
        return (_jsx(List, Object.assign({ h: '100%', spacing: 2, onTouchMove: () => {
                if (!scrolling) {
                    setScrolling(true);
                }
                if (!scrollingAuto) {
                    setScrollingAuto(true);
                }
            } }, { children: currentSpaces.map((space, i) => {
                return (_jsx(SpaceSidebarItem, { plans: plans, space: space, scrolling: scrolling, setScrolling: setScrolling, scrollingAuto: scrollingAuto, setScrollingAuto: setScrollingAuto }, `${space.identity} ${i}`));
            }) })));
    };
    return (_jsx(Sidebar, Object.assign({ placement: 'left', width: appValues.SIDEBAR_WIDTH_PLANS }, { children: _jsxs(Flex, Object.assign({ h: '100%', w: '100%', direction: 'column', alignItems: 'center', className: 'plansSidebar' }, { children: [_jsxs(Stack, Object.assign({ w: '100%', h: '100%', overflow: 'auto', onClick: () => {
                        if (pageMode !== PageModes.VIEW) {
                            setPageMode(PageModes.VIEW);
                        }
                    } }, { children: [renderPlans(), _jsx(Stack, Object.assign({ w: '100%', h: '100%', overflowY: 'auto', overflowX: 'hidden', sx: { scrollbarGutter: 'stable' } }, { children: renderSpaces() }))] })), _jsx(Spacer, {}), _jsx(Stack, Object.assign({ w: '100%', mt: '10px', alignItems: 'center' }, { children: selectedPlan && !mapMode && canEdit && _jsx(AddNewButton, {}) })), _jsx(LoadingWithControl, { isLoading: isDataLoading })] })) })));
};
