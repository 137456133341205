export const validateYear = (year) => {
    if (typeof year == 'undefined' || year === null || year === '')
        return true;
    if (Number.isNaN(year))
        return false;
    const currentYear = new Date().getFullYear();
    return currentYear - 200 <= year && year <= currentYear;
};
export const constrYearConstraint = {
    min: 1700,
    max: new Date().getFullYear() + 1,
    precision: 0,
};
export const installYearConstraint = Object.assign({}, constrYearConstraint);
export const validateYearRange = (year, min, max) => {
    if (max < min) {
        throw Error('Max year cannot be less than Min year.');
    }
    if (typeof year == 'undefined' || year === null || year === '')
        return true;
    if (Number.isNaN(year))
        return false;
    return min <= year && year <= max;
};
export function isImage(filename, supportedFormats) {
    const ext = filename.substring(filename.lastIndexOf('.')).toLowerCase();
    return supportedFormats.includes(ext);
}
export const validateImageFormat = (files, formats) => {
    const unsupportedFiles = [];
    for (const file of files) {
        if (!isImage(file.name, formats)) {
            unsupportedFiles.push(file);
        }
    }
    if (unsupportedFiles.length > 0) {
        return unsupportedFiles;
    }
    else {
        return null;
    }
};
