var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@chakra-ui/react';
import { MdFileDownload } from 'react-icons/md';
import { getCsv } from '../../../services/api/facilityAPI';
import { downloadCsvFile } from '../../../services/downloadCsvFile';
import { useAssetApi } from '../../../services/useAssetApi';
export const CsvFileExporter = ({ facility, org, }) => {
    const { loadAssetsByFacility } = useAssetApi();
    const handleDownloadClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const assets = yield loadAssetsByFacility.fetch(facility.facilityIdentity, org.showAgeAdjustedCondition);
        const facilityCsvData = facility && (yield getCsv(facility.id));
        downloadCsvFile('plans', facilityCsvData);
        downloadCsvFile('spaces', facilityCsvData);
        downloadCsvFile('assets', facilityCsvData, assets);
    });
    return (_jsx(Button, Object.assign({ bg: 'none', rightIcon: _jsx(MdFileDownload, {}), onClick: handleDownloadClick }, { children: "Download CSV" })));
};
