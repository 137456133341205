import { jsx as _jsx } from "react/jsx-runtime";
import { Menu, MenuItem, MenuList } from '@chakra-ui/react';
import React from 'react';
export function ActionMenu({ actions, isOpenActions, onCloseActions, handleTouchMove, uniqueStyles, }) {
    return (_jsx(Menu, Object.assign({ isOpen: isOpenActions, onClose: onCloseActions }, { children: _jsx(MenuList, Object.assign({ backgroundColor: 'misc.sectionGrey', overflow: 'visible', position: 'absolute', left: 0, onTouchMove: () => {
                handleTouchMove();
            }, "data-testid": 'actions' }, uniqueStyles, { children: actions.map((action, index) => (_jsx(MenuItem, Object.assign({ fontSize: '16px', fontWeight: '600', icon: action.icon, display: action.display, onClick: (e) => {
                    e.stopPropagation();
                    action.handleClick();
                }, onTouchEnd: (e) => {
                    e.stopPropagation();
                    action.handleTouch();
                } }, { children: action.name }), `${action}-${index}`))) })) })));
}
