var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPhoto } from '../services/api/photoAPI';
const initialPhoto = undefined;
export const fetchPhoto = createAsyncThunk('photos/fetch', (phid) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getPhoto(phid);
}));
export const initialPhotoState = {
    photo: initialPhoto,
    photoFullscreen: undefined,
    loadingMedia: false,
    filteredPhotos: [],
};
export const PhotoSlice = createSlice({
    name: 'Photo',
    initialState: initialPhotoState,
    reducers: {
        setPhoto: (state, { payload: photo }) => {
            state.photo = photo;
        },
        setPhotoFullscreen: (state, { payload: photoFullscreen }) => {
            state.photoFullscreen = photoFullscreen;
        },
        setLoadingMedia: (state, { payload: loading }) => {
            state.loadingMedia = loading;
        },
        setFilteredPhotos: (state, { payload: filteredPhotos }) => {
            state.filteredPhotos = filteredPhotos;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPhoto.fulfilled, (state, action) => {
            state.photo = action.payload;
        });
    },
});
export const selectPhoto = (state) => state.photo.photo;
export const selectPhotoFullscreen = (state) => state.photo.photoFullscreen;
export const selectLoadingMedia = (state) => state.photo.loadingMedia;
export const selectFilteredPhotos = (state) => state.photo.filteredPhotos;
export const { setPhoto, setPhotoFullscreen, setLoadingMedia, setFilteredPhotos, } = PhotoSlice.actions;
export default PhotoSlice.reducer;
