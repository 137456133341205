var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// TODO: https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Center, CloseButton, HStack, ListItem, Spinner, Text, UnorderedList, useToast, VStack, } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { authConfig } from './authConfig';
import { AuthenticationGuard } from './components/auth/AuthenticationGuard';
import { appColors } from './config/constants';
import { useCacheErrorToast } from './config/db/dbErrorHandling';
import { Paths } from './config/paths';
import './config/style.css';
import { slgqColors } from './config/theme';
import { startWorker } from './Mocks/platformHandlers';
import { Logout } from './pages/Logout/Logout';
import { FacilityPage } from './pages/Main/FacilityPage';
import { GalleryPage } from './pages/Selection/Gallery/GalleryPage';
import { NoAssociatedData } from './pages/Selection/NoAssociatedData';
import { SitesPage } from './pages/Selection/Sites/SitesPage';
import { SupportRedirect } from './pages/Selection/SupportRedirect';
import { apiService, platformApiService } from './services';
import { fetchFeatureFlags } from './store/featureFlagSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectUpdateAvail } from './store/orgSlice';
import { selectLoadingMedia } from './store/photoSlice';
import { fetchUser, selectAccess, selectUser } from './store/userSlice';
function App() {
    // PWA DEV TODO: When pushing to Dev, uncomment out below.
    useCacheErrorToast();
    const userCurrent = useAppSelector(selectUser);
    const loadingMedia = useAppSelector(selectLoadingMedia);
    const updateAvailable = useAppSelector(selectUpdateAvail);
    const fcaAccess = useAppSelector(selectAccess);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { setToken, isReady } = useDependencies();
    const dispatch = useAppDispatch();
    const toast = useToast();
    const closeToast = () => {
        toast.close('updateToast');
    };
    useEffect(() => {
        dispatch(fetchFeatureFlags());
    }, []);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (process.env.REACT_APP_API_MOCKING === 'enabled') {
                startWorker();
            }
        }
    }, []);
    useEffect(() => {
        if (updateAvailable) {
            console.log('There is an update available!');
            toast({
                containerStyle: {
                    border: 'none',
                },
                id: 'updateToast',
                position: 'top-right',
                render: () => (_jsxs(VStack, Object.assign({ color: slgqColors.primWhite, p: '13px 13px 20px 20px', bg: slgqColors.primBlue, borderRadius: '15px', border: '0.5px, solid rgba(43, 43, 43, 1)' }, { children: [_jsx(HStack, Object.assign({ justifyContent: 'end', h: '16px', w: '100%' }, { children: _jsx(CloseButton, { alignItems: 'baseline', "aria-label": 'Close Update Notification', color: slgqColors.primWhite, h: '100%', justifyContent: 'end', onClick: closeToast, size: 'lg' }) })), _jsx(Text, Object.assign({ fontSize: '24px', m: '0px !important', color: slgqColors.primWhite }, { children: "A new update is available!" })), _jsxs(UnorderedList, Object.assign({ ms: '20px' }, { children: [_jsx(ListItem, Object.assign({ mb: '10px', ml: '20px' }, { children: "If you are in the web browser, you just need to close all the tabs you have opened with the FA tool." })), _jsx(ListItem, Object.assign({ ml: '20px' }, { children: "If you are in the downloaded app, you just need to close and reopen it." }))] }))] }))),
                duration: 600000,
                isClosable: true,
            });
        }
    }, [updateAvailable]);
    useEffect(() => {
        if (isAuthenticated && (user === null || user === void 0 ? void 0 : user.email)) {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: authConfig.authorizationParams.platformAudience,
                    scope: 'email read:current_user update:current_user_metadata',
                },
            }).then((token) => {
                setToken(token);
            });
            // TODO: remove once we only make service requests through useDependencies
            apiService.setTokenRetrievalFunction(() => __awaiter(this, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            platformApiService.setTokenRetrievalFunction(() => __awaiter(this, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            dispatch(fetchUser());
        }
    }, [isAuthenticated, user]);
    const renderRoutes = () => {
        // PWA DEV TODO: When pushing to Dev, uncomment from HERE...
        if ((userCurrent === null || userCurrent === void 0 ? void 0 : userCurrent.id) === 'null' || !isReady) {
            return (_jsx(Route, { path: '*', element: _jsx(AuthenticationGuard, { component: () => (_jsx(Center, Object.assign({ w: '100vw', h: '100vh' }, { children: _jsx(Spinner, { color: appColors.PRIM_BLUE }) }))) }) }));
        }
        else if (userCurrent && isReady) {
            // PWA DEV TODO: Until HERE
            return (_jsxs(_Fragment, { children: [_jsx(Route, { path: Paths.HOME, element: _jsx(AuthenticationGuard, { component: SitesPage, appAccess: fcaAccess }) }), _jsx(Route, { path: Paths.FACILITY_DYNAMIC, element: _jsx(AuthenticationGuard, { component: FacilityPage, appAccess: fcaAccess }) }), _jsx(Route, { path: Paths.GALLERY, element: _jsx(AuthenticationGuard, { component: GalleryPage, appAccess: fcaAccess }) }), _jsx(Route, { path: Paths.GALLERY_DYNAMIC, element: _jsx(AuthenticationGuard, { component: GalleryPage, appAccess: fcaAccess }) }), _jsxs(Route, { children: [_jsx(Route, { path: Paths.ORG_SITES, element: _jsx(AuthenticationGuard, { component: SitesPage, appAccess: fcaAccess }) }), _jsx(Route, { path: Paths.SUPPORT, element: _jsx(AuthenticationGuard, { component: SupportRedirect }) })] })] }));
            // PWA DEV TODO: When pushing to Dev, uncomment from HERE...
        }
        else {
            return (_jsx(Route, { path: '*', element: _jsx(AuthenticationGuard, { component: NoAssociatedData }) }));
        }
        // PWA DEV TODO: Until HERE
    };
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ className: 'App', h: '100vh', zIndex: '1' }, { children: [_jsx(Routes, { children: _jsxs(_Fragment, { children: [_jsx(Route, { path: Paths.LOGOUT, element: _jsx(Logout, {}) }), renderRoutes()] }) }), loadingMedia && (_jsx(HStack, Object.assign({ zIndex: '90000', w: '100%', h: '100%', position: 'absolute', backgroundColor: 'rgb(0,0,0, 0.7)' }, { children: _jsx(Spinner, { margin: 'auto', thickness: '4px', speed: '0.65s', emptyColor: 'gray.200', color: 'blue.500', size: 'xl' }) })))] })) }));
}
export default App;
