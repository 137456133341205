import { jsx as _jsx } from "react/jsx-runtime";
import { HStack } from '@chakra-ui/react';
import { Controller, } from 'react-hook-form';
import { TimeseriesButtons, } from '../DetailsSidebar/Timeseries/TimeseriesButtons';
import { DynamicInput } from './DynamicInput';
export function DynamicFormInput({ inputData, control, isEditing, infoPopover, canEdit, }) {
    return (_jsx(HStack, Object.assign({ justifyContent: 'space-between', w: '100%', display: inputData.isHidden ? 'none' : 'block' }, { children: _jsx(Controller, { name: inputData.name, control: control, 
            // TODO: once a decision on how we validate has been made, validation is only being done on backend
            // rules={{
            // 	validate: (value) => {
            // 		if (inputData.validation && inputData.errorMessage) {
            // 			return (
            // 				inputData.validation(value) ||
            // 				inputData.errorMessage
            // 			);
            // 		}
            // 	},
            // }}
            render: ({ field, fieldState: { error } }) => {
                return !isEditing ? (_jsx(TimeseriesButtons, Object.assign({ canEdit: canEdit, timeseriesConfig: inputData.timeseriesConfig, name: inputData.name }, { children: _jsx(DynamicInput, { required: inputData.required, data: field.value, editMode: isEditing, inputType: inputData.inputType, label: inputData.label, choices: inputData.choices, isDisabled: inputData.isDisabled, errorMessage: error === null || error === void 0 ? void 0 : error.message, handleInputChange: (e) => {
                            field.onChange(e);
                            if (inputData.onChange) {
                                inputData.onChange(e);
                            }
                        }, constraints: inputData.constraints, color: inputData.color, infoPopover: infoPopover, placeholder: inputData.placeholder }) }))) : (_jsx(DynamicInput, { required: inputData.required, data: field.value, editMode: isEditing, inputType: inputData.inputType, label: inputData.label, choices: inputData.choices, isDisabled: inputData.isDisabled, errorMessage: error === null || error === void 0 ? void 0 : error.message, handleInputChange: (e) => {
                        field.onChange(e);
                        if (inputData.onChange) {
                            inputData.onChange(e);
                        }
                    }, constraints: inputData.constraints, color: inputData.color, infoPopover: infoPopover, placeholder: inputData.placeholder }));
            } }) })));
}
