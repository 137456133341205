var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Compressor from 'compressorjs';
import { supportedImageFormats } from '../config/constants';
import { isImage } from '../pages/Main/Sidebar/DetailsSidebar/utility/Validations';
export function useImageHandler() {
    const compressImage = (file) => __awaiter(this, void 0, void 0, function* () {
        if (!isImage(file.name, supportedImageFormats)) {
            console.warn(`File: ${file.name} is not in a supported image format ('.jpg', '.jpeg', '.png', '.gif') and cannot be compressed.`);
            return Promise.resolve(file);
        }
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                mimeType: file.type,
                success(result) {
                    resolve(new File([result], `${file.name}`, {
                        type: file.type,
                    }));
                },
                error(err) {
                    reject(err);
                },
            });
        }).catch((err) => {
            console.error('Error:', err);
            return Promise.reject('Error during image conversion and compression.');
        });
    });
    const compressImages = (files) => __awaiter(this, void 0, void 0, function* () {
        const promises = files.map((file) => compressImage(file));
        return Promise.all(promises).catch((err) => {
            console.error('Error:', err);
            return Promise.reject('Error during image conversion and compression.');
        });
    });
    return {
        compressImages,
        compressImage,
    };
}
