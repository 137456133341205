var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FacilityRole, OrganizationRole } from '../objects/FCAInterfaces';
import { SystemRole } from '../objects/UserData';
import { usePlatformUserApi } from '../services/api/platformUserApiHook';
import { hasAccess } from '../utils/userRoleUtils';
export const fetchUser = createAsyncThunk('users', () => __awaiter(void 0, void 0, void 0, function* () {
    const { getUser } = usePlatformUserApi();
    return yield getUser();
}));
export const initialUserState = {
    authUser: null,
    userData: { id: 'null' },
    access: true,
};
export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.userData = action.payload;
        });
        builder.addCase(fetchUser.rejected, (state) => {
            state.userData = undefined;
        });
    },
});
export const selectUser = (state) => state.user.userData;
export const selectAccess = (state) => {
    return hasAccess(state.user.userData, state.organization.organization);
};
export const getOrgRole = (user, orgId) => {
    var _a;
    const systemRole = user.systemRole;
    if (SystemRole.SUPER_ADMIN === systemRole ||
        SystemRole.ORG_APPROVER === systemRole) {
        return OrganizationRole.LOCAL_ADMIN;
    }
    return (_a = user.organizationRoles.find((role) => role.organizationId === orgId)) === null || _a === void 0 ? void 0 : _a.role;
};
export const getFacilityRole = (user, orgId, facilityId) => {
    var _a, _b;
    const systemRole = user.systemRole;
    if (SystemRole.SUPER_ADMIN === systemRole ||
        SystemRole.ORG_APPROVER === systemRole) {
        return FacilityRole.MEMBER;
    }
    const orgRole = (_a = user.organizationRoles.find((role) => role.organizationId === orgId)) === null || _a === void 0 ? void 0 : _a.role;
    if (!orgRole) {
        // All users must have an org role
        return;
    }
    if (OrganizationRole.LOCAL_ADMIN === orgRole ||
        OrganizationRole.CLIENT_CONCIERGE === orgRole) {
        return FacilityRole.MEMBER;
    }
    return (_b = user.facilityRoles.find((role) => role.facilityId === facilityId)) === null || _b === void 0 ? void 0 : _b.role;
};
export const selectOrgRole = (state) => {
    var _a;
    const user = state.user.userData;
    const orgId = (_a = state.organization.organization) === null || _a === void 0 ? void 0 : _a.id;
    if (!user || !orgId) {
        return;
    }
    return getOrgRole(user, orgId);
};
export const facilityRoleCanEdit = (role) => {
    return FacilityRole.MEMBER === role;
};
export const selectFacilityRole = (state) => {
    var _a, _b;
    const user = state.user.userData;
    const orgId = (_a = state.organization.organization) === null || _a === void 0 ? void 0 : _a.id;
    const facilityId = (_b = state.facility.facility) === null || _b === void 0 ? void 0 : _b.id;
    if (!user || !orgId || !facilityId) {
        return;
    }
    return getFacilityRole(user, orgId, facilityId);
};
export default userSlice.reducer;
