import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { selectCachingError, setCachingErrorAsToast, } from '../../store/dbCacheSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
const createRegToast = (text) => {
    return {
        title: 'Testing Notice',
        description: text,
        status: 'success',
        duration: 200000,
        isClosable: true,
    };
};
const createToastFromCacheError = (error) => {
    const errorName = error.inner ? error.inner.name : error.name;
    if (errorName === 'QuotaExceededError') {
        console.log('handling quota error', error);
        return {
            title: 'Offline memory limit exceeded',
            description: 'Please go online and upload data to free up storage space.',
            status: 'error',
            isClosable: true,
        };
    }
    else {
        // Any other error
        return {
            title: 'Download Data Error',
            description: `${errorName} was encountered while downloading data for offline use.`,
            status: 'error',
            isClosable: true,
        };
    }
};
export const broadcastCacheErrorToast = (error, broadcast) => {
    const errorToastOptions = createToastFromCacheError(error);
    broadcast.postMessage(errorToastOptions);
};
export const broadcastRegToast = (message, broadcast) => {
    const toastOptions = createRegToast(message);
    broadcast.postMessage(toastOptions);
};
const broadcast = new BroadcastChannel('cache-channel');
export const useCacheErrorToast = () => {
    const dispatch = useAppDispatch();
    const toast = useToast();
    const caching = useAppSelector(selectCachingError);
    broadcast.onmessage = (event) => {
        dispatch(setCachingErrorAsToast(event.data));
    };
    useEffect(() => {
        if (caching)
            toast(caching);
    }, [caching, toast]);
};
