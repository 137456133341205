import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Collapse, HStack, VStack } from '@chakra-ui/react';
import CompressIcon from '../../theme/icons/CompressIcon';
import DragHandleIcon from '../../theme/icons/DragHandleIcon';
import React, { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
const DEFAULT_WIDTH = 'auto';
const DEFAULT_HEIGHT = 'auto';
export const DraggableContainer = ({ initialSize = { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT }, initialPosition = { x: 0, y: 0 }, minimizedPosition, resizeable = false, title, children, defaultMinimized = false, canvasWidth = 0, }) => {
    const [isMinimized, setIsMinimized] = useState(defaultMinimized);
    const [currentPosition, setCurrentPosition] = useState(initialPosition);
    useEffect(() => {
        const containerElement = document.getElementById('draggable-container');
        if (containerElement) {
            const containerWidth = containerElement.offsetWidth;
            setCurrentPosition({
                x: canvasWidth - containerWidth,
                y: currentPosition.y,
            });
        }
    }, [canvasWidth]);
    const [currentSize, setCurrentSize] = useState(initialSize);
    useEffect(() => {
        if (isMinimized) {
            if (minimizedPosition) {
                setCurrentPosition(minimizedPosition);
            }
        }
        const containerElement = document.getElementById('draggable-container');
        const parent = containerElement === null || containerElement === void 0 ? void 0 : containerElement.parentElement;
        const containerRect = containerElement === null || containerElement === void 0 ? void 0 : containerElement.getBoundingClientRect();
        const parentRect = parent === null || parent === void 0 ? void 0 : parent.getBoundingClientRect();
        if (parentRect && containerRect) {
            // Check if child is outside the bounds of parent
            if (containerRect.top < parentRect.top ||
                containerRect.left < parentRect.left ||
                containerRect.bottom > parentRect.bottom ||
                containerRect.right > parentRect.right) {
                const newYPosition = initialPosition.y === 0
                    ? 0
                    : initialPosition.y - containerRect.height;
                let containerOffset = 0;
                if (containerElement && canvasWidth !== 0) {
                    containerOffset = containerElement === null || containerElement === void 0 ? void 0 : containerElement.offsetWidth;
                }
                // If child is outside the bounds of parent, move to initial
                setCurrentPosition({
                    x: initialPosition.x - containerOffset,
                    y: newYPosition,
                });
            }
        }
    }, [isMinimized]);
    const handleDragStop = (_, d) => {
        setCurrentPosition({ x: d.x, y: d.y });
    };
    const handleResizeStop = (_e, _direction, ref, _delta, position) => {
        const newSize = {
            width: ref.style.width,
            height: ref.style.height,
        };
        setCurrentSize(newSize);
        setCurrentPosition((_prevPosition) => ({
            x: position.x,
            y: position.y,
        }));
        if (isMinimized) {
            // if the container is minimized when we resize, we un-minimize it
            setIsMinimized(false);
        }
    };
    return (_jsx(Rnd, Object.assign({ size: currentSize, bounds: 'parent', enableResizing: resizeable, position: { x: currentPosition.x, y: currentPosition.y }, onDragStop: handleDragStop, onResizeStop: handleResizeStop, dragHandleClassName: 'dragHandle', style: { position: 'absolute' }, minWidth: '14vw', id: 'draggable-container' }, { children: _jsxs(VStack, Object.assign({ spacing: 0, bgColor: 'white', borderRadius: 'md', boxShadow: '0px 0px 6px rgba(0, 0, 0, .25)', overflow: 'hidden', zIndex: 9, align: 'stretch' }, { children: [_jsxs(HStack, Object.assign({ spacing: 0, h: '2rem', p: '0.25rem 0.5rem', justify: 'space-between', alignItems: 'center', bgColor: 'white', borderRadius: 'md', overflow: 'hidden', flexShrink: 0 }, { children: [_jsxs(HStack, Object.assign({ spacing: 2, alignItems: 'center' }, { children: [_jsx(DragHandleIcon, { className: 'dragHandle', cursor: 'grab', transform: 'scale(1.5)' }), _jsx(Box, Object.assign({ fontWeight: '600', whiteSpace: 'nowrap' }, { children: title }))] })), _jsx(CompressIcon, { onClick: () => {
                                setIsMinimized(!isMinimized);
                            }, cursor: 'pointer', transform: 'scale(1.5)' })] })), _jsx(Collapse, Object.assign({ in: !isMinimized }, { children: _jsx(Box, Object.assign({ p: '1rem', overflow: 'auto' }, { children: children })) }))] })) }), isMinimized ? 'true' : 'false'));
};
