import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, IconButton, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
const collapsedWidth = 30;
export const Sidebar = ({ children, placement, width, override, }) => {
    const [currWidth, setCurrWidth] = useState(width);
    const [open, setOpen] = useState(true);
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
    const [rightSidebarOpen, setRightSidebarOpen] = useState(true);
    useEffect(() => {
        override && setOpen(override);
    }, [override]);
    useEffect(() => {
        setCurrWidth(open ? width : collapsedWidth);
    }, [open]);
    const renderOpenCloseBtn = () => {
        if (placement === 'left') {
            return (_jsx(IconButton, { onClick: () => {
                    setOpen(!open);
                    setLeftSidebarOpen(!leftSidebarOpen);
                }, icon: open ? (_jsx(ChevronLeftIcon, { boxSize: '25px' })) : (_jsx(ChevronRightIcon, { boxSize: '25px' })), "aria-label": `${open ? 'close' : 'open'}-sidebar`, position: 'absolute', right: '-20px', top: '45%', opacity: 0.9, boxSizing: 'border-box', borderRadius: '50%', boxSize: '30px', p: 0, m: 0 }));
        }
        else {
            return (_jsx(IconButton, { onClick: () => {
                    setOpen(!open);
                    setRightSidebarOpen(!rightSidebarOpen);
                }, icon: open ? (_jsx(ChevronRightIcon, { boxSize: '25px' })) : (_jsx(ChevronLeftIcon, { boxSize: '25px' })), "aria-label": `${open ? 'close' : 'open'}-sidebar`, position: 'absolute', left: '-20px', top: '45%', opacity: 0.9, boxSizing: 'border-box', borderRadius: '50%', boxSize: '30px', p: 0, m: 0 }));
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ bg: 'misc.sidebarWhite', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));', h: '100%', textAlign: 'left', w: `${currWidth}px`, pos: 'sticky', left: placement === 'left' ? 0 : undefined, right: placement === 'right' ? 0 : undefined, zIndex: 99, maxW: `${currWidth}px`, minW: `${currWidth}px` }, { children: [_jsx(Stack, Object.assign({ h: '100%', justifyContent: 'space-between', pb: '27px', display: open ? 'visible' : 'none' }, { children: children })), renderOpenCloseBtn()] })) }));
};
