import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@chakra-ui/react';
import { CheckboxInput } from '@frontend/design-system/components/DynamicInput/CheckboxInput';
import { NumberDynamicInput } from '@frontend/design-system/components/DynamicInput/NumberDynamicInput';
import { useEffect, useState } from 'react';
import { stringToInt } from '../../../../conversions/AssetConversions';
export const RoomOccupiedInput = ({ editMode, data, handleInputChange, required = false, errorMessage, }) => {
    const [isChecked, setIsChecked] = useState(false);
    const onCheckBox = (isChecked) => {
        if (isChecked) {
            handleInputChange('1');
        }
        else {
            handleInputChange('0');
        }
        setIsChecked(isChecked);
    };
    useEffect(() => {
        setIsChecked(data && data !== '0' ? true : false);
    }, [data]);
    return (_jsxs(Stack, Object.assign({ w: '100%' }, { children: [_jsx(CheckboxInput, { label: 'Occupied Room?', editMode: editMode, data: isChecked, onInputChange: onCheckBox, required: required, errorMessage: errorMessage }), isChecked && (_jsx(NumberDynamicInput, { label: '# of Occupants', editMode: editMode, data: stringToInt(data) || 1, errorMessage: errorMessage, onInputChange: handleInputChange, required: required, constraints: { precision: 0, min: 1, max: 100 } }))] })));
};
