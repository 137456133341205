import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useFacilityContext } from '../../../../../contexts/Facilities/FacilityPageContext';
import { PageModes, } from '../../../../../objects/FCAInterfaces';
import { DynamicFormInput, } from '../../Inputs/DynamicFormInput';
import { AssetFormSubquestions } from './AssetFormSubquestions';
export function AssetForm({ formInputs, canEdit, isEditing, assetTemplate, assetQuestions, path, isFirstAssetOfClass = true, }) {
    const { pageMode, setPageMode } = useFacilityContext();
    const { control } = useFormContext();
    const prefixPath = path ? `${path}.` : '';
    const notes = {
        name: `${prefixPath}notes`,
        inputType: 'textarea',
        label: 'Notes',
        placeholder: 'Type here to write your notes',
    };
    return (_jsxs(VStack, Object.assign({ "data-testid": 'asset-form-inputs', alignItems: 'flex-start', w: '100%', onClick: () => {
            if (canEdit && pageMode !== PageModes.ADD_ASSET) {
                setPageMode(PageModes.EDIT);
            }
        }, spacing: 4 }, { children: [formInputs.map((formInput, i) => (_jsx(DynamicFormInput, { inputData: formInput, control: control, isEditing: isEditing, canEdit: canEdit }, `asset-form-inputs-${i}`))), (assetTemplate === null || assetTemplate === void 0 ? void 0 : assetTemplate.survey) && assetTemplate.survey.length > 0 && (_jsx(AssetFormSubquestions, { path: `${prefixPath}questions`, assetQuestions: assetQuestions, assetTemplate: assetTemplate, control: control, isFirstAssetOfClass: isFirstAssetOfClass })), _jsx(DynamicFormInput, { inputData: notes, control: control, isEditing: isEditing })] })));
}
