var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SystemRole } from '../objects/UserData';
const baseUrlPlatformAPI = '/User';
export default class UserService {
    constructor(platformApi) {
        this.platformApi = platformApi;
        this.getContactFromPlatformUser = (platformUser) => {
            return {
                id: platformUser.userId,
                firstName: platformUser.firstName,
                lastName: platformUser.lastName,
                email: platformUser.emailAddress,
            };
        };
    }
    getUser() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.platformApi
                .get(`${baseUrlPlatformAPI}/view`)
                .then((platformUser) => this.getUserFromPlatformUser(platformUser));
        });
    }
    getUserFromPlatformUser(platformUser) {
        var _a, _b, _c, _d;
        return {
            id: platformUser.userId,
            firstName: platformUser.firstName,
            lastName: platformUser.lastName,
            email: platformUser.emailAddress,
            systemRole: (_a = platformUser.systemRole) !== null && _a !== void 0 ? _a : SystemRole.USER,
            organizationRoles: (_b = platformUser.organizationRoles) !== null && _b !== void 0 ? _b : [],
            facilityRoles: (_c = platformUser.facilityRoles) !== null && _c !== void 0 ? _c : [],
            apps: (_d = platformUser.apps) !== null && _d !== void 0 ? _d : [],
        };
    }
}
