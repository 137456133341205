var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFacility, getPlans, updateFacility, } from '../services/api/facilityAPI';
const initialFacility = undefined;
const plans = undefined;
export const fetchFacility = createAsyncThunk('facilities/id/fetch', (fidentity) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getFacility(fidentity);
}));
export const fetchPlans = createAsyncThunk('facilities/plans/fetch', (fidentity) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getPlans(fidentity);
}));
export const editFacility = createAsyncThunk('users/editFacility', ({ id, org }) => __awaiter(void 0, void 0, void 0, function* () {
    return updateFacility(id, org);
}));
export const facilitySlice = createSlice({
    name: 'facility',
    initialState: {
        facility: initialFacility,
        plans: plans,
    },
    reducers: {
        setFacility: (state, { payload: facility }) => {
            state.facility = facility;
        },
        setPlans: (state, { payload: plans }) => {
            state.plans = plans;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFacility.fulfilled, (state, action) => {
            state.facility = action.payload;
            state.plans = action.payload.plans;
        });
        builder.addCase(fetchPlans.fulfilled, (state, action) => {
            state.plans = action.payload;
        });
    },
});
export const selectFacility = (state) => state.facility.facility;
export const selectPlans = (state) => state.facility.plans;
export const { setFacility, setPlans } = facilitySlice.actions;
export default facilitySlice.reducer;
