var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Apps, OrganizationRole, } from '../objects/FCAInterfaces';
import { SystemRole } from '../objects/UserData';
import { getAllOrgs, 
// getAllOrgsSnapshots,
getOrg, getSitesForOrg, } from '../services/api/organizationAPI';
import { usePlatformOrgApi } from '@/services/api/platformOrgApiHook';
const ORG_RELATIONS = ['sites', 'sites.facilities', 'apps'];
export const filterFacilities = (sites, user, orgId) => {
    const orgRole = user.organizationRoles.find((role) => orgId === role.organizationId);
    if (SystemRole.SUPER_ADMIN === user.systemRole ||
        SystemRole.ORG_APPROVER === user.systemRole ||
        (orgRole &&
            (OrganizationRole.LOCAL_ADMIN === orgRole.role ||
                OrganizationRole.CLIENT_CONCIERGE === orgRole.role))) {
        return sites;
    }
    if (!user.facilityRoles) {
        return [];
    }
    const filteredSites = sites.reduce((sitesAcc, site) => {
        if (!site.facilities) {
            return sitesAcc;
        }
        const facilities = site.facilities.filter((facility) => user.facilityRoles.some((facilityRole) => facility.id === facilityRole.facilityId));
        if (facilities.length < 1) {
            return sitesAcc;
        }
        else {
            return [...sitesAcc, Object.assign(Object.assign({}, site), { facilities })];
        }
    }, []);
    return filteredSites;
};
export const fetchOrg = createAsyncThunk('organizations/id/fetch', ({ id }) => __awaiter(void 0, void 0, void 0, function* () {
    const org = yield getOrg(id, ['apps']);
    return org;
}));
export const fetchOrganizationSnapshots = createAsyncThunk('organizations-snapshots/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    const { getOrganizationSnapshots } = usePlatformOrgApi();
    const orgs = yield getOrganizationSnapshots();
    const sortedOrgs = orgs.sort((a, b) => a.name.localeCompare(b.name));
    return sortedOrgs;
}));
export const fetchAllOrgs = createAsyncThunk('organizations/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    return yield getAllOrgs(ORG_RELATIONS);
}));
export const filterUserOrgApps = (orgs, user) => {
    return orgs.filter((org) => {
        var _a;
        const orgRole = user === null || user === void 0 ? void 0 : user.organizationRoles.find((orgRole) => orgRole.organizationId === org.id);
        if ((orgRole === null || orgRole === void 0 ? void 0 : orgRole.role) === OrganizationRole.LOCAL_ADMIN ||
            (orgRole === null || orgRole === void 0 ? void 0 : orgRole.role) === OrganizationRole.CLIENT_CONCIERGE) {
            return (_a = org.apps) === null || _a === void 0 ? void 0 : _a.find((app) => Apps.FCA === app.app);
        }
        return user === null || user === void 0 ? void 0 : user.apps.some((orgApp) => Apps.FCA === orgApp.app && org.id === orgApp.organizationId);
    });
};
export const fetchAllUserOrgs = createAsyncThunk('user/organizations/fetch', (user) => __awaiter(void 0, void 0, void 0, function* () {
    const orgs = yield getAllOrgs(ORG_RELATIONS);
    return filterUserOrgApps(orgs, user);
}));
export const fetchSites = createAsyncThunk('organizations/id/sites/fetch', ({ orgId, user }) => __awaiter(void 0, void 0, void 0, function* () {
    return getSitesForOrg(orgId).then((sites) => {
        return filterFacilities(sites, user, orgId);
    });
}));
export const initialOrgState = {
    authOrg: null,
    organization: undefined,
    allOrgs: [],
    allOrgsSnapshots: [],
    updateAvail: false,
    showAgeAdjustedCondition: true,
    sites: [],
    sitesLoading: false,
};
export const orgSlice = createSlice({
    name: 'organization',
    initialState: initialOrgState,
    reducers: {
        setOrganization: (state, { payload: organization }) => {
            state.organization = organization;
        },
        setShowAgeAdjustment: (state, { payload: showAgeAdjustment }) => {
            state.showAgeAdjustedCondition = showAgeAdjustment;
        },
        setAllOrgs: (state, { payload: orgs }) => {
            state.allOrgs = orgs ? orgs : [];
        },
        setUpdateAvail: (state, { payload: updateAvail }) => {
            state.updateAvail = updateAvail;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrganizationSnapshots.fulfilled, (state, action) => {
            state.allOrgsSnapshots = action.payload;
        });
        builder.addCase(fetchAllOrgs.fulfilled, (state, action) => {
            state.allOrgs = action.payload;
        });
        builder.addCase(fetchOrg.fulfilled, (state, action) => {
            state.organization = action.payload;
        });
        builder.addCase(fetchAllUserOrgs.fulfilled, (state, action) => {
            state.allOrgs = action.payload;
            state.allOrgsSnapshots = action.payload.map((o) => ({
                id: o.id,
                orgIdentity: o.orgIdentity,
                name: o.name,
                apps: o.apps,
            }));
        });
        builder.addCase(fetchSites.fulfilled, (state, action) => {
            state.sites = action.payload;
            state.sitesLoading = false;
        });
        builder.addCase(fetchSites.pending, (state) => {
            state.sitesLoading = true;
        });
        builder.addCase(fetchSites.rejected, (state) => {
            state.sitesLoading = false;
        });
    },
});
export const selectOrganization = (state) => {
    return state.organization.organization;
};
export const selectShowAgeAdjustment = (state) => state.organization.showAgeAdjustedCondition;
export const selectAllOrgsSnapshots = (state) => state.organization.allOrgsSnapshots;
export const selectAllOrgs = (state) => state.organization.allOrgs;
export const selectUpdateAvail = (state) => state.organization.updateAvail;
export const selectSites = (state) => {
    return {
        sites: state.organization.sites,
        loading: state.organization.sitesLoading,
    };
};
export const { setOrganization, setAllOrgs, setUpdateAvail, setShowAgeAdjustment, } = orgSlice.actions;
export default orgSlice.reducer;
