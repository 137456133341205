import { jsx as _jsx } from "react/jsx-runtime";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Flex, Spinner } from '@chakra-ui/react';
import { NoAccessPage } from '../../pages/Selection/NoAccessPage';
export const AuthenticationGuard = ({ component, appAccess = true, }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (_jsx(Flex, Object.assign({ h: '100vh', w: 'full', justifyContent: 'center', alignItems: 'center' }, { children: _jsx(Spinner, {}) }))),
    });
    return appAccess ? _jsx(Component, {}) : _jsx(NoAccessPage, {});
};
