import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { slgqColors } from '@/config/theme';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, HStack, Image, Link, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text, VStack, } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import co2 from '../../../../../../images/Why_CO2.png';
import { TimeseriesButtons } from '../../Timeseries/TimeseriesButtons';
import { CO2InputsHandler } from './CO2InputsHandler';
export const CO2FormWrapper = ({ control, co2IeqInput, isEditing, canEdit, }) => {
    const renderInfoIcon = () => {
        return (_jsxs(Popover, Object.assign({ placement: 'bottom', closeOnBlur: true, trigger: 'hover' }, { children: [_jsx(PopoverTrigger, { children: _jsx(InfoOutlineIcon, { ml: '20px', w: '15px', h: '15px' }) }), _jsxs(PopoverContent, Object.assign({ maxW: '280px' }, { children: [_jsx(PopoverArrow, { bg: 'misc.sectionGrey' }), _jsx(PopoverBody, Object.assign({ bg: 'misc.sectionGrey' }, { children: _jsxs(Box, Object.assign({ width: '100%' }, { children: [_jsx(Text, Object.assign({ mb: '10px' }, { children: _jsxs(Link, Object.assign({ as: 'a', href: 'https://www.sitelogiq.com/blog/the-importance-of-testing-and-monitoring-your-indoor-air-quality/', color: slgqColors.primBlue, target: '_blank', fontSize: '14px' }, { children: ["Recording CO", _jsx("sub", { children: "2" })] })) })), _jsx(Image, { src: co2, width: '100%' })] })) }))] }))] })));
    };
    return (_jsx(HStack, { children: _jsx(Controller, { name: 'cO2', control: control, render: ({ field, fieldState: { error } }) => {
                return !isEditing ? (_jsx(TimeseriesButtons, Object.assign({ canEdit: canEdit, timeseriesConfig: co2IeqInput.timeseriesConfig, name: co2IeqInput.name }, { children: _jsx(CO2InputsHandler, { co2IeqInput: co2IeqInput, renderInfoIcon: renderInfoIcon(), data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, handleInputChange: (e) => {
                            field.onChange(e);
                        }, editMode: isEditing }) }))) : (_jsx(VStack, Object.assign({ justifyContent: 'space-between', position: 'relative', w: '100%' }, { children: _jsx(CO2InputsHandler, { co2IeqInput: co2IeqInput, renderInfoIcon: renderInfoIcon(), data: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, handleInputChange: (e) => {
                            field.onChange(e);
                        }, editMode: isEditing }) })));
            } }, 'dynamic_input_co2') }));
};
