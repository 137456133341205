import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { GalleryContext } from './GalleryContext';
export function GalleryProvider({ children, allFacilities, allSpaces, allAssets, }) {
    // request cache
    const spaces = allSpaces.sort((a, b) => {
        return a.name.localeCompare(b.name, 'en-u-kn-true');
    });
    const assets = allAssets.sort((a, b) => {
        return a.name.localeCompare(b.name, 'en-u-kn-true');
    });
    // dropdown lists
    const facilityDropdown = allFacilities.sort((a, b) => {
        return a.name.localeCompare(b.name, 'en-u-kn-true');
    });
    const [spaceYearDropdown, setSpaceYearDropdown] = useState([]);
    const [spaceDropdown, setSpaceDropdown] = useState([]);
    const [assetCategoryDropdown, setAssetCategoryDropdown] = useState([]);
    const [assetTypeDropdown, setAssetTypeDropdown] = useState([]);
    const [assetYearDropdown, setAssetYearDropdown] = useState([]);
    const [assetDropdown, setAssetDropdown] = useState([]);
    const initializeDropdowns = (filterObj) => {
        onFacilityDropdownSelection(filterObj);
    };
    const onFacilityDropdownSelection = (filterObj) => {
        // derived from facility drop down filtered by selected facilities
        const facilityFilters = filterObj.Facility
            ? Array.isArray(filterObj.Facility)
                ? filterObj.Facility
                : [filterObj.Facility]
            : [];
        const facilityIdentities = facilityDropdown
            .filter((ref) => {
            if (facilityFilters.length > 0) {
                return facilityFilters.some((filter) => filter && filter.value === ref.identity);
            }
            return true;
        })
            .map((ref) => ref.identity);
        const filteredSpaces = spaces.filter((space) => facilityIdentities.includes(space.facilityIdentity));
        setSpaceYearDropdown(filteredSpaces);
        const newDropdownList = onSpaceYearDropdownSelection(filteredSpaces, filterObj);
        return {
            'Space Construction Year': filteredSpaces,
            Space: newDropdownList.Space,
            Category: newDropdownList.Category,
            'Asset Type': newDropdownList['Asset Type'],
            'Install Year': newDropdownList['Install Year'],
            Asset: newDropdownList.Asset,
        };
    };
    const onSpaceYearDropdownSelection = (referenceList, filterObj) => {
        // all spaces within space year dropdown, filtered by selected space years
        const spaceYearFilter = filterObj['Space Construction Year']
            ? Array.isArray(filterObj['Space Construction Year'])
                ? filterObj['Space Construction Year']
                : [filterObj['Space Construction Year']]
            : [];
        const filteredSpaces = referenceList.filter((ref) => {
            if (spaceYearFilter.length > 0) {
                return spaceYearFilter.some((filter) => filter && filter.value === ref.constrYear);
            }
            return true;
        });
        setSpaceDropdown(filteredSpaces);
        const newDropdownList = onSpaceDropdownSelection(filteredSpaces, filterObj);
        return {
            Space: filteredSpaces,
            Category: newDropdownList.Category,
            'Asset Type': newDropdownList['Asset Type'],
            'Install Year': newDropdownList['Install Year'],
            Asset: newDropdownList.Asset,
        };
    };
    const onSpaceDropdownSelection = (referenceList, filterObj) => {
        // all spaces within space drop down filtered by selected spaces
        const spaceFilters = filterObj.Space
            ? Array.isArray(filterObj.Space)
                ? filterObj.Space
                : [filterObj.Space]
            : [];
        const spaceIdentities = referenceList
            .filter((ref) => {
            if (spaceFilters.length > 0) {
                return spaceFilters.some((filter) => filter && filter.value === ref.identity);
            }
            return true;
        })
            .map((ref) => ref.identity);
        const filteredAssets = assets.filter((asset) => spaceIdentities.includes(asset.spaceIdentity));
        setAssetCategoryDropdown(filteredAssets);
        const newDropdownList = onAssetCategoryDropdownSelection(filteredAssets, filterObj);
        return {
            Category: filteredAssets,
            'Asset Type': newDropdownList['Asset Type'],
            'Install Year': newDropdownList['Install Year'],
            Asset: newDropdownList.Asset,
        };
    };
    const onAssetCategoryDropdownSelection = (referenceList, filterObj) => {
        // all assets within category dropdown filtered by selected categories
        const categoryFilter = filterObj.Category
            ? Array.isArray(filterObj.Category)
                ? filterObj.Category
                : [filterObj.Category]
            : [];
        const filteredAssets = referenceList.filter((ref) => {
            if (categoryFilter.length > 0) {
                return categoryFilter.some((filter) => filter && filter.value === ref.category);
            }
            return true;
        });
        setAssetTypeDropdown(filteredAssets);
        const newDropdownList = onAssetTypeDropdownSelection(filteredAssets, filterObj);
        return {
            'Asset Type': filteredAssets,
            'Install Year': newDropdownList['Install Year'],
            Asset: newDropdownList.Asset,
        };
    };
    const onAssetTypeDropdownSelection = (referenceList, filterObj) => {
        // all assets within asset type dropdown filtered by selected asset types
        const assetTypeFilter = filterObj['Asset Type']
            ? Array.isArray(filterObj['Asset Type'])
                ? filterObj['Asset Type']
                : [filterObj['Asset Type']]
            : [];
        const filteredAssets = referenceList.filter((ref) => {
            if (assetTypeFilter.length > 0) {
                return assetTypeFilter.some((filter) => filter && filter.value === ref.assetType);
            }
            return true;
        });
        setAssetYearDropdown(filteredAssets);
        const newDropdownList = onAssetYearDropdownSelection(filteredAssets, filterObj);
        return {
            'Install Year': filteredAssets,
            Asset: newDropdownList.Asset,
        };
    };
    const onAssetYearDropdownSelection = (referenceList, filterObj) => {
        // all assets within asset year dropdown filtered by selected asset years
        const assetYearFilter = filterObj['Install Year']
            ? Array.isArray(filterObj['Install Year'])
                ? filterObj['Install Year']
                : [filterObj['Install Year']]
            : [];
        const filteredAssets = referenceList.filter((ref) => {
            if (assetYearFilter.length > 0) {
                return assetYearFilter.some((filter) => filter && filter.value === ref.installYear);
            }
            return true;
        });
        setAssetDropdown(filteredAssets);
        return {
            Asset: filteredAssets,
        };
    };
    return (_jsx(GalleryContext.Provider, Object.assign({ value: {
            facilityDropdown,
            spaceYearDropdown,
            spaceDropdown,
            assetCategoryDropdown,
            assetTypeDropdown,
            assetYearDropdown,
            assetDropdown,
            initializeDropdowns,
            onFacilityDropdownSelection,
            onSpaceYearDropdownSelection,
            onSpaceDropdownSelection,
            onAssetCategoryDropdownSelection,
            onAssetTypeDropdownSelection,
            onAssetYearDropdownSelection,
        } }, { children: children })));
}
