var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { variants } from '@frontend/design-system/theme/theme';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { slgqColors } from '../../../../../../config/theme';
export const ReassessModal = ({ children, isOpen, onClose, reassessConfig, type, }) => {
    const [inputValue, setInputValue] = useState();
    const [dateValue, setDateValue] = useState('');
    const [isFutureDate, setIsFutureDate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onCloseModal = () => {
        setInputValue(undefined);
        setIsFutureDate(false);
        onClose();
    };
    useEffect(() => {
        setDateValue(moment(new Date().getTime()).utc(false).format());
    }, [isOpen]);
    const onModalInputChange = (value) => {
        setInputValue(value);
    };
    const handleDateChange = (e) => {
        const formattedDate = moment(e.target.value).utc(false).format();
        setDateValue(formattedDate);
        // Check if the selected date is in the future
        const selectedDateTime = new Date(e.target.value).getTime();
        const currentDateTime = new Date().getTime();
        setIsFutureDate(selectedDateTime > currentDateTime);
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        // Do we need the other types on the reassessCall now?
        reassessConfig.reassessCall(inputValue, dateValue, type);
        onCloseModal();
        setIsLoading(false);
    });
    const allInputsCloned = React.cloneElement(children, {
        handleInputChange: onModalInputChange,
        editMode: true,
        data: inputValue,
        required: true,
    });
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onCloseModal, isCentered: true }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, Object.assign({ maxW: '500px', bg: slgqColors.primWhite }, { children: [_jsx(ModalHeader, { children: _jsx(Text, Object.assign({ mt: '1rem', color: '#2E3532', fontSize: '36px', fontWeight: '400' }, { children: reassessConfig.modalLabel })) }), _jsx(ModalCloseButton, {}), _jsx(Stack, Object.assign({ padding: '25px' }, { children: _jsxs("form", { children: [allInputsCloned, _jsxs(FormControl, Object.assign({ isRequired: true }, { children: [_jsx(FormLabel, Object.assign({ marginTop: '10px' }, { children: "Date" })), _jsx(Input, { "data-testid": 'modal-date-input', type: 'datetime-local', max: moment().format('YYYY-MM-DD'), style: {
                                                boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15) inset',
                                            }, value: moment(dateValue).format('YYYY-MM-DDTHH:mm'), onChange: handleDateChange }), isFutureDate && (_jsx("span", Object.assign({ style: { color: 'red' } }, { children: "Selected date is in the future" })))] })), _jsxs(HStack, Object.assign({ paddingTop: '15px', justifyContent: 'space-between' }, { children: [_jsx(Button, Object.assign({ variant: variants.transparentBlueBtn, onClick: onCloseModal, borderColor: '#DA1100', color: 'red' }, { children: "Cancel" })), _jsx(Button, Object.assign({ isDisabled: isFutureDate ||
                                                inputValue === '' ||
                                                inputValue === undefined ||
                                                dateValue === ''
                                                ? true
                                                : false, variant: variants.blueBtn, onClick: handleSubmit, "data-testid": 'modal-save-button' }, { children: "Save" }))] }))] }) })), _jsx(LoadingWithControl, { isLoading: isLoading })] }))] })));
};
