var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSite, getSiteEverything } from '../services/api/siteAPI';
const initialSite = undefined;
export const fetchSite = createAsyncThunk('sites/id/fetch', (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getSite(id);
}));
export const fetchSiteEverything = createAsyncThunk('sites/id/everything/fetch', (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield getSiteEverything(id);
}));
export const siteSlice = createSlice({
    name: 'site',
    initialState: {
        site: initialSite,
    },
    reducers: {
        setSite: (state, { payload: site }) => {
            state.site = site;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSite.fulfilled, (state, action) => {
            state.site = action.payload;
        });
        builder.addCase(fetchSiteEverything.fulfilled, (state, action) => {
            state.site = action.payload;
        });
    },
});
export const selectSite = (state) => {
    return state.site.site;
};
export const { setSite } = siteSlice.actions;
export default siteSlice.reducer;
