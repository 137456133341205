var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import services from '..';
import { toCleanFileName } from '../../utils/photoFileUtils';
export function getPhoto(phid) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.photoService.getPhoto(phid);
    });
}
export function createPhotos(photoFiles, createPhotoIdentities, selectedTags, selectedDate) {
    return __awaiter(this, void 0, void 0, function* () {
        const photos = convertPhotoFileToCreatePhoto(photoFiles, createPhotoIdentities, selectedTags, selectedDate);
        return services.photoService.createPhotos(photos);
    });
}
export function updatePhoto(phid, photo) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.photoService.updatePhoto(phid, photo);
    });
}
export function addComment(phid, comment) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.photoService.addComment(phid, comment);
    });
}
export function deletePhoto(id) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield services.photoService.deletePhoto(id);
    });
}
export function getPhotosByOrg(orgIdentity, filters) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.photoService.getPhotosByOrg(orgIdentity, filters);
    });
}
export function addTagToPhoto(photoIdentity, tagIdentity) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.photoService.addTagToPhoto(photoIdentity, tagIdentity);
    });
}
export function deletePhotoTag(photoIdentity, tagIdentity) {
    return __awaiter(this, void 0, void 0, function* () {
        return services.photoService.deletePhotoTag(photoIdentity, tagIdentity);
    });
}
export const convertPhotoFileToCreatePhoto = (photoFiles, createPhotoIdentities, selectedTags, selectedDate) => {
    const photosToAdd = photoFiles.map((namedFile) => {
        return Object.assign({ name: namedFile.appendString +
                toCleanFileName(namedFile.file.name), tags: selectedTags, date: selectedDate }, createPhotoIdentities);
    });
    return photosToAdd;
};
export const movePhotosLocation = (locationData) => {
    return services.photoService.movePhotosLocation(locationData);
};
