import { extractQualityMetrics } from '../../conversions/SpaceConversions';
export const convertSpaceDetailResponseToSpaceCopySnapshot = (spaces, assets) => {
    if (spaces.length !== 1) {
        console.warn('Unexpected number of spaces returned in response');
    }
    const space = spaces[0];
    const qualityMetrics = extractQualityMetrics(space);
    const spaceIEQ = {
        class: 'IEQ',
        propertiesToCopy: [],
    };
    const spaceDetail = {
        class: 'Space',
        propertiesToCopy: [],
    };
    if (qualityMetrics.light) {
        const light = { label: 'Light', value: 'Light', type: 'IEQ' };
        spaceIEQ.propertiesToCopy = [...spaceIEQ.propertiesToCopy, light];
    }
    if (qualityMetrics.sound) {
        const sound = { label: 'Sound', value: 'Sound', type: 'IEQ' };
        spaceIEQ.propertiesToCopy = [...spaceIEQ.propertiesToCopy, sound];
    }
    if (qualityMetrics.co2) {
        const co2 = { label: 'CO\u2082', value: 'CO2', type: 'IEQ' };
        spaceIEQ.propertiesToCopy = [...spaceIEQ.propertiesToCopy, co2];
    }
    if (qualityMetrics.temperature) {
        const temperature = {
            label: 'Temperature',
            value: 'Temperature',
            type: 'IEQ',
        };
        spaceIEQ.propertiesToCopy = [...spaceIEQ.propertiesToCopy, temperature];
    }
    if (qualityMetrics.humidity) {
        const humidity = {
            label: 'Humidity',
            value: 'Humidity',
            type: 'IEQ',
        };
        spaceIEQ.propertiesToCopy = [...spaceIEQ.propertiesToCopy, humidity];
    }
    if (space.constructionYear) {
        const construction = {
            label: 'Construction Year',
            value: 'ConstructionYear',
            type: 'Space',
        };
        spaceDetail.propertiesToCopy = [
            ...spaceDetail.propertiesToCopy,
            construction,
        ];
    }
    if (space.spaceType) {
        const spaceType = {
            label: 'Space Type & Room Function',
            value: 'SpaceType',
            type: 'Space',
        };
        spaceDetail.propertiesToCopy = [
            ...spaceDetail.propertiesToCopy,
            spaceType,
        ];
    }
    if (space.spaceArea) {
        const spaceArea = {
            label: 'Space Area (ft\u00B2)',
            value: 'SpaceArea',
            type: 'Space',
        };
        spaceDetail.propertiesToCopy = [
            ...spaceDetail.propertiesToCopy,
            spaceArea,
        ];
    }
    const facilityAssets = space.servedBy.map((facilityAsset) => facilityAsset);
    return {
        spaceDetail: spaceDetail,
        spaceIEQ: spaceIEQ,
        standardAssets: assets,
        facilityAssets: convertFacilityAssetsToFacilityAssetCopyOptions(facilityAssets),
    };
};
const convertFacilityAssetsToFacilityAssetCopyOptions = (facilityAssetsList) => {
    return facilityAssetsList.map((facilityAsset) => ({
        label: facilityAsset.name,
        value: facilityAsset.assetId.toString(),
        type: 'Facility Asset',
    }));
};
const convertToSpaceCopyRequestValuesGroupedByType = (details, type) => {
    const propertiesToReturn = details.filter((property) => type.includes(property.type));
    return propertiesToReturn.map((property) => property.value);
};
export const convertCopiedSpaceToSpaceCopyRequest = (spaceIdentity, copyDetails, selectedSpaceIdentities) => {
    const spacePropertiesToCopy = convertToSpaceCopyRequestValuesGroupedByType(copyDetails, ['Space', 'IEQ']);
    const standardAssetTypes = [
        ...new Set(copyDetails
            .filter((detail) => detail.type !== 'Space' &&
            detail.type !== 'IEQ' &&
            detail.type !== 'Facility Asset')
            .map((detail) => detail.type)),
    ];
    const spaceAssetCopyRequests = standardAssetTypes.map((assetType) => {
        return {
            assetClassName: assetType,
            propertiesToCopy: convertToSpaceCopyRequestValuesGroupedByType(copyDetails, [assetType]),
        };
    });
    const targetFacilityAssetIds = copyDetails
        .filter((detail) => detail.type === 'Facility Asset')
        .map((detail) => Number(detail.value));
    return {
        sourceSpaceId: spaceIdentity,
        propertiesToCopy: spacePropertiesToCopy,
        targetSpaceIds: selectedSpaceIdentities,
        assetsToCopy: spaceAssetCopyRequests,
        facilityAssetIds: targetFacilityAssetIds,
    };
};
export const convertSpaceSummaryResponseToCurrentSpaces = (spaces) => {
    return spaces;
};
export const convertAssetDetailResponseToAssetCopySnapshot = (assets) => {
    const propertiesToCopy = [
        {
            key: 'condition',
            label: 'Condition',
            value: 'Condition',
        },
        {
            key: 'approxYear',
            label: 'Install Year',
            value: 'InstallYear',
        },
        {
            key: 'questions',
            label: 'Asset Questions',
            value: 'Survey',
        },
    ];
    const assetMap = new Map();
    const ensureAssetPropertyExists = (assetClass) => {
        let assetProperty = assetMap.get(assetClass);
        if (!assetProperty) {
            assetProperty = {
                class: assetClass,
                propertiesToCopy: [],
            };
            assetMap.set(assetClass, assetProperty);
        }
        return assetProperty;
    };
    assets.forEach((asset) => {
        const assetClass = asset.assetClass;
        const assetProperty = ensureAssetPropertyExists(assetClass);
        propertiesToCopy.forEach((prop) => {
            if (Object.prototype.hasOwnProperty.call(asset, prop.key) &&
                asset[prop.key] !== undefined) {
                const existingPropIndex = assetProperty.propertiesToCopy.findIndex((p) => p.label === prop.label);
                if (existingPropIndex !== -1) {
                    // Merge if property already exists
                    assetProperty.propertiesToCopy[existingPropIndex] = Object.assign(Object.assign({}, assetProperty.propertiesToCopy[existingPropIndex]), { value: `${assetProperty.propertiesToCopy[existingPropIndex].value}` });
                }
                else {
                    // Otherwise add new property
                    assetProperty.propertiesToCopy.push(Object.assign(Object.assign({}, prop), { type: asset.assetClass }));
                }
            }
        });
    });
    return Array.from(assetMap.values()).map((assetProperty) => (Object.assign(Object.assign({}, assetProperty), { propertiesToCopy: propertiesToCopy
            .map((prop) => assetProperty.propertiesToCopy.find((p) => p.label === prop.label))
            .filter((p) => p !== undefined) })));
};
export const convertCopiedAssetToAssetCopyRequest = (assetIdentity, selectedSpaceIdentities) => {
    return {
        sourceAssetId: assetIdentity,
        targetSpaceIds: selectedSpaceIdentities,
    };
};
