var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { faker } from '@faker-js/faker';
import { rest, setupWorker } from 'msw';
import { handlers as fcaHandlers } from './handlers';
import { PlatformAssetBuilder } from './platformAssetMocks';
import { PlatformMapBuilder } from './platformMapMocks';
faker.seed(1);
export const handlers = [
    rest.get('*/api/v1/Asset/load', (req, res, ctx) => __awaiter(void 0, void 0, void 0, function* () {
        // Use the body to construct any overrides you'd like in the builder pattern
        // const body = await req.json();
        // console.log(body);
        const partial = {};
        const assetBuilder = new PlatformAssetBuilder(partial);
        const assets = [
            ...new Array(faker.number.int({ min: 1, max: 1 })).keys(),
        ].map((_) => assetBuilder.build());
        return res(ctx.json(assets));
    })),
    rest.get('*/api/v1/Assessment/map/:floorPlanId', (req, res, ctx) => __awaiter(void 0, void 0, void 0, function* () {
        const partial = {};
        const mapBuilder = new PlatformMapBuilder(partial);
        const spaces = [
            ...new Array(faker.number.int({ min: 1, max: 10 })).keys(),
        ].map((_) => mapBuilder.build());
        return res(ctx.json(spaces));
    })),
];
// This configures a Service Worker with the given request handlers.
export const startWorker = () => {
    const worker = setupWorker(...[...handlers, ...fcaHandlers]);
    worker.start();
};
