import { PageModes, } from '@/objects/FCAInterfaces';
import { createContext, useContext } from 'react';
import { CanvasMode, } from '@frontend/design-system/components/FloorPlan/FloorPlanCanvas';
export const defaultFacilityPageContextType = {
    currentSpaces: [],
    selectedSpaceIdentity: undefined,
    selectedAssetIdentity: undefined,
    pageMode: PageModes.VIEW,
    mapMode: false,
    setMapMode: () => { },
    canvasMode: CanvasMode.VIEW,
    selectSpace: () => { },
    selectAsset: () => { },
    selectPlan: () => { },
    selectedPlan: undefined,
    setSelectedPlan: () => { },
    setPageMode: () => { },
    setCanvasMode: () => { },
    drawAssetPin: undefined,
    setDrawAssetPin: () => { },
    drawSpaceShape: [],
    setDrawSpaceShape: () => { },
    removeSpace: () => { },
    removeAsset: () => { },
    updateCurrentSpaces: () => { },
    updateCurrentSpacesWithAsset: () => { },
    updateCurrentSpacesWithAssetMap: () => { },
    isDataLoading: false,
    setIsDataLoading: () => { },
    deleteAsset: () => { },
    deleteSpace: () => { },
};
export const FacilityPageContext = createContext({});
export const useFacilityContext = () => useContext(FacilityPageContext);
