export var Apps;
(function (Apps) {
    Apps["FCA"] = "FCA";
    Apps["REPORTS"] = "Reports";
    Apps["PLANNER"] = "Planner";
    Apps["PIQ"] = "PIQ";
})(Apps || (Apps = {}));
export var OrganizationRole;
(function (OrganizationRole) {
    OrganizationRole["LOCAL_ADMIN"] = "LocalAdministrator";
    OrganizationRole["CLIENT_CONCIERGE"] = "ClientConcierge";
    OrganizationRole["MEMBER"] = "Member";
    OrganizationRole["VISITOR"] = "Visitor";
})(OrganizationRole || (OrganizationRole = {}));
export var FacilityRole;
(function (FacilityRole) {
    FacilityRole["MEMBER"] = "Member";
    FacilityRole["VISITOR"] = "Visitor";
})(FacilityRole || (FacilityRole = {}));
export var UserTypes;
(function (UserTypes) {
    UserTypes["admin"] = "admin";
    UserTypes["concierge"] = "concierge";
    UserTypes["customer"] = "customer";
    UserTypes["none"] = "none";
})(UserTypes || (UserTypes = {}));
// TODO: refactor the use of this. Should it include ground assets?
export var StandardAssetTypes;
(function (StandardAssetTypes) {
    StandardAssetTypes["flooring"] = "FLOORING";
    StandardAssetTypes["ceiling"] = "CEILING";
    StandardAssetTypes["windows"] = "WINDOWS";
    StandardAssetTypes["casework"] = "CASEWORK";
    StandardAssetTypes["interiorDoors"] = "INTERIOR_DOORS";
    StandardAssetTypes["exteriorDoors"] = "EXTERIOR_DOORS";
    StandardAssetTypes["lighting"] = "LIGHTING";
})(StandardAssetTypes || (StandardAssetTypes = {}));
export var AssetClassType;
(function (AssetClassType) {
    AssetClassType["PINNED"] = "Pinned";
    AssetClassType["STANDARD"] = "Standard";
    AssetClassType["FACILITY"] = "Facility";
})(AssetClassType || (AssetClassType = {}));
export var PageModes;
(function (PageModes) {
    PageModes[PageModes["VIEW"] = 0] = "VIEW";
    PageModes[PageModes["EDIT"] = 1] = "EDIT";
    PageModes[PageModes["ADD_SPACE"] = 2] = "ADD_SPACE";
    PageModes[PageModes["ADD_ASSET"] = 3] = "ADD_ASSET";
    PageModes[PageModes["LOADING"] = 4] = "LOADING";
})(PageModes || (PageModes = {}));
export var SpaceCategoryEnum;
(function (SpaceCategoryEnum) {
    SpaceCategoryEnum["FACILITY"] = "FACILITY";
    SpaceCategoryEnum["GROUNDS"] = "GROUNDS";
})(SpaceCategoryEnum || (SpaceCategoryEnum = {}));
