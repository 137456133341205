var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getPlans } from '@/services/api/facilityAPI';
import { HStack, Stack, useToast } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { appValues } from '../../config/constants';
import { FacilityPageProvider } from '../../contexts/Facilities/FacilityPageProvider';
import { fetchFacility, selectFacility, setFacility, } from '../../store/facilitySlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectOrganization, setOrganization } from '../../store/orgSlice';
import { selectSite, setSite } from '../../store/siteSlice';
import { FacilityNotes } from './Canvas/FacilityNotes';
import FloorPlanWrapper from './Canvas/FloorPlanWrapper';
import { FacilityPageHeader } from './FacilityPageHeader';
import { DetailsSidebar } from './Sidebar/DetailsSidebar/DetailsSidebar';
import { PlansSidebar } from './Sidebar/PlansSidebar/PlansSidebar';
export const FacilityPage = () => {
    const dispatch = useAppDispatch();
    const { fidentity } = useParams();
    const facilityIdentity = Number(fidentity);
    const org = useAppSelector(selectOrganization);
    const site = useAppSelector(selectSite);
    const facility = useAppSelector(selectFacility);
    const [currentPlans, setCurrentPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast();
    useEffect(() => {
        const sesh = window.sessionStorage;
        //Avoid flicker on information (Header) requiring org/site/facility
        if (!org) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(setOrganization(JSON.parse(sesh.getItem('currOrg'))));
        }
        if (!site) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(setSite(JSON.parse(sesh.getItem('currSite'))));
        }
        if (!facility) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(setFacility(JSON.parse(sesh.getItem('currFacility'))));
        }
        // confirm when removing that navigation back and forth with both btn and browser back btn is intact.
        if (facility &&
            facilityIdentity &&
            facility.facilityIdentity !== facilityIdentity) {
            dispatch(fetchFacility(facilityIdentity));
            fetchPlans();
        }
        if (facilityIdentity && (!currentPlans || currentPlans.length === 0)) {
            fetchPlans();
        }
    }, []);
    const fetchPlans = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const res = yield getPlans(facilityIdentity);
            setCurrentPlans(res);
        }
        catch (err) {
            console.error('couldnt fetch plans: ', err);
            toast({
                title: 'Failed to fetch plans',
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            setIsLoading(false);
        }
    });
    const updateCurrentPlans = (
    // TODO: replace selectedPlan with identity when createSpace endpont availiable
    selectedPlan, updatedPlan) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = prevPlans.map((plan) => {
                //change this to use filter
                if (plan.identity !== selectedPlan.identity) {
                    return plan;
                }
                return updatedPlan;
            });
            return updatedPlans;
        });
    });
    const handlePlanCreate = (planToCreate) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = [...prevPlans, planToCreate];
            return updatedPlans;
        });
    });
    const handlePlanDelete = (planToDelete) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = prevPlans.filter((plan) => plan.identity !== planToDelete.identity);
            return updatedPlans;
        });
    });
    return (_jsxs(FacilityPageProvider, Object.assign({ plans: currentPlans }, { children: [_jsxs(Stack, Object.assign({ bg: 'misc.bgGrey', color: 'slgq.primBlue', h: '100%', spacing: 0, w: '100%', zIndex: '1', overflow: 'hidden' }, { children: [org && site && facility && (_jsx(FacilityPageHeader, { orgId: org.id, orgName: org.name, siteName: site.name, facilityName: facility.name })), _jsxs(HStack, Object.assign({ h: `calc(100vh - ${appValues.HEADER_HEIGHT_MAIN}px)`, spacing: 0 }, { children: [_jsx(PlansSidebar, { plans: currentPlans, updateCurrentPlans: updateCurrentPlans, handlePlanCreate: handlePlanCreate, handlePlanDelete: handlePlanDelete }), _jsx(HStack, Object.assign({ sx: { touchAction: 'none' }, h: `calc(100vh - ${appValues.HEADER_HEIGHT_MAIN}px)`, w: '100vw' }, { children: _jsxs(Splitter, Object.assign({ layout: 'vertical', style: {
                                        height: '100%',
                                        width: '100%',
                                    } }, { children: [_jsx(SplitterPanel, Object.assign({ style: { overflowX: 'hidden' }, size: 99.9 }, { children: currentPlans && currentPlans.length > 0 && (_jsx(FloorPlanWrapper, {})) })), _jsx(SplitterPanel, Object.assign({ style: { overflowX: 'hidden' }, size: 0.1 }, { children: facility && (_jsx(FacilityNotes, { facilityId: facility.id, note: facility.note })) }))] })) })), org && _jsx(DetailsSidebar, { orgId: org.orgIdentity })] }))] })), _jsx(LoadingWithControl, { isLoading: isLoading })] })));
};
